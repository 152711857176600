<template>
  <div @click="handleBackDropClick">
    <div class="backdrop" @click="handleBackDropClick"></div>
    <transition name="snackbar">
      <dialog open :style="{ backgroundColor }" @click="handleBackDropClick">
        <p>{{ message }}</p>
      </dialog>
    </transition>
  </div>
</template>

<script>
export default {
  methods: {
    handleBackDropClick() {
      this.$emit("close");
    },
  },
  props: {
    backgroundColor: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    timeout: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 800;
}

dialog {
  position: fixed;
  top: 20vh;
  left: 25%;
  width: 50%;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 1rem;
  margin: 0;
  overflow: hidden;
  z-index: 900;
  cursor: pointer;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>
