<template>
  <div>
    <div class="border-l-8 border-2 border-blue-500 px-1">
      <r-spinner v-if="loading"> </r-spinner>

      <r-snackbar
        v-if="snackbarVisible"
        :backgroundColor="snackbarColor"
        :message="snackbarText"
        :timeout="3000"
        @close="snackbarVisible = false"
      >
      </r-snackbar>

      <div v-if="!loading" class="flex justify-between p-5">
        <div class="text-2xl font-bold mb-2">Special Airports</div>
        <button @click="hide" class="btn-icon">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>

      <div v-if="!loading" class="flex mb-5">
        <input
          class="mx-3 text-align-center w-24 h-9 rounded-md border-gray-500 uppercase"
          v-model="iataCode"
          @input="handleFilterAirports"
          type="text"
          maxlength="3"
          placeholder="IATA"
        />

        <input
          class="ml-2 min-h-9 w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
          v-model="targetDate"
          type="date"
          @input="handleFilterAirports"
        />
      </div>

      <!-- Data Table -->
      <div class="max-h-[300px] lg:max-h-[500px] relative overflow-x-auto">
        <table
          class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          v-if="specialAirports.length"
        >
          <thead
            class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
          >
            <tr>
              <th
                scope="col"
                class="px-6 py-2 cursor-pointer"
                @click="sortAirports('name')"
              >
                Name
              </th>
              <th
                scope="col"
                class="px-6 py-2 cursor-pointer"
                @click="sortAirports('country')"
              >
                Country
              </th>
              <th
                scope="col"
                class="px-6 py-2 cursor-pointer"
                @click="sortAirports('iata')"
              >
                IATA Code
              </th>
              <th
                scope="col"
                class="px-6 py-2 cursor-pointer"
                @click="sortAirports('icao')"
              >
                ICAO Code
              </th>
              <th scope="col" class="px-6 py-2">COVID-19</th>
              <th scope="col" class="px-6 py-2">Hazard</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="airport in specialAirports"
              :key="airport._id"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              <td
                scope="row"
                class="px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {{ airport.name }}
              </td>
              <td class="px-6 py-1">{{ airport.country }}</td>
              <td class="px-6 py-1">{{ airport.iata }}</td>
              <td class="px-6 py-1">{{ airport.icao }}</td>
              <td class="px-6 py-1">{{ formatCovid19Status(airport) }}</td>
              <td class="px-6 py-1">{{ formatHazardStatus(airport) }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else class="text-xl text-blue-500 p-5">
          {{ `No airports found for  ${iataCode.toUpperCase()}` }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
import RSpinner from "../components/RSpinner.vue";
import RSnackbar from "../components/RSnackbar.vue";
import aad from "../services/aad";
import api from "../services/api";

export default {
  name: "specialAirports",
  mixins: [mixin],
  components: { RSpinner, RSnackbar },
  data() {
    return {
      loading: false,

      //-------------------------------------------Sorting

      sortAirportsName: "",
      sortAirportsCountry: "",
      sortAirportsIata: "",
      sortAirportsIcao: "",

      //-------------------------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 3000,

      specialAirports: [],
      targetDate: new Date().toISOString().substring(0, 10),
      iataCode: "",
    };
  },

  mounted() {
    this.$nextTick(function () {
      this.getData();
    });
  },

  computed: {
    airports() {
      return this.$store.getters.airports;
    },
  },

  methods: {
    hide() {
      this.$emit("hide");
    },

    sortAirports(field) {
      switch (field) {
        case "name":
          if (this.sortAirportsName === "ASC") {
            this.specialAirports.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (b.name < a.name) {
                return 1;
              }
              return 0;
            });

            this.sortAirportsName = "DESC";
          } else {
            this.specialAirports.sort((a, b) => {
              if (a.name > b.name) {
                return -1;
              }
              if (b.name > a.name) {
                return 1;
              }
              return 0;
            });

            this.sortAirportsName = "ASC";
          }

          break;

        case "country":
          if (this.sortAirportsCountry === "ASC") {
            this.specialAirports.sort((a, b) => {
              if (a.country < b.country) {
                return -1;
              }
              if (b.country < a.country) {
                return 1;
              }
              return 0;
            });

            this.sortAirportsCountry = "DESC";
          } else {
            this.specialAirports.sort((a, b) => {
              if (a.country > b.country) {
                return -1;
              }
              if (b.country > a.country) {
                return 1;
              }
              return 0;
            });

            this.sortAirportsCountry = "ASC";
          }
          break;

        case "iata":
          if (this.sortAirportsIata === "ASC") {
            this.specialAirports.sort((a, b) => {
              if (a.iata < b.iata) {
                return -1;
              }
              if (b.iata < a.iata) {
                return 1;
              }
              return 0;
            });

            this.sortAirportsIata = "DESC";
          } else {
            this.specialAirports.sort((a, b) => {
              if (a.iata > b.iata) {
                return -1;
              }
              if (b.iata > a.iata) {
                return 1;
              }
              return 0;
            });

            this.sortAirportsIata = "ASC";
          }
          break;

        case "icao":
          if (this.sortAirportsIcao === "ASC") {
            this.specialAirports.sort((a, b) => {
              if (a.icao < b.icao) {
                return -1;
              }
              if (b.icao < a.icao) {
                return 1;
              }
              return 0;
            });

            this.sortAirportsIcao = "DESC";
          } else {
            this.specialAirports.sort((a, b) => {
              if (a.icao > b.icao) {
                return -1;
              }
              if (b.icao > a.icao) {
                return 1;
              }
              return 0;
            });

            this.sortAirportsIcao = "ASC";
          }
          break;

        default:
          break;
      }
    },

    async getData() {
      try {
        this.loading = true;

        //Login to server with AD token
        const token = await aad.getIdTokenSilent();

        const res = await api.get(`/airports`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        const airports = res.data.airports;
        this.$store.commit("updateAirports", airports);

        this.handleFilterAirports();
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = "Error loading airports";
        this.snackbarVisible = true;
      }
    },

    formatCovid19Status(airport) {
      const startTime = new Date(this.targetDate).getTime();
      const endTime = new Date(this.targetDate).getTime();

      if (this.overlaps(startTime, endTime, airport.covid19Periods)) {
        return "YES";
      } else {
        return "NO";
      }
    },
    formatHazardStatus(airport) {
      const startTime = new Date(this.targetDate).getTime();
      const endTime = new Date(this.targetDate).getTime();

      if (this.overlaps(startTime, endTime, airport.hazardPeriods)) {
        return "YES";
      } else {
        return "NO";
      }
    },

    handleFilterAirports() {
      if (this.iataCode.length === 3) {
        this.specialAirports = this.airports.filter((airport) => {
          return (
            (airport.covid19Periods.length || airport.hazardPeriods.length) &
            (airport.iata === this.iataCode.toUpperCase())
          );
        });
      } else {
        this.specialAirports = this.airports.filter((airport) => {
          return airport.covid19Periods.length || airport.hazardPeriods.length;
        });
      }
    },
  },
};
</script>
