<template>
  <div>
    <div class="backdrop" @click="handleBackDropClick"></div>
    <dialog open>
      <div class="frame">
        <div class="loader"></div>
      </div>
    </dialog>
  </div>
</template>

<script>
export default {
  methods: {
    handleBackDropClick() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1700;
}

dialog {
  position: fixed;
  top: 20vh;
  left: calc(50% - 5rem);
  width: 10rem;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  z-index: 1800;
}

.frame {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem;
}

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #de3500 #0000 #fff #0000;
  border-radius: 50%;
  box-sizing: border-box;
  animation: 1s rotate linear infinite;
}
.loader:before,
.loader:after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom-color: #fff;
  transform: translate(-10px, 19px) rotate(-35deg);
}
.loader:after {
  border-color: #de3500 #0000 #0000 #0000;
  transform: translate(32px, 3px) rotate(-35deg);
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
