<template>
  <div class="outer-frame">
    <div class="text-[10px] text-truncate">
      {{ `${groundAssignment.type} ` }}
    </div>

    <div class="inner-frame" :style="getLabelStyle()">
      {{ `${formatSnapshot()}` }}
    </div>

    <div class="flex justify-between text-truncate text-[10px]">
      <div>{{ groundAssignment.startTime.substring(11, 16) }}</div>
      <div>{{ groundAssignment.endTime.substring(11, 16) }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "groundAssignmentLabel",
  data() {
    return {};
  },
  props: ["groundAssignment"],
  methods: {
    getLabelStyle() {
      // groundAssignmentCategories: [
      //   "Stand By",
      //   "Station Assignment",
      //   "Special Assignment",
      //   "Travel",
      //   "Layover",
      //   "Training",
      //   "Sick",
      //   "Quarantine",
      //   "Quarantine - Day OFF",
      //   "Vacation",
      //   "Holiday",
      //   "Floating Holiday",
      //   "Leave",
      //   "LOA",
      // ],

      let style = {
        backgroundColor: "#C89762",
        color: "#FFFFFF",
      };

      switch (this.groundAssignment.type) {
        // case "CLC - Stand By":
        //   style = {
        //     backgroundColor: "#E120FE",
        //     color: "#FFFFFF",
        //   };
        //   break;

        case "Stand By":
          style = {
            backgroundColor: "#F59608",
            color: "#FFFFFF",
          };
          break;

        case "Layover":
          if (this.groundAssignment.confirmed) {
            style = {
              backgroundColor: "#C89762",
              color: "#FFFFFF",
            };
          } else {
            style = {
              backgroundColor: "#C89762",
              color: "#FF0000",
            };
          }

          break;

        case "Travel":
          if (this.groundAssignment.confirmed) {
            style = {
              backgroundColor: "#379fe1",
              color: "#FFFFFF",
            };
          } else {
            style = {
              backgroundColor: "#379fe1",
              color: "#FF0000",
            };
          }

          break;

        case "Training - Ground School":
        case "Training - SIM":
          style = {
            backgroundColor: "#002663",
            color: "#FFFFFF",
          };
          break;

        case "Leave - Bereavement":
        case "Leave - FMLA":
        case "Leave - Medical":
        case "Leave - Military":
        case "Leave - Non-FMLA":
        case "Leave - Parental":
        case "Leave - Personal":
          style = {
            backgroundColor: "#002663",
            color: "#FFFFFF",
          };
          break;

        case "Sick":
          style = {
            backgroundColor: "#598662",
            color: "#FFFFFF",
          };
          break;
        case "Vacation":
        case "Holiday":
        case "Floating Holiday":
          style = {
            backgroundColor: "#F7490E",
            color: "#FFFFFF",
          };
          break;

        case "LOA - Jury Duty":
        case "LOA - Long Term Disability":
        case "LOA - Short Term Disability":
        case "LOA - Workers' Compensation":
          style = {
            backgroundColor: "#F7490E",
            color: "#FFFFFF",
          };
          break;

        case "Special Assignment":
          break;
        case "Quarantine":
        case "Quarantine - Day OFF":
          style = {
            backgroundColor: "#9A071C",
            color: "#FFFFFF",
          };
          break;
        case "Do Not Use":
          style = {
            backgroundColor: "#f56308",
            color: "#FFFFFF",
          };
          break;

        default:
          break;
      }

      if (this.groundAssignment.warnings.length) {
        style.borderTop = "2px solid red";
      }

      return style;
    },

    formatSnapshot() {
      //const timeOut = this.formatDateTime(this.groundAssignment.startTime);
      // const timeIn = this.formatTime(this.groundAssignment.endTime);
      let snapshot = "??";

      switch (this.groundAssignment.type) {
        case "Travel":
          snapshot = `${this.groundAssignment.type} . ${this.groundAssignment.originIata} - ${this.groundAssignment.destinationIata}`;
          break;
        default:
          snapshot = `${this.groundAssignment.type} . ${this.groundAssignment.originIata}`;
          break;
      }

      return snapshot;
    },
  },
};
</script>
<style scoped>
.outer-frame {
  position: absolute;
  height: 59px;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}

.inner-frame {
  position: relative;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #bebebe;
  background-color: #939790;
  height: 27px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
</style>
