<template>
  <div class="outer-frame">
    <div class="text-[10px]">
      {{ assignment.flightNumber }}
    </div>

    <div class="inner-frame" :style="getFlightLabelStyle()">
      {{ `${assignment.originIata} - ${assignment.destinationIata}` }}
    </div>

    <div class="flex justify-between text-truncate text-[10px]">
      <div>{{ assignment.startTime.substring(11, 16) }}</div>
      <div>{{ assignment.endTime.substring(11, 16) }}</div>
    </div>
  </div>
</template>
<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "flightAssignmentLabel",
  mixins: [mixin],
  data() {
    return {};
  },
  props: ["assignment"],
  methods: {
    getFlightLabelStyle() {
      let style = {
        backgroundColor: "#FFFFFF",
        color: "#FF0000",
      };

      let crewFlag = "1px solid #bebebe";
      let groundCrewFlag = "1px solid #bebebe";

      switch (this.assignment.status) {
        case "In":
        case "On":
          style = {
            backgroundColor: "#273FCB",
            color: "#FFFFFF",
          };
          break;
        case "Off":
        case "Out":
          style = {
            backgroundColor: "#228833",
            color: "#FFFFFF",
          };
          break;
        case "Delayed":
          if (this.assignment.isVip) {
            style = {
              backgroundColor: "#d795d4",
              color: "#FFFFFF",
              borderLeft: crewFlag,
              borderBottom: groundCrewFlag,
            };
          } else {
            style = {
              backgroundColor: "#D92120",
              color: "#FFFFFF",
              borderLeft: crewFlag,
              borderBottom: groundCrewFlag,
            };
          }

          break;
        case "Scheduled":
          if (this.assignment.isVip) {
            style = {
              backgroundColor: "#96b2d7",
              color: "#000000",
              borderLeft: crewFlag,
              borderBottom: groundCrewFlag,
            };
          } else {
            style = {
              backgroundColor: "#F7EE55",
              color: "#000000",
              borderLeft: crewFlag,
              borderBottom: groundCrewFlag,
            };
          }
          break;
        default:
          break;
      }

      if (this.assignment.warnings.length) {
        style.borderTop = "2px solid red";
      }

      return style;
    },
  },
};
</script>
<style scoped>
.outer-frame {
  position: absolute;
  height: 59px;
  width: 100%;
}

.has-conflict {
  border: 1px solid red;
  background-color: darksalmon;
}

.inner-frame {
  position: relative;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #bebebe;
  background-color: #939790;
  height: 27px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

/* .inner-frame {
  position: relative;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #bebebe;
  background-color: #939790;
  height: 30px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */
</style>
