<template>
  <div class="p-5 bg-red-500">
    <div class="flex justify-between">
      <div class="text-xl">Are you sure?</div>
      <button @click="cancel" icon>
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
    <div class="horizontal-line-gray my-1"></div>

    <p class="my-2 text-sm-bold">{{ message }}</p>

    <div class="flex justify-end">
      <button class="btn bg-red-500 text-white" @click="confirm">
        confirm
      </button>
      <button @click="cancel" lass="btn bg-gray-500 text-white ml-1">
        cancel
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "confirmaDelete",
  props: ["message"],
  data() {
    return {};
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
