export const mixin = {
  data() {
    return {
      jan2100: 4102444800000, //UNIX Epoch Jan 1st, 2100 @ 00:00 UTC
      aircraftTypes: [
        "737",
        "738",
        "744",
        "747",
        "748",
        "763",
        "74Y",
        "76F",
        "77F",
        "LCF",
        "PAX",
      ],
      loadmasterRoles: ["Loadmaster", "Check Loadmaster", "Senior Loadmaster"],
      psrRoles: ["PSR", "Check PSR", "Lead PSR", "Senior PSR"],
      cargoJobTitles: [
        "Check Loadmaster",
        "Senior Loadmaster",
        "Loadmaster",
        "Loadmaster Training",
        "Contract Loadmaster",
        "Loadmaster Trainee",
        "Loadmaster/PSR",
        "Special Loads/CLC",
        "Mechanic",
        "Jumpseat",
        "Station Rep.",
        "Station Representative (Not W&B Qualified)",
        "Coordinator (Vendors)",
      ],
      passengerJobTitles: [
        "PSR",
        "Lead PSR",
        "Senior PSR",
        "Check PSR",
        "PSR Training",
        "PAA",
        "PAA Training",
        "Loadmaster/PSR",
        "PSR/PAA Trainee",
        "POC",
        "GSC",
        "Mechanic",
        "Jumpseat",
        "Station Rep.",
        "Station Representative (Not W&B Qualified)",
        "Coordinator (Vendors)",
      ],
      countries: [
        "United States",
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bonaire",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Congo (the Democratic Republic of the)",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea (the Democratic People's Republic of)",
        "Korea (the Republic of)",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States Minor Outlying Islands",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "Aland Islands",
      ],
      states: [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Federated States of Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      groundAssignmentTypes: [
        "Layover",
        "Travel",
        "Do Not Use",
        "Stand By",
        "Sick",
        "Special Assignment",
        "Floating Holiday",
        "Holiday",
        "Leave - Bereavement",
        "Leave - FMLA",
        "Leave - Medical",
        "Leave - Military",
        "Leave - Non-FMLA",
        "Leave - Parental",
        "Leave - Personal",
        "LOA - Jury Duty",
        "LOA - Long Term Disability",
        "LOA - Short Term Disability",
        "LOA - Workers' Compensation",
        "Quarantine - Day OFF",
        "Quarantine",
        "Training - Ground School",
        "Training - SIM",
        "Vacation",
      ],
      version: "2.1.0", //Deploy
      build: "121320241114", //Deploy
      environment: "UAT", //Deploy
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    employeeProfileImagesBucketUrl() {
      switch (this.environment) {
        case "DEV":
          return "https://dev-monarch-employee-profile-images.s3.amazonaws.com/";
        case "UAT":
          return "https://uat-monarch-employee-profile-images.s3.amazonaws.com/";

        default:
          return "https://monarch-employee-profile-images.s3.amazonaws.com/";
      }
    },

    currentTimeX() {
      const ct = Date.now();
      const minutes = Math.ceil(
        (ct - new Date(this.dateFilter.start).getTime()) / 1000 / 60
      );

      if (minutes > 0) {
        return minutes / this.scale;
      } else {
        return 0;
      }
    },

    dateFilter() {
      return this.$store.getters.dateFilter;
    },

    //-----------------------------------Admin Manual
    canSeeAdminManual() {
      return this.user.securityGroup.authorizations.flightAssignment.create
        .enabled;
    },

    //-----------------------------------Flight (Payload)
    canUpdateFlight() {
      return this.user.securityGroup.authorizations.flight.update.enabled;
    },

    //-----------------------------------Flight (Remarks)
    canUpdateFlightRemarks() {
      return this.user.securityGroup.authorizations.flight.update;
    },

    canReadFlight() {
      return this.user.securityGroup.authorizations.flight.read.enabled;
    },

    canGetAircraftScheduleConflicts() {
      return this.user.securityGroup.authorizations.flight.read
        .getAircraftScheduleConflicts;
    },

    //-----------------------------------Warnings
    canSeeAssignmentWarnings() {
      return this.user.securityGroup.authorizations.flightAssignment.create
        .enabled;
    },

    //-----------------------------------Bidlines
    canCreateBidlines() {
      return this.user.securityGroup.authorizations.bidline.create.enabled;
    },

    canReadBidlines() {
      return this.user.securityGroup.authorizations.bidline.read.enabled;
    },

    canUpdateBidline() {
      return this.user.securityGroup.authorizations.bidline.update.enabled;
    },

    canDeleteBidline() {
      return this.user.securityGroup.authorizations.bidline.delete.enabled;
    },

    //-----------------------------------Flight Assignments
    //Only users with "Atlas Air" in companies can make trip assignments
    canCreateTripAssignments() {
      return (
        this.user.securityGroup.authorizations.flightAssignment.create
          .enabled && this.user.securityGroup.companies.includes("Atlas Air")
      );
    },

    canCreateFlightAssignments() {
      return this.user.securityGroup.authorizations.flightAssignment.create
        .enabled;
    },

    canReadMyMonarch() {
      return (
        this.user.securityGroup.authorizations.flightAssignment.read.enabled ||
        this.user.securityGroup.authorizations.groundAssignment.read.enabled
      );
    },

    canReadFlightAssignments() {
      return (
        this.user.securityGroup.authorizations.flightAssignment.read.enabled &&
        this.user.securityGroup.authorizations.flightAssignment.read.level ===
          "Manager"
      );
    },

    canUpdateFlightAssignment() {
      return this.user.securityGroup.authorizations.flightAssignment.update
        .enabled;
    },

    canDeleteFlightAssignment() {
      return this.user.securityGroup.authorizations.flightAssignment.delete
        .enabled;
    },

    canOverrideFlightAssignmentWarnings() {
      return this.user.securityGroup.authorizations.flightAssignment
        .overrideWarnings;
    },

    //-----------------------------------Ground Assignments
    canCreateGroundAssignments() {
      return this.user.securityGroup.authorizations.groundAssignment.create
        .enabled;
    },

    canReadMyGroundAssignments() {
      return this.user.securityGroup.authorizations.groundAssignment.read
        .enabled;
    },

    canReadGroundAssignments() {
      return (
        this.user.securityGroup.authorizations.groundAssignment.read.enabled &&
        this.user.securityGroup.authorizations.groundAssignment.read.level ===
          "Manager"
      );
    },

    canUpdateGroundAssignment() {
      return this.user.securityGroup.authorizations.groundAssignment.update
        .enabled;
    },

    canDeleteGroundAssignment() {
      return this.user.securityGroup.authorizations.groundAssignment.delete
        .enabled;
    },

    canOverrideGroundAssignmentWarnings() {
      return this.user.securityGroup.authorizations.groundAssignment
        .overrideWarnings;
    },

    //---------------------------------Assignment Notifications
    canCreateAssignmentNotification() {
      return this.user.securityGroup.authorizations.assignmentNotification
        .create.enabled;
    },

    canReadAllAssignmentNotification() {
      if (
        this.user.securityGroup.authorizations.assignmentNotification.read
          .enabled
      ) {
        const i = ["Manager"].findIndex((item) => {
          return (
            item ===
            this.user.securityGroup.authorizations.assignmentNotification.read
              .level
          );
        });

        return i >= 0;
      } else {
        return false;
      }
    },

    canReadMyAssignmentNotification() {
      if (
        this.user.securityGroup.authorizations.assignmentNotification.read
          .enabled
      ) {
        const i = ["Manager", "Employee"].findIndex((item) => {
          return (
            item ===
            this.user.securityGroup.authorizations.assignmentNotification.read
              .level
          );
        });

        return i >= 0;
      } else {
        return false;
      }
    },

    canUpdateAssignmentNotification() {
      return this.user.securityGroup.authorizations.assignmentNotification
        .update.enabled;
    },

    canDeleteAssignmentNotification() {
      return this.user.securityGroup.authorizations.assignmentNotification
        .delete.enabled;
    },

    //-----------------------------------Employee Profiles
    canCreateEmployee() {
      return this.user.securityGroup.authorizations.employee.create.enabled;
    },

    canReadEmployee() {
      return this.user.securityGroup.authorizations.employee.read.enabled;
    },

    canReadEmployeeContactInformation() {
      const i = ["Manager"].findIndex((item) => {
        return (
          item === this.user.securityGroup.authorizations.employee.read.level
        );
      });

      return i >= 0;
    },

    canUpdateEmployee() {
      const i = ["Manager", "Employee"].findIndex((item) => {
        return (
          item === this.user.securityGroup.authorizations.employee.update.level
        );
      });

      return (
        this.user.securityGroup.authorizations.employee.update.enabled && i >= 0
      );
    },
    canUpdateEmployeeSecurityGroup() {
      return this.user.securityGroup.authorizations.employee.update
        .securityGroup;
    },

    canUpdateEmployeeAdvanced() {
      return (
        this.user.securityGroup.authorizations.employee.update.enabled &&
        this.user.securityGroup.authorizations.employee.update.level ===
          "Manager"
      );
    },

    canDeleteEmployee() {
      return this.user.securityGroup.authorizations.employee.delete.enabled;
    },

    //-----------------------------------Payroll Reports
    //Export Payroll Reports

    canGetYearlyActivityReport() {
      const i = ["Administrator", "Manager"].findIndex((item) => {
        return (
          item ===
          this.user.securityGroup.authorizations.payrollReport.read.level
        );
      });

      return (
        this.user.securityGroup.authorizations.payrollReport.read.enabled &&
        i >= 0
      );
    },

    //Create

    canCreateEmployeePayrollReport() {
      const i = ["Administrator", "Manager", "Employee"].findIndex((item) => {
        return (
          item ===
          this.user.securityGroup.authorizations.payrollReport.create.level
        );
      });
      return (
        this.user.securityGroup.authorizations.payrollReport.create.enabled &&
        i >= 0
      );
    },

    canCreateManagerPayrollReport() {
      const i = ["Administrator", "Manager"].findIndex((item) => {
        return (
          item ===
          this.user.securityGroup.authorizations.payrollReport.create.level
        );
      });

      return (
        this.user.securityGroup.authorizations.payrollReport.create.enabled &&
        i >= 0
      );
    },

    canCreateAdministratorPayrollReport() {
      return (
        this.user.securityGroup.authorizations.payrollReport.create.enabled &&
        this.user.securityGroup.authorizations.payrollReport.create.level ===
          "Administrator"
      );
    },

    canReadEmployeePayrollReport() {
      const i = ["Administrator", "Manager", "Employee"].findIndex((item) => {
        return (
          item ===
          this.user.securityGroup.authorizations.payrollReport.read.level
        );
      });

      return (
        this.user.securityGroup.authorizations.payrollReport.read.enabled &&
        i >= 0
      );
    },

    canReadManagerPayrollReport() {
      const i = ["Administrator", "Manager"].findIndex((item) => {
        return (
          item ===
          this.user.securityGroup.authorizations.payrollReport.read.level
        );
      });

      return (
        this.user.securityGroup.authorizations.payrollReport.read.enabled &&
        i >= 0
      );
    },

    canReadAdministratorPayrollReport() {
      return (
        this.user.securityGroup.authorizations.payrollReport.read.enabled &&
        this.user.securityGroup.authorizations.payrollReport.read.level ===
          "Administrator"
      );
    },

    //Update

    canUpdateEmployeePayrollReport() {
      const i = ["Administrator", "Manager", "Employee"].findIndex((item) => {
        return (
          item ===
          this.user.securityGroup.authorizations.payrollReport.update.level
        );
      });

      return (
        this.user.securityGroup.authorizations.payrollReport.update.enabled &&
        i >= 0
      );
    },

    canUpdateManagerPayrollReport() {
      const i = ["Administrator", "Manager"].findIndex((item) => {
        return (
          item ===
          this.user.securityGroup.authorizations.payrollReport.update.level
        );
      });

      return (
        this.user.securityGroup.authorizations.payrollReport.update.enabled &&
        i >= 0
      );
    },

    canUpdateAdministratorPayrollReport() {
      return (
        this.user.securityGroup.authorizations.payrollReport.update.enabled &&
        this.user.securityGroup.authorizations.payrollReport.update.level ===
          "Administrator"
      );
    },

    //Delete

    canDeleteEmployeePayrollReport() {
      const i = ["Administrator", "Manager", "Employee"].findIndex((item) => {
        return (
          item ===
          this.user.securityGroup.authorizations.payrollReport.delete.level
        );
      });

      return (
        this.user.securityGroup.authorizations.payrollReport.delete.enabled &&
        i >= 0
      );
    },

    canDeleteManagerPayrollReport() {
      const i = ["Administrator", "Manager"].findIndex((item) => {
        return (
          item ===
          this.user.securityGroup.authorizations.payrollReport.delete.level
        );
      });

      return (
        this.user.securityGroup.authorizations.payrollReport.delete.enabled &&
        i >= 0
      );
    },

    canDeleteAdministratorPayrollReport() {
      return (
        this.user.securityGroup.authorizations.payrollReport.delete.enabled &&
        this.user.securityGroup.authorizations.payrollReport.delete.level ===
          "Administrator"
      );
    },

    //-------------Security Groups
    canCreateSecurityGroups() {
      return this.user.securityGroup.authorizations.securityGroup.create
        .enabled;
    },

    canReadSecurityGroups() {
      return this.user.securityGroup.authorizations.securityGroup.read.enabled;
    },

    canUpdateSecurityGroups() {
      return this.user.securityGroup.authorizations.securityGroup.update
        .enabled;
    },

    canDeleteSecurityGroups() {
      return this.user.securityGroup.authorizations.securityGroup.delete
        .enabled;
    },

    //------------Aircraft
    canUpdateAircraft() {
      return this.user.securityGroup.authorizations.aircraft.update.enabled;
    },

    //-------------Airports
    canReadAirport() {
      return this.user.securityGroup.authorizations.airport.read.enabled;
    },

    canUpdateAirport() {
      return this.user.securityGroup.authorizations.airport.update.enabled;
    },

    canUpdateCrewRequirements() {
      return this.user.securityGroup.authorizations.airport.update.enabled;
    },
  },

  methods: {
    //-------------------------------Activity Reports
    getActivityReport(employee) {
      let date;
      let dayStart;
      let dayEnd;
      let dayAssignments;
      let dayGroundSegments;
      let dayHazardSegments;

      let hazardDayCredit;

      const monthGroundSegments = [];
      let assignment;
      let nextAssignment;
      let groundSegment;
      let duration;

      let airport;

      let activity = {
        daysInThisMonth: this.monthFilter.days.length,
        days: [],
        perdiemDaysFirstHalf: 0,
        perdiemDaysSecondHalf: 0,
        perdiemDays: 0,
        internationalPerdiemDaysFirstHalf: 0,
        internationalPerdiemDaysSecondHalf: 0,
        internationalPerdiemDays: 0,
        combinedPerdiemDays: 0,
        workDays: 0,
        workDaysFirstHalf: 0,
        workDaysSecondHalf: 0,
        daysOff: 0,
        standByDays: 0,
        vacationDays: 0,
        sickDays: 0,
        loaDays: 0,
        leaveDays: 0,
        holidayDays: 0,
        floatingHolidayDays: 0,
        healthRiskDays: 0,
        missionHazard: 0,
        totalDays: 0,
        daysOver20: 0,
        daysOverGuarrantee: 0,
      };

      //Get month ground segments
      for (let i = 0; i < employee.assignments.length; i++) {
        assignment = employee.assignments[i];
        nextAssignment = employee.assignments[i + 1];

        if (nextAssignment) {
          duration = Math.floor(
            (new Date(nextAssignment.startTime).getTime() -
              new Date(assignment.endTime).getTime()) /
              60 /
              1000
          );

          groundSegment = {
            startIata: assignment.destinationIata,
            startTime: assignment.endTime,
            endIata: nextAssignment.originIata,
            endTime: nextAssignment.startTime,
            duration,
          };

          monthGroundSegments.push(groundSegment);
        } else {
          const endTime = new Date(
            `${assignment.endTime.substring(0, 10)}T23:59:59.999+00:00`
          ).toISOString();

          duration = Math.floor(
            (new Date(endTime).getTime() -
              new Date(assignment.endTime).getTime()) /
              60 /
              1000
          );

          //Open ground segment ends 1 minute before midnight on the last assignment
          groundSegment = {
            startIata: assignment.destinationIata,
            startTime: assignment.endTime,
            endIata: assignment.destinationIata,
            endTime,
            duration,
          };

          monthGroundSegments.push(groundSegment);
        }
      }

      //--------------------------------------------------------------------
      //          MISSION HAZ - HEALTH RISK - PERDIEM - DAYS
      //--------------------------------------------------------------------

      let dayOfTheMonth;

      //Loop thru days
      for (let i = 0; i < this.monthFilter.days.length; i++) {
        //Reset variables
        dayHazardSegments = new Map();
        hazardDayCredit = 0;

        //Get day date text, start and end timestamps in Unix Epoch
        date = this.monthFilter.days[i].substring(0, 10);
        dayStart = new Date(`${date}T00:00:00.000+00:00`).getTime();
        dayEnd = new Date(`${date}T23:59:59.999+00:00`).getTime();

        //Day ground segments
        dayGroundSegments = monthGroundSegments.filter((groundSegment) => {
          return (
            new Date(groundSegment.startTime).getTime() <= dayEnd &&
            new Date(groundSegment.endTime).getTime() >= dayStart
          );
        });

        //Loop thru day ground segments
        if (dayGroundSegments.length) {
          dayGroundSegments.forEach((groundSegment) => {
            //Count Hazard
            if (groundSegment.startTime.substring(0, 10) === date) {
              airport = this.airports.find((a) => {
                return a.iata === groundSegment.startIata;
              });

              if (
                airport &&
                this.overlaps(dayStart, dayEnd, airport.hazardPeriods)
              ) {
                dayHazardSegments.set(groundSegment.startIata, groundSegment);
              }
            }
          });
        }

        if (dayHazardSegments.size > 0) {
          dayHazardSegments.forEach((groundSegment) => {
            //Rule definition: on the ground 8 hours or more. Ground time must be in different days
            if (groundSegment.duration >= 8 * 60) {
              if (
                groundSegment.startTime.substring(0, 10) !==
                groundSegment.endTime.substring(0, 10)
              ) {
                hazardDayCredit += 2;
              } else {
                hazardDayCredit += 1;
              }
            } else {
              hazardDayCredit += 1;
            }
          });
        }

        //Day assignments
        dayAssignments = employee.assignments.filter((assignment) => {
          return (
            new Date(assignment.startTime).getTime() <= dayEnd &&
            new Date(assignment.endTime).getTime() >= dayStart
          );
        });

        let dayData = this.getDayData(
          dayAssignments,
          dayStart,
          dayEnd,
          employee.gatewayCode
        );

        if (hazardDayCredit > 2) {
          dayData.missionHazard = 2;
        } else {
          dayData.missionHazard = hazardDayCredit;
        }

        dayData.assignments = dayAssignments;

        dayOfTheMonth = parseInt(date.substring(8, 10));

        //International Perdiem
        if (dayData.internationalPerdiem > 0) {
          if (dayOfTheMonth > 15) {
            activity.internationalPerdiemDaysSecondHalf++;
          } else {
            activity.internationalPerdiemDaysFirstHalf++;
          }
        } else {
          //Domestic Perdiem
          if (dayData.perdiem > 0) {
            if (dayOfTheMonth > 15) {
              activity.perdiemDaysSecondHalf++;
            } else {
              activity.perdiemDaysFirstHalf++;
            }
          }
        }

        if (dayData.work > 0) {
          if (dayOfTheMonth > 15) {
            activity.workDaysSecondHalf++;
          } else {
            activity.workDaysFirstHalf++;
          }
        }

        if (dayData.vacation > 0) {
          activity.vacationDays++;
        }

        if (dayData.sick > 0) {
          activity.sickDays++;
        }

        if (dayData.off > 0) {
          activity.daysOff++;
        }

        if (dayData.standBy > 0) {
          activity.standByDays++;
        }

        if (dayData.loa > 0) {
          activity.loaDays++;
        }

        if (dayData.leave > 0) {
          activity.leaveDays++;
        }

        if (dayData.holiday > 0) {
          activity.holidayDays++;
        }

        if (dayData.floatingHoliday > 0) {
          activity.floatingHolidayDays++;
        }

        if (dayData.healthRisk > 0) {
          activity.healthRiskDays++;
        }

        activity.missionHazard += hazardDayCredit;

        activity.days.push({
          date,
          data: dayData,
        });
      }

      activity.workDays =
        activity.workDaysFirstHalf + activity.workDaysSecondHalf;
      activity.perdiemDays =
        activity.perdiemDaysFirstHalf + activity.perdiemDaysSecondHalf;
      activity.internationalPerdiemDays =
        activity.internationalPerdiemDaysFirstHalf +
        activity.internationalPerdiemDaysSecondHalf;
      activity.combinedPerdiemDays =
        activity.perdiemDays + activity.internationalPerdiemDays;
      activity.healthRiskAllowance = activity.healthRiskDays / 2;
      activity.totalDays =
        activity.workDays +
        activity.vacationDays +
        activity.daysOff +
        activity.loaDays +
        activity.leaveDays +
        activity.holidayDays +
        activity.floatingHolidayDays;
      activity.daysOver20 = activity.workDays > 20 ? activity.workDays - 20 : 0;
      activity.daysOverGuarrantee =
        activity.daysOver20 +
        activity.missionHazard +
        activity.healthRiskAllowance;

      return activity;
    },
    getDayData(assignments, dayStart, dayEnd, gatewayCode) {
      //PERDIEM  Calculation of Perdiem happens here
      let dayData = {
        description: "",
        code: "",
        displayCode: "",
        backgroundColor: "red",
        color: "#FFFFFF",
        work: 0,
        vacation: 0,
        sick: 0,
        off: 0,
        standBy: 0,
        loa: 0,
        leave: 0,
        holiday: 0,
        floatingHoliday: 0,
        healthRisk: 0,
        perdiem: 0,
        internationalPerdiem: 0,
      };

      // 24 Hour Assignments Types
      // "Stand By",
      // "Sick",
      // "Quarantine",
      // "Quarantine - Day OFF",
      // "Vacation",
      // "Holiday",
      // "Floating Holiday",
      // "Leave - Bereavement",
      // "Leave - FMLA",
      // "Leave - Medical",
      // "Leave - Military",
      // "Leave - Non-FMLA",
      // "Leave - Parental",
      // "Leave - Personal",
      // "LOA - Jury Duty",
      // "LOA - Long Term Disability",
      // "LOA - Short Term Disability",
      // "LOA - Workers' Compensation",

      // Ground Assignment Types: [
      // "Layover",
      // "Travel",
      // "Do Not Use",
      // "Stand By",
      // "Sick",
      // "Special Assignment",
      // "Floating Holiday",
      // "Holiday",
      // "Leave - Bereavement",
      // "Leave - FMLA",
      // "Leave - Medical",
      // "Leave - Military",
      // "Leave - Non-FMLA",
      // "Leave - Parental",
      // "Leave - Personal",
      // "LOA - Jury Duty",
      // "LOA - Long Term Disability",
      // "LOA - Short Term Disability",
      // "LOA - Workers' Compensation",
      // "Quarantine - Day OFF",
      // "Quarantine",
      // "Training - Ground School",
      // "Training - SIM",
      // ],

      if (assignments.length) {
        let startsToday;
        let endsToday;
        let startsBeforeToday;
        let endsAfterToday;

        let assignment; //Holds the assignment value when looping thru assignments, retains the last assignment
        let assignmentStart; //Assignment start time in Unix Epoch
        let assignmentEnd; //Assignment end time in Unix Epoch

        let origin;
        let destination;

        //These types count as a working day
        const workAssignmentTypes = [
          "Stand By",
          "Station Assignment",
          "Special Assignment",
          "Travel",
          "Layover",
          "Training - Ground School",
          "Training - SIM",
          "Sick",
          "Quarantine",
          "Do Not Use",
        ];

        const perdiemAssignmentTypes = [
          "Station Assignment",
          "Special Assignment",
          "Travel",
          "Layover",
          "Training - Ground School",
          "Training - SIM",
          "Sick",
          "Quarantine",
          "Quarantine - Day OFF",
          "Do Not Use",
        ];

        const healthRiskAssignmentTypes = [
          "Station Assignment",
          "Special Assignment",
          "Travel",
          "Layover",
          "Training",
          "Sick",
          "Quarantine",
          "Quarantine - Day OFF",
          "Do Not Use",
        ];

        let descriptionAndCodes;
        let payInternationalPerdiem = 0;

        for (let i = 0; i < assignments.length; i++) {
          //PERDIEM

          assignment = assignments[i];

          origin = this.airports.find((airport) => {
            return airport.iata === assignment.originIata;
          });

          destination = this.airports.find((airport) => {
            return airport.iata === assignment.destinationIata;
          });

          assignmentStart = new Date(assignment.startTime).getTime();
          assignmentEnd = new Date(assignment.endTime).getTime();

          startsToday =
            assignmentStart >= dayStart && assignmentStart <= dayEnd;
          endsToday = assignmentEnd >= dayStart && assignmentEnd <= dayEnd;
          startsBeforeToday = assignmentStart < dayStart;
          endsAfterToday = assignmentEnd > dayEnd;

          if (startsBeforeToday && endsAfterToday) {
            //The employee started to the assignment before the start of the day and ended the assignment after the day ended
            //Check assignment origin to calculate health risk and Perdiem

            if (assignment.type !== "Flight") {
              //LOA, Leavy, HOL, FH Days

              switch (assignment.type) {
                case "Sick":
                  dayData.sick++;
                  break;
                case "Vacation":
                  dayData.vacation++;
                  break;

                case "LOA - Jury Duty":
                case "LOA - Long Term Disability":
                case "LOA - Short Term Disability":
                case "LOA - Workers' Compensation":
                  dayData.loa++;
                  break;

                case "Leave - Bereavement":
                case "Leave - FMLA":
                case "Leave - Medical":
                case "Leave - Military":
                case "Leave - Non-FMLA":
                case "Leave - Parental":
                case "Leave - Personal":
                  dayData.leave++;
                  break;
                case "Holiday":
                  dayData.holiday++;
                  break;
                case "Floating Holiday":
                  dayData.floatingHoliday++;
                  break;
                case "Stand By":
                  dayData.standBy++;

                  break;
                default:
                  break;
              }

              //Work Day based on Type
              if (workAssignmentTypes.includes(assignment.type)) {
                dayData.work++;
              }

              //Health Risk based on Origin
              if (
                healthRiskAssignmentTypes.includes(assignment.type) &&
                origin &&
                this.overlaps(dayStart, dayEnd, origin.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Origin , except for "Special Assignment" which pays perdiem even at home base
              if (
                perdiemAssignmentTypes.includes(assignment.type) &&
                (assignment.type === "Special Assignment" ||
                  gatewayCode !== assignment.originIata)
              ) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }
            } else {
              //---------------------------------------------Flight Assignment

              //Work Day
              dayData.work++;

              //Health Risk based on Origin
              if (
                origin &&
                this.overlaps(dayStart, dayEnd, origin.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Origin
              if (gatewayCode !== assignment.originIata) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }
            }
          } else if (startsToday && endsToday) {
            //The employee started the assignment on or after the start of the day and ended the assignment before the day ended
            //Check assignment origin and assignment destination to calculate health risk and Perdiem

            if (assignment.type !== "Flight") {
              //LOA, Leavy, HOL, FH Days
              switch (assignment.type) {
                case "Sick":
                  dayData.sick++;
                  break;
                case "Vacation":
                  dayData.vacation++;
                  break;
                case "LOA - Jury Duty":
                case "LOA - Long Term Disability":
                case "LOA - Short Term Disability":
                case "LOA - Workers' Compensation":
                  dayData.loa++;
                  break;
                case "Leave - Bereavement":
                case "Leave - FMLA":
                case "Leave - Medical":
                case "Leave - Military":
                case "Leave - Non-FMLA":
                case "Leave - Parental":
                case "Leave - Personal":
                  dayData.leave++;
                  break;
                case "Holiday":
                  dayData.holiday++;
                  break;
                case "Floating Holiday":
                  dayData.floatingHoliday++;
                  break;
                case "Stand By":
                  dayData.standBy++;

                  break;
                default:
                  break;
              }

              //Work Day based on Type
              if (workAssignmentTypes.includes(assignment.type)) {
                dayData.work++;
              }

              //Health Risk based on Origin

              if (
                healthRiskAssignmentTypes.includes(assignment.type) &&
                origin &&
                this.overlaps(dayStart, dayEnd, origin.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Origin , except for "Special Assignment" which pays perdiem even at home base
              if (
                perdiemAssignmentTypes.includes(assignment.type) &&
                (assignment.type === "Special Assignment" ||
                  gatewayCode !== assignment.originIata)
              ) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }

              //Perdiem based on Destination , except for "Special Assignment" which pays perdiem even at home base
              if (
                perdiemAssignmentTypes.includes(assignment.type) &&
                (assignment.type === "Special Assignment" ||
                  gatewayCode !== assignment.destinationIata)
              ) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }

              //Health Risk based on Destination

              if (
                healthRiskAssignmentTypes.includes(assignment.type) &&
                origin &&
                this.overlaps(dayStart, dayEnd, origin.covid19Periods)
              ) {
                dayData.healthRisk++;
              }
            } else {
              //---------------------------------------------Flight Assignment

              //Work Day
              dayData.work++;

              //Health Risk based on Origin
              if (
                origin &&
                this.overlaps(dayStart, dayEnd, origin.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Origin
              if (gatewayCode !== assignment.originIata) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }

              //Health Risk based on Destination

              if (
                destination &&
                this.overlaps(dayStart, dayEnd, destination.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Destination
              if (gatewayCode !== assignment.destinationIata) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }
            }
          } else if (startsBeforeToday && endsToday) {
            //The employee started the assignment  before the start of the day and ended the assignment before the day ended
            //Check assignment destination to calculate health risk and Perdiem

            if (assignment.type !== "Flight") {
              //LOA, Leavy, HOL, FH Days
              switch (assignment.type) {
                case "Sick":
                  dayData.sick++;
                  break;
                case "Vacation":
                  dayData.vacation++;
                  break;
                case "LOA - Jury Duty":
                case "LOA - Long Term Disability":
                case "LOA - Short Term Disability":
                case "LOA - Workers' Compensation":
                  dayData.loa++;
                  break;
                case "Leave - Bereavement":
                case "Leave - FMLA":
                case "Leave - Medical":
                case "Leave - Military":
                case "Leave - Non-FMLA":
                case "Leave - Parental":
                case "Leave - Personal":
                  dayData.leave++;
                  break;
                case "Holiday":
                  dayData.holiday++;
                  break;
                case "Floating Holiday":
                  dayData.floatingHoliday++;
                  break;
                case "Stand By":
                  dayData.standBy++;

                  break;
                default:
                  break;
              }

              //Work Day based on Type
              if (workAssignmentTypes.includes(assignment.type)) {
                dayData.work++;
              }

              //Health Risk based on Destination
              if (
                healthRiskAssignmentTypes.includes(assignment.type) &&
                destination &&
                this.overlaps(dayStart, dayEnd, destination.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Origin , except for "Special Assignment" which pays perdiem even at home base
              if (
                perdiemAssignmentTypes.includes(assignment.type) &&
                (assignment.type === "Special Assignment" ||
                  gatewayCode !== assignment.originIata)
              ) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }

              //Perdiem based on Destination , except for "Special Assignment" which pays perdiem even at home base
              if (
                perdiemAssignmentTypes.includes(assignment.type) &&
                (assignment.type === "Special Assignment" ||
                  gatewayCode !== assignment.destinationIata)
              ) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }
            } else {
              //---------------------------------------------Flight Assignment
              //Work Day
              dayData.work++;

              //Health Risk based on Destination
              if (
                destination &&
                this.overlaps(dayStart, dayEnd, destination.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Origin
              if (gatewayCode !== assignment.originIata) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }

              //Perdiem based on Destination
              if (gatewayCode !== assignment.destinationIata) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }
            }
          } else if (startsToday && endsAfterToday) {
            //The employee started the assignment on or after the start of the day and ended the assignment after the day ended
            //Check assignment origin to calculate health risk and Perdiem

            if (assignment.type !== "Flight") {
              //LOA, Leavy, HOL, FH Days
              switch (assignment.type) {
                case "Sick":
                  dayData.sick++;
                  break;
                case "Vacation":
                  dayData.vacation++;
                  break;
                case "LOA - Jury Duty":
                case "LOA - Long Term Disability":
                case "LOA - Short Term Disability":
                case "LOA - Workers' Compensation":
                  dayData.loa++;
                  break;
                case "Leave - Bereavement":
                case "Leave - FMLA":
                case "Leave - Medical":
                case "Leave - Military":
                case "Leave - Non-FMLA":
                case "Leave - Parental":
                case "Leave - Personal":
                  dayData.leave++;
                  break;
                case "Holiday":
                  dayData.holiday++;
                  break;
                case "Floating Holiday":
                  dayData.floatingHoliday++;
                  break;
                case "Stand By":
                  dayData.standBy++;

                  break;
                default:
                  break;
              }

              //Work Day based on Type
              if (workAssignmentTypes.includes(assignment.type)) {
                dayData.work++;
              }

              //Health Risk based on Origin
              if (
                healthRiskAssignmentTypes.includes(assignment.type) &&
                destination &&
                this.overlaps(dayStart, dayEnd, destination.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Origin , except for "Special Assignment" which pays perdiem even at home base
              if (
                perdiemAssignmentTypes.includes(assignment.type) &&
                (assignment.type === "Special Assignment" ||
                  gatewayCode !== assignment.originIata)
              ) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }

              //Perdiem based on Destination , except for "Special Assignment" which pays perdiem even at home base
              if (
                perdiemAssignmentTypes.includes(assignment.type) &&
                (assignment.type === "Special Assignment" ||
                  gatewayCode !== assignment.destinationIata)
              ) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }
            } else {
              //---------------------------------------------Flight Assignment

              //Work Day
              dayData.work++;

              //Health Risk based on Origin
              if (
                origin &&
                this.overlaps(dayStart, dayEnd, origin.covid19Periods)
              ) {
                dayData.healthRisk++;
              }

              //Perdiem based on Origin
              if (gatewayCode !== assignment.originIata) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }

              //Perdiem based on Destination
              if (gatewayCode !== assignment.destinationIata) {
                if (payInternationalPerdiem > 0) {
                  dayData.internationalPerdiem++;
                } else {
                  dayData.perdiem++;
                }
              }
            }
          }

          descriptionAndCodes = this.getDayDescriptionAndCodes(assignment);
          dayData.description = descriptionAndCodes.description;
          dayData.code = descriptionAndCodes.code;
          dayData.displayCode = descriptionAndCodes.displayCode;
          dayData.backgroundColor = descriptionAndCodes.backgroundColor;
          dayData.color =
            dayData.healthRisk > 0 || dayData.missionHazard > 0
              ? "#FF0000"
              : descriptionAndCodes.color;
        }
      } else {
        dayData.description = "Day Off";
        dayData.code = "OFF";
        dayData.displayCode = "OFF";
        dayData.backgroundColor = "#F0FFFF";
        dayData.color = "#000000";
        dayData.off = 1;
      }

      return dayData;
    },
    getDayDescriptionAndCodes(assignment) {
      let code = "---";
      let displayCode = "---";
      let backgroundColor = "red";
      let color = "#FFFFFF";

      switch (assignment.type) {
        case "Stand By":
          code = "STBY";
          displayCode = "STBY";
          backgroundColor = "#FFD394";
          color = "#000000";
          break;
        case "Station":
          code = "STA";
          displayCode = "STA ";
          backgroundColor = "#FFFFFF";
          color = "#000000";
          break;
        case "Special Assignment":
          code = "SPAS";
          displayCode = "SPAS";
          backgroundColor = "#FFFFFF";
          color = "#000000";
          break;
        case "Travel":
          code = "TRVL";
          displayCode = assignment.destinationIata;
          backgroundColor = "#F6F110";
          color = "#0000FF";
          break;
        case "Layover":
          code = "LYVR";
          displayCode = "LYVR";
          backgroundColor = "#FFFFFF";
          color = "#0000FF";
          break;
        case "Training - Ground School":
        case "Training - SIM":
          code = "TRNG";
          displayCode = "TRNG";
          backgroundColor = "#FFFFFF";
          color = "#000000";
          break;
        case "Sick":
          code = "SICK";
          displayCode = "SICK";
          backgroundColor = "#FFFFFF";
          color = "#000000";
          break;
        case "Quarantine":
          code = "QUA";
          displayCode = "QUA";
          backgroundColor = "#FFFFFF";
          color = "#000000";
          break;
        case "Quarantine - Day OFF":
          code = "QDO";
          displayCode = "QDO";
          backgroundColor = "#FFFFFF";
          color = "#000000";
          break;
        case "Vacation":
          code = "VAC";
          displayCode = "VAC";
          backgroundColor = "#00FFCC";
          color = "#000000";
          break;
        case "Holiday":
          code = "HOL";
          displayCode = "HOL";
          backgroundColor = "#FFFFFF";
          color = "#000000";
          break;
        case "Floating Holiday":
          code = "FHOL";
          displayCode = "FHOL";
          backgroundColor = "#FFFFFF";
          color = "#000000";
          break;
        case "Leave - Bereavement":
        case "Leave - FMLA":
        case "Leave - Medical":
        case "Leave - Military":
        case "Leave - Non-FMLA":
        case "Leave - Parental":
        case "Leave - Personal":
          code = "LEAV";
          displayCode = "LEAV";
          backgroundColor = "#FFFFFF";
          color = "#000000";
          break;
        case "LOA - Jury Duty":
        case "LOA - Long Term Disability":
        case "LOA - Short Term Disability":
        case "LOA - Workers' Compensation":
          code = "LOA";
          displayCode = "LOA";
          backgroundColor = "#FFFFFF";
          color = "#000000";
          break;
        case "Do Not Use":
          code = "DNU";
          displayCode = "DNU";
          backgroundColor = "#f56308";
          color = "#000000";
          break;

        default:
          code = "FLT";
          displayCode = assignment.destinationIata;
          backgroundColor = "#FFFFFF";
          color = "#000000";
          break;
      }

      return {
        description: this.formatAssignmentSnapshot(assignment),
        code,
        displayCode,
        backgroundColor,
        color,
      };
    },

    //---------------------------------Training Warnings
    getTrainingWarnings(
      certificates,
      role,
      assignmentEnd,
      aircraftTypes,
      companyCodes
    ) {
      let warnings = [];
      let daysRemaining = 0;

      let sortedCertificates;

      let redCertificates = [];
      let amberCertificates = [];
      let blackCertificates = [];

      //  Get red, amber, and black required certificate lists
      switch (role) {
        case "Loadmaster":
          //Generic certificates, required for any aircraft type
          redCertificates = ["FACAOSSP", "SORT 1"];
          amberCertificates = ["DG"];
          blackCertificates = ["SMS", "DEICE"];

          //Aircraft specific certificates
          if (aircraftTypes.includes("76F")) {
            redCertificates.push("B767WB");
          }

          if (aircraftTypes.includes("77F")) {
            redCertificates.push("B777_dif");
          }

          if (aircraftTypes.includes("LCF")) {
            redCertificates.push("LCFWB");
            redCertificates.push("LCFGEN");
            redCertificates.push("LCFLCL");
            redCertificates.push("LCFMTS");
            redCertificates.push("gmlf 1.1");
          }

          if (aircraftTypes.includes("737") || aircraftTypes.includes("738")) {
            blackCertificates.push("737SDT");
          }

          break;
        case "PSR":
        case "POC":
        case "GSC":
          // Note: Aircraft types have no effect on required certificates
          redCertificates = ["B747 PAXS", "TSAAOSSP"];
          amberCertificates = ["DG"];
          blackCertificates = ["EAPRP", "SMS", "DEICE"];

          break;
        case "PAA":
          // Note: Aircraft types have no effect on required certificates
          redCertificates = [];
          amberCertificates = ["DG", "SORT", "OSSP"];
          blackCertificates = ["DEICE"];

          break;

        case "Mechanic":
          //Aircraft Types
          // 744
          // 74Y
          // 748
          // 763
          // 76F
          // 744
          // 74Y
          // 748
          // 763
          // 76F
          // 738
          // 77F

          //Atlas Air AMBER certificate definitions
          // 5Y-76X-REC   =  5Y REC L1 AUTH || 5Y REC L2 AUTH
          // 5Y-76X-ARA   =  5Y 767-300 ARA AUTH  || 5Y767-3 NO ETOPS ARA AUTH
          //

          //----------------------------------Atlas Air
          if (companyCodes.includes("A")) {
            if (
              // aircraftTypes.includes("744") ||
              // aircraftTypes.includes("74Y") ||
              // aircraftTypes.includes("747")
              aircraftTypes.includes("744") ||
              aircraftTypes.includes("74Y") ||
              aircraftTypes.includes("747") ||
              aircraftTypes.includes("PAX") ||
              aircraftTypes.includes("LCF")
            ) {
              redCertificates.push("5Y RVSM AUTH");
              redCertificates.push("5Y 400 ARA AUTH");
              redCertificates.push("5Y 400 CAT AUTH");
              redCertificates.push("5Y 400 RII AUTH");
              amberCertificates.push("5Y-744-REC");
            }

            // NOTE
            // "5Y-744-REC" Includes the following certificates
            //  5Y REC L1 AUTH
            //  5Y REC L2 AUTH

            //These aircraft types were move to the 744 Group above
            // if (aircraftTypes.includes("PAX") || aircraftTypes.includes("LCF")) {
            //   redCertificates.push("5Y REC L1 AUTH");
            //   redCertificates.push("5Y REC L2 AUTH");
            //   redCertificates.push("5Y RVSM AUTH");
            //   redCertificates.push("5Y 400 ARA AUTH");
            //   redCertificates.push("5Y 400 CAT AUTH");
            //   redCertificates.push("5Y 400 RII AUTH");
            // }

            if (aircraftTypes.includes("748")) {
              redCertificates.push("5Y RVSM AUTH");
              redCertificates.push("5Y 747-8 ARA AUTH");
              redCertificates.push("5Y 747-8 CAT AUTH");
              redCertificates.push("5Y 747-8 RII AUTH");
              amberCertificates.push("5Y-748-REC");
            }

            if (aircraftTypes.includes("77F")) {
              redCertificates.push("5Y RVSM AUTH");
              redCertificates.push("777-ARA-AUTH");
              redCertificates.push("777-LLM-AUTH");
              redCertificates.push("777-RII-AUTH");
              redCertificates.push("777-ETOPS-AUTH");
              amberCertificates.push("5Y-777-REC");
            }

            if (
              aircraftTypes.includes("763") ||
              aircraftTypes.includes("76F")
            ) {
              redCertificates.push("5Y RVSM AUTH");
              redCertificates.push("5Y 767 CAT AUTH");
              redCertificates.push("5Y 767 ETOPS AUTH");
              amberCertificates.push("5Y-76X-REC");
              amberCertificates.push("5Y-76X-ARA");
              amberCertificates.push("5Y-76X-RII");
            }

            // NOTES
            // 5Y-76X-ARA Includes the following certificates
            // 5Y 767-300 ARA AUTH
            // 5Y767-3 NO ETOPS ARA AUTH

            //=============================
            // 5Y-76X-RII Includes the following certificates
            // 5Y 767-300 RII AUTH
            // 5Y767-3 NO ETOPS RII AUTH

            if (
              aircraftTypes.includes("737") ||
              aircraftTypes.includes("738")
            ) {
              redCertificates.push("5Y RVSM AUTH");
              redCertificates.push("737NG-ARA-AUTH");
              redCertificates.push("737NG-LLM-AUTH");
              redCertificates.push("737NG-RII-AUTH");
              amberCertificates.push("5Y-73X-REC");
            }
          }

          //----------------------------------Polar
          if (companyCodes.includes("P")) {
            if (
              aircraftTypes.includes("744") ||
              aircraftTypes.includes("74Y")
            ) {
              redCertificates.push("PO RVSM AUTH");
              redCertificates.push("PO 400 ARA AUTH");
              redCertificates.push("PO 400 CAT AUTH");
              redCertificates.push("PO 400 RII AUTH");
              amberCertificates.push("PO-7XX-REC");
            }

            if (aircraftTypes.includes("748")) {
              redCertificates.push("PO RVSM AUTH");
              redCertificates.push("PO 747-8 ARA AUTH");
              redCertificates.push("PO 747-8 CAT AUTH");
              redCertificates.push("PO 747-8 RII AUTH");
              amberCertificates.push("PO-7XX-REC");
            }

            if (aircraftTypes.includes("77F")) {
              redCertificates.push("PO RVSM AUTH");
              redCertificates.push("PO-777-ARA-AUTH");
              redCertificates.push("PO-777-LLM-AUTH");
              redCertificates.push("PO-777-RII-AUTH");
              redCertificates.push("PO-777-ETOPS-AUTH");
              amberCertificates.push("PO-7XX-REC");
            }
          }

          break;

        default:
          break;
      }

      // Check each required certificate list againts the employee's list of certificates

      // RED Certificates. Generate a HARD warning if missing or expired
      if (redCertificates.length) {
        redCertificates.forEach((code) => {
          // Look for this certificate code in the employee's list of certificates
          const certificate = certificates.find((item) => {
            return item.code === code;
          });

          if (certificate) {
            // Check expiration date
            if (certificate.expires) {
              daysRemaining = this.getDaysRemaining(
                new Date(certificate.graceExpirationDate).getTime(),
                assignmentEnd
              );

              if (daysRemaining < 15) {
                warnings.push({
                  level: "Hard",
                  type: "Training Expiration (less than 15 days)",
                  message: `Training ${code} grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              } else if (daysRemaining < 30) {
                warnings.push({
                  level: "Soft",
                  type: "Training Expiration (less than 30 days)",
                  message: `Training ${code} grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              } else if (daysRemaining < 60) {
                warnings.push({
                  level: "Soft",
                  type: "Training Expiration (less than 60 days)",
                  message: `Training ${code} grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              }
            }
          } else {
            // Certificates was not found, add a hard warning for this certificate code
            warnings.push({
              level: "Hard",
              type: "Missing Training",
              message: `Missing certificate:  ${code}`,
            });
          }
        });
      }

      // AMBER Certificates. Look for certificate and alternate certificate. Generate a HARD warning if both missing or expired
      // AMBER Certificates are group by a common name. Certificates that share a common name are interchangable

      if (amberCertificates.length) {
        let employeeCertificates;
        let bestCertificate;

        amberCertificates.forEach((code) => {
          employeeCertificates = [];
          bestCertificate = null;

          switch (code) {
            case "DG":
              employeeCertificates = certificates.filter((item) => {
                return item.code === "DG 1-6" || item.code === "DG 10R";
              });

              break;

            case "SORT":
              employeeCertificates = certificates.filter((item) => {
                return (
                  item.code === "SORT 3" ||
                  item.code === "LVP_1" ||
                  item.code === "B747 PAXS"
                );
              });

              break;
            case "5Y-744-REC":
            case "5Y-748-REC":
            case "5Y-777-REC":
            case "5Y-76X-REC":
            case "5Y-73X-REC":
            case "PO-7XX-REC":
              //case "PO-76X-REC": //---Removed DE4895 - 17APRIL2024 - Jerry SADA---//
              employeeCertificates = certificates.filter((item) => {
                return (
                  item.code === "5Y REC L1 AUTH" ||
                  item.code === "5Y REC L2 AUTH"
                );
              });

              break;

            case "5Y-76X-ARA":
              //case "PO-76X-ARA": //---Removed DE4895 - 17APRIL2024 - Jerry SADA---//
              employeeCertificates = certificates.filter((item) => {
                return (
                  item.code === "5Y 767-300 ARA AUTH" ||
                  item.code === "5Y767-3 NO ETOPS ARA AUTH"
                );
              });

              break;

            case "5Y-76X-RII":
              employeeCertificates = certificates.filter((item) => {
                return (
                  item.code === "5Y 767-300 RII AUTH" ||
                  item.code === "5Y767-3 NO ETOPS RII AUTH"
                );
              });

              break;

            default:
              employeeCertificates = certificates.filter((item) => {
                return item.code === "TSAAOSSP" || item.code === "FACAOSSP";
              });

              break;
          }

          if (employeeCertificates.length) {
            sortedCertificates = employeeCertificates.sort(
              this.compareExpirationDates
            );
            bestCertificate = sortedCertificates[sortedCertificates.length - 1];
          }

          if (bestCertificate) {
            // Check the expiration date of the best certificate found
            daysRemaining = this.getDaysRemaining(
              new Date(bestCertificate.graceExpirationDate).getTime(),
              assignmentEnd
            );

            if (daysRemaining < 15) {
              warnings.push({
                level: "Hard",
                type: "Training Expiration (less than 15 days)",
                message: `Training ${code} grace expiration date: ${this.formatDate(
                  bestCertificate.graceExpirationDate
                )}`,
              });
            } else if (daysRemaining < 30) {
              warnings.push({
                level: "Soft",
                type: "Training Expiration (less than 30 days)",
                message: `Training ${code} grace expiration date: ${this.formatDate(
                  bestCertificate.graceExpirationDate
                )}`,
              });
            } else if (daysRemaining < 60) {
              warnings.push({
                level: "Soft",
                type: "Training Expiration (less than 60 days)",
                message: `Training ${code} grace expiration date: ${this.formatDate(
                  bestCertificate.graceExpirationDate
                )}`,
              });
            }
          } else {
            // Certificates was not found, add a hard warning for this certificate code
            warnings.push({
              level: "Hard",
              type: "Missing Training",
              message: `Missing certificate:  ${code}`,
            });
          }
        });
      }

      // BLACK Certificates. Generate a SOFT warning if missing or expired
      if (blackCertificates.length) {
        blackCertificates.forEach((code) => {
          // Look for this certificate code in the employee's list of certificates
          const certificate = certificates.find((item) => {
            return item.code === code;
          });

          if (certificate) {
            // Check expiration date
            if (certificate.expires) {
              certificate.graceExpirationDate =
                new Date(certificate.expires).getTime() +
                30 * 24 * 60 * 60 * 1000;

              daysRemaining = this.getDaysRemaining(
                new Date(certificate.graceExpirationDate).getTime(),
                assignmentEnd
              );

              if (daysRemaining < 15) {
                warnings.push({
                  level: "Soft",
                  type: "Training Expiration (less than 15 days)",
                  message: `Training ${code} grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              } else if (daysRemaining < 30) {
                warnings.push({
                  level: "Soft",
                  type: "Training Expiration (less than 30 days)",
                  message: `Training ${code} grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              } else if (daysRemaining < 60) {
                warnings.push({
                  level: "Soft",
                  type: "Training Expiration (less than 60 days)",
                  message: `Training ${code} grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              }
            }
          } else {
            // Certificates was not found, add a soft warning for this certificate code
            warnings.push({
              level: "Soft",
              type: "Missing Training",
              message: `Missing certificate:  ${code}`,
            });
          }
        });
      }

      return warnings;
    },

    compareExpirationDates(a, b) {
      if (
        new Date(a.expirationDate).getTime() <
        new Date(b.expirationDate).getTime()
      ) {
        return -1;
      }
      if (
        new Date(b.expirationDate).getTime() <
        new Date(a.expirationDate).getTime()
      ) {
        return 1;
      }
      return 0;
    },

    getDaysRemaining(expirationDate, latest) {
      //Note: param 'expirationDate' must be in milliseconds

      const daysRemaining = (expirationDate - latest) / (24 * 60 * 60 * 1000);

      //Returns whole days only

      if (daysRemaining > 0) {
        return Math.ceil(daysRemaining);
      } else {
        return 0;
      }
    },

    getGeneralWarnings(employees) {
      let warnings = [];
      let daysRemaining = 0;

      const assignmentEnd = new Date().getTime() + 72 * 60 * 60 * 1000; // Check next 72 hours

      let employee;

      for (let i = 0; i < employees.length; i++) {
        employee = employees[i];
        const employeeName = `${employee.surname}, ${employee.givenName}`;

        //--------------------Training Certificates
        if (employee.trainingRecords?.certificates.length) {
          // NOTE The training records are not checked against an assignment.
          // This function just checks for the expiration of any certificate
          // that the employee has
          employee.trainingRecords.certificates.forEach((certificate) => {
            // Check expiration date
            if (certificate.expires) {
              daysRemaining = this.getDaysRemaining(
                new Date(certificate.graceExpirationDate).getTime(),
                assignmentEnd
              );

              if (daysRemaining < 15) {
                warnings.push({
                  employeeName,
                  level: "Soft",
                  type: "Training Expiration (less than 15 days)",
                  message: `Training ${
                    certificate.title
                  } grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              } else if (daysRemaining < 30) {
                warnings.push({
                  employeeName,
                  level: "Soft",
                  type: "Training Expiration (less than 30 days)",
                  message: `Training ${
                    certificate.title
                  } grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              } else if (daysRemaining < 60) {
                warnings.push({
                  employeeName,
                  level: "Soft",
                  type: "Training Expiration (less than 60 days)",
                  message: `Training ${
                    certificate.title
                  } grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              }
            }
          });
        }
        //--------------------Documents
        const passports = employee.documents.filter((item) => {
          return item.type === "Passport";
        });

        if (passports.length) {
          passports.forEach((passport) => {
            daysRemaining = this.getDaysRemaining(
              new Date(passport.expirationDate).getTime(),
              assignmentEnd
            );

            if (daysRemaining < 30) {
              warnings.push({
                employeeName,
                level: "Hard",
                type: "Required Document",
                message: `Passport (${passport.country}-${
                  passport.number
                } ) expiration date: ${this.formatDate(
                  passport.expirationDate
                )} is 30 days or less from assignment date.`,
              });
            } else if (daysRemaining < 60) {
              warnings.push({
                employeeName,
                level: "Soft",
                type: "Required Document",
                message: `Passport (${passport.country}-${
                  passport.number
                } ) expiration date: ${this.formatDate(
                  passport.expirationDate
                )} is 60 days or less from assignment date.`,
              });
            }
          });
        } else {
          warnings.push({
            employeeName,
            level: "Hard",
            type: "Documents",
            message: `No Passport`,
          });
        }

        //Visas
        if (employee.visas?.length) {
          employee.visas.forEach((visa) => {
            daysRemaining = this.getDaysRemaining(
              new Date(visa.expirationDate).getTime(),
              assignmentEnd
            );

            if (daysRemaining < 15) {
              warnings.push({
                employeeName,
                level: "Hard",
                type: "Required Visa",
                message: `${
                  visa.country
                } visa expiration date: ${this.formatDate(
                  visa.expirationDate
                )} is 15 days or less from assignment date.`,
              });
            } else if (daysRemaining < 60) {
              warnings.push({
                employeeName,
                level: "Soft",
                type: "Required Visa",
                message: `${
                  visa.country
                } visa expiration date: ${this.formatDate(
                  visa.expirationDate
                )} is 60 days or less from assignment date.`,
              });
            }
          });
        }
      }

      return warnings;
    },

    getGroundAssignmentWarnings(employee, assignment) {
      let warnings = [];
      let daysRemaining = 0;
      const countriesThatRequireVisa = ["CHINA"];

      const assignmentStart = new Date(assignment.startTime).getTime();
      const assignmentEnd = new Date(assignment.endTime).getTime();

      // ------------------- Get previous assignments
      //Find previous assignments from 1 hour before this assignment start to 7 days before
      const previousAssignments = employee.assignments.filter((item) => {
        return new Date(item.endTime).getTime() < assignmentStart;
      });

      if (previousAssignments.length) {
        const lastAssignment =
          previousAssignments[previousAssignments.length - 1];

        if (lastAssignment.destinationIata !== assignment.originIata) {
          warnings.push({
            level: "Soft",
            type: "Route Discontinuity",
            message: `This assignment starts in ${assignment.originIata}, last assignment ended in ${lastAssignment.destinationIata}`,
          });
        }
      }

      //-----------------------Check Documents (Only applies to Travel)
      if (assignment.type === "Travel") {
        const passports = employee.documents.filter((item) => {
          return item.type === "Passport";
        });

        if (assignment.originCountry !== assignment.destinationCountry) {
          if (passports && passports.length) {
            const sortedPassports = passports.sort(this.compareExpirationDates);
            const bestPassport = sortedPassports[sortedPassports.length - 1];

            daysRemaining = this.getDaysRemaining(
              new Date(bestPassport.expirationDate).getTime(),
              assignmentEnd
            );

            if (daysRemaining < 30) {
              warnings.push({
                level: "Hard",
                type: "Passport Expiration",
                message: `Expiration: ${this.formatDate(
                  bestPassport.expirationDate
                )} `,
              });
            } else if (daysRemaining < 60) {
              warnings.push({
                level: "Soft",
                type: "Passport Expiration",
                message: `Expiration: ${this.formatDate(
                  bestPassport.expirationDate
                )} `,
              });
            }

            //Visas
            if (
              countriesThatRequireVisa.includes(
                assignment.destinationCountry.toUpperCase()
              )
            ) {
              if (employee.visas?.length) {
                const visa = employee.visas.find((v) => {
                  return (
                    v.country.toUpperCase() ===
                    assignment.destinationCountry.toUpperCase()
                  );
                });
                if (visa) {
                  daysRemaining = this.getDaysRemaining(
                    new Date(visa.expirationDate).getTime(),
                    assignmentEnd
                  );

                  if (daysRemaining < 15) {
                    warnings.push({
                      level: "Hard",
                      type: "Required Visa",
                      message: `${
                        visa.country
                      } visa expiration date: ${this.formatDate(
                        visa.expirationDate
                      )} is 15 days or less from assignment date.`,
                    });
                  } else if (daysRemaining < 60) {
                    warnings.push({
                      level: "Soft",
                      type: "Required Visa",
                      message: `${
                        visa.country
                      } visa expiration date: ${this.formatDate(
                        visa.expirationDate
                      )} is 60 days or less from assignment date.`,
                    });
                  }
                } else {
                  warnings.push({
                    level: "Hard",
                    type: "Required Visa",
                    message: `Employee does not have the required visa for ${assignment.destinationCountry}`,
                  });
                }
              } else {
                warnings.push({
                  level: "Hard",
                  type: "Required Visa",
                  message: `Employee does not have the required visa for ${assignment.destinationCountry}`,
                });
              }
            }
          } else {
            warnings.push({
              level: "Hard",
              type: "Document Missing",
              message: `Employee has no Passport`,
            });
          }
        }
      }

      //----------------------Bidline Warnings
      if (employee.biddays.length) {
        const daysOff = employee.biddays.filter((item) => {
          return item.code !== "RES";
        });

        let biddayConflicts = [];
        let dayStart;
        let dayEnd;

        daysOff.forEach((item) => {
          dayStart = new Date(
            Date.UTC(item.year, item.month, item.day)
          ).getTime();
          dayEnd =
            dayStart + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999;

          if (assignmentStart <= dayEnd && assignmentEnd >= dayStart) {
            biddayConflicts.push(item);
          }
        });

        //Add conflict warning
        if (biddayConflicts.length) {
          biddayConflicts.forEach((item) => {
            warnings.push({
              level: "Soft",
              type: "Bidline Conflict",
              message: `This assignment conflicts with "${
                item.description
              }" on ${this.formatDate(
                new Date(Date.UTC(item.year, item.month, item.day))
              )}`,
            });
          });
        }
      }

      return warnings;
    },

    getFlightAssignmentWarnings(employee, flight, role) {
      let warnings = [];
      let daysRemaining = 0;
      const countriesThatRequireVisa = ["CHINA"];
      const customerCodesThatRequireUsPassport = ["AMC", "MC"];
      const companyNamesThatRequireUsPassport = ["Atlas Air", "Flightline"];

      const assignmentStart = new Date(flight.effectiveTimeOut).getTime();
      const assignmentEnd = new Date(flight.effectiveTimeIn).getTime();
      const aircraftTypes = [flight.aircraft.type];
      const companyCodes = [flight.company]; // Used for the requirements of mechanics

      // ------------------- Get previous assignments
      //Find previous assignments from 1 hour before this assignment start to 7 days before
      const previousAssignments = employee.assignments.filter((item) => {
        return new Date(item.endTime).getTime() < assignmentStart;
      });

      if (previousAssignments.length) {
        const lastAssignment =
          previousAssignments[previousAssignments.length - 1];

        if (lastAssignment.destinationIata !== flight.originIata) {
          warnings.push({
            level: "Soft",
            type: "Route Discontinuity",
            message: `This assignment starts in ${flight.originIata}, last assignment ended in ${lastAssignment.destinationIata}`,
          });
        }
      }

      //--------------------Training Certificates
      if (employee.trainingRecords?.certificates.length) {
        const trainingWarnings = this.getTrainingWarnings(
          employee.trainingRecords.certificates,
          role,
          assignmentEnd,
          aircraftTypes,
          companyCodes
        );

        if (trainingWarnings.length) {
          trainingWarnings.forEach((item) => {
            warnings.push(item);
          });
        }
      } else {
        if (role === "Jumpseat") {
          warnings.push({
            level: "Soft",
            type: "Training Records",
            message: "No Training Records found",
          });
        } else {
          warnings.push({
            level: "Hard",
            type: "Training Records",
            message: "No Training Records found",
          });
        }
      }

      //--------------------Documents
      if (flight.originCountry !== flight.destinationCountry) {
        const passports = employee.documents.filter((item) => {
          return item.type === "Passport";
        });

        if (passports.length) {
          const sortedPassports = passports.sort(this.compareExpirationDates);
          const bestPassport = sortedPassports[sortedPassports.length - 1];

          daysRemaining = this.getDaysRemaining(
            new Date(bestPassport.expirationDate).getTime(),
            assignmentEnd
          );

          if (daysRemaining < 30) {
            warnings.push({
              level: "Hard",
              type: "Required Document",
              message: `Passport (${bestPassport.country}-${
                bestPassport.number
              } ) expiration date: ${this.formatDate(
                bestPassport.expirationDate
              )} is 30 days or less from assignment date.`,
            });
          } else if (daysRemaining < 60) {
            warnings.push({
              level: "Soft",
              type: "Required Document",
              message: `Passport (${bestPassport.country}-${
                bestPassport.number
              } ) expiration date: ${this.formatDate(
                bestPassport.expirationDate
              )} is 60 days or less from assignment date.`,
            });
          }
        } else {
          warnings.push({
            level: "Hard",
            type: "Required Document",
            message: `Employee has no Passport`,
          });
        }
      }

      //Visas
      if (
        countriesThatRequireVisa.includes(
          flight.destinationCountry.toUpperCase()
        )
      ) {
        if (employee.visas?.length) {
          const visa = employee.visas.find((v) => {
            return (
              v.country.toUpperCase() ===
              flight.destinationCountry.toUpperCase()
            );
          });
          if (visa) {
            daysRemaining = this.getDaysRemaining(
              new Date(visa.expirationDate).getTime(),
              assignmentEnd
            );

            if (daysRemaining < 15) {
              warnings.push({
                level: "Hard",
                type: "Required Visa",
                message: `${
                  flight.destinationCountry
                } visa expiration date: ${this.formatDate(
                  visa.expirationDate
                )} is 15 days or less from assignment date.`,
              });
            } else if (daysRemaining < 60) {
              warnings.push({
                level: "Soft",
                type: "Required Visa",
                message: `${
                  flight.destinationCountry
                } visa expiration date: ${this.formatDate(
                  visa.expirationDate
                )} is 60 days or less from assignment date.`,
              });
            }
          } else {
            warnings.push({
              level: "Hard",
              type: "Required Visa",
              message: `Employee does not have the required visa for ${flight.destinationCountry}`,
            });
          }
        } else {
          warnings.push({
            level: "Hard",
            type: "Required Visa",
            message: `Employee does not have the required visa for ${flight.destinationCountry}`,
          });
        }
      }

      //Flight Customer require US Passport
      if (
        companyNamesThatRequireUsPassport.includes(employee.companyName) &&
        customerCodesThatRequireUsPassport.includes(flight.customerCode)
      ) {
        let usPassports;
        let sortedUsPassports;
        let bestUsPassport;

        const passports = employee.documents.filter((item) => {
          return item.type === "Passport";
        });

        if (passports.length) {
          //Find US passports
          usPassports = passports.filter((item) => {
            return item.country === "United States";
          });

          if (usPassports.length) {
            //Sort US Passports
            sortedUsPassports = usPassports.sort(this.compareExpirationDates);
            bestUsPassport = sortedUsPassports[sortedUsPassports.length - 1];

            daysRemaining = this.getDaysRemaining(
              new Date(bestUsPassport.expirationDate).getTime(),
              assignmentEnd
            );

            //Check best passport is valid until the end of the flight
            if (daysRemaining < 30) {
              warnings.push({
                level: "Hard",
                type: "Required Document",
                message: `Passport (${bestUsPassport.country}-${
                  bestUsPassport.number
                } ) expiration date: ${this.formatDate(
                  bestUsPassport.expirationDate
                )} is 30 days or less from assignment date.`,
              });
            } else if (daysRemaining < 60) {
              warnings.push({
                level: "Soft",
                type: "Required Document",
                message: `Passport (${bestUsPassport.country}-${
                  bestUsPassport.number
                } ) expiration date: ${this.formatDate(
                  bestUsPassport.expirationDate
                )} is 60 days or less from assignment date.`,
              });
            }
          } else {
            warnings.push({
              level: "Hard",
              type: "U.S. Passport - Required Document",
              message: `Employee does not hold a valid U.S. Passport`,
            });
          }
        } else {
          warnings.push({
            level: "Hard",
            type: "Required Document",
            message: `Employee does not hold a valid U.S. Passport`,
          });
        }
      }

      //----------------------Bidline Warnings
      if (employee.biddays.length) {
        const daysOff = employee.biddays.filter((item) => {
          return item.code !== "RES";
        });

        let biddayConflicts = [];
        let dayStart;
        let dayEnd;

        daysOff.forEach((item) => {
          dayStart = new Date(
            Date.UTC(item.year, item.month, item.day)
          ).getTime();
          dayEnd =
            dayStart + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999;

          if (assignmentStart <= dayEnd && assignmentEnd >= dayStart) {
            biddayConflicts.push(item);
          }
        });

        //Add conflict warning
        if (biddayConflicts.length) {
          biddayConflicts.forEach((item) => {
            warnings.push({
              level: "Soft",
              type: "Bidline Conflict",
              message: `This assignment conflicts with "${
                item.description
              }" on ${this.formatDate(
                new Date(Date.UTC(item.year, item.month, item.day))
              )}`,
            });
          });
        }
      }

      return warnings;
    },

    getStationAssignmentWarnings(employee, flights, assignment) {
      let warnings = [];

      const assignmentStart = new Date(assignment.startTime).getTime();
      const assignmentEnd = new Date(assignment.endTime).getTime();

      let aircraftTypes = [];
      let companyCodes = []; // Used for the requirements of mechanics
      flights.forEach((item) => {
        if (!aircraftTypes.includes(item.aircraft.type)) {
          aircraftTypes.push(item.aircraft.type);
        }

        if (!companyCodes.includes(item.company)) {
          companyCodes.push(item.company);
        }
      });

      // ------------------- Get previous assignments
      //Find previous assignments from 1 hour before this assignment start to 7 days before
      const previousAssignments = employee.assignments.filter((item) => {
        return new Date(item.endTime).getTime() < assignmentStart;
      });

      if (previousAssignments.length) {
        const lastAssignment =
          previousAssignments[previousAssignments.length - 1];

        if (lastAssignment.destinationIata !== assignment.originIata) {
          warnings.push({
            level: "Soft",
            type: "Route Discontinuity",
            message: `This assignment starts in ${assignment.originIata}, last assignment ended in ${lastAssignment.destinationIata}`,
          });
        }
      }

      //--------------------Training Certificates
      if (employee.trainingRecords?.certificates.length) {
        const trainingWarnings = this.getTrainingWarnings(
          employee.trainingRecords.certificates,
          assignment,
          assignmentEnd,
          aircraftTypes,
          companyCodes
        );

        if (trainingWarnings.length) {
          trainingWarnings.forEach((item) => {
            warnings.push(item);
          });
        }
      } else {
        if (assignment.role === "Jumpseat") {
          warnings.push({
            level: "Soft",
            type: "Training Certificates",
            message: "No Training Records found",
          });
        } else {
          warnings.push({
            level: "Hard",
            type: "Training Certificates",
            message: "No Training Records found",
          });
        }
      }

      //----------------------Bidline Warnings
      if (employee.biddays.length) {
        const daysOff = employee.biddays.filter((item) => {
          return item.code !== "RES";
        });

        let biddayConflicts = [];
        let dayStart;
        let dayEnd;

        daysOff.forEach((item) => {
          dayStart = new Date(
            Date.UTC(item.year, item.month, item.day)
          ).getTime();
          dayEnd =
            dayStart + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999;

          if (assignmentStart <= dayEnd && assignmentEnd >= dayStart) {
            biddayConflicts.push(item);
          }
        });

        //Add conflict warning
        if (biddayConflicts.length) {
          biddayConflicts.forEach((item) => {
            warnings.push({
              level: "Soft",
              type: "Bidline Conflict",
              message: `This assignment conflicts with "${
                item.description
              }" on ${this.formatDate(
                new Date(Date.UTC(item.year, item.month, item.day))
              )}`,
            });
          });
        }
      }

      return warnings;
    },

    //#region ------------------------------- FORMATTERS

    formatDateTime(d) {
      if (d) {
        const d1 = new Date(d).toUTCString().toString();

        return `${d1.substring(5, 17)} - ${d1.substring(16, 22)}`;
      } else {
        return "---";
      }
    },

    formatAssignmentSnapshot(assignment) {
      if (assignment.type === "Ground") {
        return this.formatGroundAssignmentSnapshot(assignment);
      } else {
        return this.formatFullFlightSnapshot(assignment.flight);
      }
    },

    formatFlightSnapshot(flight) {
      const timeOut = this.formatDateTime(flight.effectiveTimeOut);

      const snapshot = `${flight.flightNumber} . ${flight.aircraft.registration} . ${flight.originIata}-${flight.destinationIata} . ${timeOut} Z`;
      return snapshot;
    },

    formatGroundAssignmentSnapshot(assignment) {
      //const timeOut = this.formatDateTime(assignment.startTime);
      // const timeIn = this.formatTime(assignment.endTime);
      let snapshot = "??";

      switch (assignment.type) {
        case "Travel":
          snapshot = `${assignment.type} . ${assignment.originIata} - ${assignment.destinationIata}`;
          break;
        default:
          // case "Special Assignment":
          // case "Leave":
          // case "Leave - Bereavement":
          // case "Leave - FMLA":
          // case "Leave - Medical":
          // case "Leave - Military":
          // case "Leave - Non-FMLA":
          // case "Leave - Parental":
          // case "Leave - Personal":
          // case "LOA":
          // case "LOA - Jury Duty":
          // case "LOA - Long Term Disability":
          // case "LOA - Short Term Disability":
          // case "LOA - Workers' Compensation":
          // case "Layover":
          // case "Stand By":
          // case "Quarantine":
          // case "Quarantine - Day OFF":
          // case "Sick":
          // case "Training":
          // case "Training - Ground School":
          // case "Training - SIM":
          // case "Do Not Use":
          snapshot = `${assignment.type} . ${assignment.originIata}`;
          break;
      }

      return snapshot;
    },

    formatFullFlightSnapshot(flight) {
      const timeOut = this.formatDateTime(flight.effectiveTimeOut);
      const timeIn = this.formatTime(flight.effectiveTimeIn);

      const snapshot = `${flight.flightNumber} . ${flight.aircraftRegistration} . ${flight.originIata} - ${flight.destinationIata} . ${timeOut} Z - ${timeIn} Z`;

      return snapshot;
    },

    formatWeight(w) {
      return w.toLocaleString();
    },

    formatDate(d) {
      if (d) {
        return new Date(d).toUTCString().substring(5, 16);
      } else {
        return "---";
      }
    },

    formatTime(d) {
      return new Date(d).toUTCString().substring(17, 22);
    },

    formatMinutes(minutes) {
      const _h = Math.floor((minutes % (24 * 60)) / 60);
      const _m = Math.floor((minutes % (24 * 60)) % 60);

      const h = _h < 10 ? `0${_h}` : _h;
      const m = _m < 10 ? `0${_m}` : _m;

      return `${h}+${m}`;
    },

    formatDay(d, scale) {
      if (scale > 8) {
        return new Date(d).toUTCString().toString().substring(5, 8);
      } else {
        return new Date(d).toUTCString().substring(0, 16);
      }
    },

    formatMonth(m) {
      switch (m) {
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";

        case 10:
          return "October";

        case 11:
          return "November";

        case 12:
          return "December";

        default:
          return "---";
      }
    },

    formatMonthShort(m) {
      switch (m) {
        case 0:
          return "JAN";
        case 1:
          return "FEB";
        case 2:
          return "MAR";
        case 3:
          return "APR";
        case 4:
          return "MAY";
        case 5:
          return "JUN";
        case 6:
          return "JUL";
        case 7:
          return "AUG";
        case 8:
          return "SEP";

        case 9:
          return "OCT";

        case 10:
          return "NOV";

        case 11:
          return "DEC";

        default:
          return "---";
      }
    },
    formatStationAssignmentNotification(assignment) {
      const timeOut = this.formatDateTime(assignment.startTime);
      const timeIn = this.formatTime(assignment.endTime);

      return `${assignment.originIata} . ${
        assignment.subCategory
      } . ${timeOut} Z - ${timeIn} Z . ${
        assignment.group.flightIds.length
      } Assignment(s) . ${this.formatMinutes(assignment.duration)}`;
    },

    formatGroundAssignmentNotification(assignment) {
      const timeOut = this.formatDateTime(assignment.startTime);
      const updatedAt = this.formatDateTime(assignment.updatedAt);

      switch (assignment.category) {
        case "Special Assignment":
        case "Layover":
        case "Quarantine":
        case "Sick":
          return `${assignment.category} . ${assignment.originIata} . ${timeOut} Z . Assigned by: ${assignment.assignedBy} on ${updatedAt} Z.`;
        case "Travel":
          return `${assignment.category} . ${assignment.originIata} - ${assignment.destinationIata} . ${timeOut} Z . Assigned by: ${assignment.assignedBy} on ${updatedAt} Z.`;
        case "Leave":
          return `${assignment.category} . ${assignment.subCategory} . ${timeOut} Z . Assigned by: ${assignment.assignedBy} on ${updatedAt} Z.`;
        case "Training":
          return `${assignment.category} . ${assignment.subCategory} . ${assignment.originIata} . ${timeOut} Z . Assigned by: ${assignment.assignedBy} on ${updatedAt} Z.`;
        default:
          return `${assignment.category} . ${timeOut} . Assigned by: ${assignment.assignedBy} on ${updatedAt} z.`;
      }
    },

    formatFlightAssignmentNotification(assignment) {
      const timeOut = this.formatDateTime(assignment.flight.effectiveTimeOut);
      const updatedAt = this.formatDateTime(assignment.updatedAt);
      const flightRole = assignment.role;

      return `Flight Assignment . ${flightRole} . ${assignment.flight.flightNumber} . ${assignment.flight.aircraftRegistration} . ${assignment.flight.originIata}-${assignment.flight.destinationIata} . ${timeOut} Z . Assigned by: ${assignment.assignedBy} on ${updatedAt} Z.`;
    },

    formatElapsedTime(t) {
      const addLeadingZeros = (number) => {
        return String(number).padStart(2, "0");
      };

      const h = addLeadingZeros(Math.floor(t / 60));
      const m = addLeadingZeros(t % 60);

      return `${h}+${m}`;
    },

    formatStationAssignmentFlight(flight, originIata) {
      const timeOut = this.formatDateTime(flight.effectiveTimeOut);
      const timeIn = this.formatDateTime(flight.effectiveTimeIn);

      let aircraftType = "PAX";

      if (flight.aircraft.cargoOnly) {
        aircraftType = "CARGO";
      }

      let snapshot = "---";

      if (flight.originIata === originIata) {
        snapshot = `${aircraftType} - DEP: ${timeOut}  | ${flight.flightNumber} . ${flight.aircraftRegistration} . ${flight.originIata} - ${flight.destinationIata}`;
      } else {
        snapshot = `${aircraftType} - ARR: ${timeIn}  | ${flight.flightNumber} . ${flight.aircraftRegistration} . ${flight.originIata} - ${flight.destinationIata}`;
      }

      return snapshot;
    },

    formatConflictText(previousFlight, flight, employee) {
      const timeIn = this.formatDateTime(previousFlight.effectiveTimeIn);
      const timeOut = this.formatDateTime(flight.effectiveTimeOut);
      const employeeName = `${employee.surname}, ${employee.givenName}`;

      const snapshot = `${employeeName} . Flight ${previousFlight.flightNumber} arrives at ${previousFlight.destinationIata} on ${timeIn}, next flight ${flight.flightNumber} departs from ${flight.originIata} on ${timeOut}`;
      return snapshot;
    },

    formatName(givenName, surname, format) {
      if (format === "f") {
        return `${givenName} ${surname}`;
      } else if (format === "sub") {
        return `${surname}, ${givenName.substring(0, 1)}`;
      } else {
        return `${surname}, ${givenName}`;
      }
    },

    //#endregion

    overlaps(dayStart, dayEnd, periods) {
      if (periods.length) {
        let periodStart;
        let periodEnd;

        let count = 0;

        periods.forEach((period) => {
          periodStart = new Date(period.start).getTime();
          periodEnd = new Date(period.end).getTime();

          if (dayStart <= periodEnd && dayEnd >= periodStart) {
            count++;
          }
        });

        return count > 0;
      } else {
        return false;
      }
    },

    //#region COMPARES

    compareNames(a, b) {
      if (a < b) {
        return -1;
      }
      if (b < a) {
        return 1;
      }
      return 0;
    },

    //-----------------------------Special Airports
    compareAirportNameUp(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (b.name < a.name) {
        return 1;
      }
      return 0;
    },

    compareAirportNameDown(a, b) {
      if (a.name < b.name) {
        return 1;
      }
      if (b.name < a.name) {
        return -1;
      }
      return 0;
    },

    compareAirportCountryUp(a, b) {
      if (a.country < b.country) {
        return -1;
      }
      if (b.country < a.country) {
        return 1;
      }
      return 0;
    },

    compareAirportCountryDown(a, b) {
      if (a.country < b.country) {
        return 1;
      }
      if (b.country < a.country) {
        return -1;
      }
      return 0;
    },

    compareAirportIataUp(a, b) {
      if (a.iata < b.iata) {
        return -1;
      }
      if (b.iata < a.iata) {
        return 1;
      }
      return 0;
    },

    compareAirportIataDown(a, b) {
      if (a.iata < b.iata) {
        return 1;
      }
      if (b.iata < a.iata) {
        return -1;
      }
      return 0;
    },

    compareAirportIcaoUp(a, b) {
      if (a.icao < b.icao) {
        return -1;
      }
      if (b.icao < a.icao) {
        return 1;
      }
      return 0;
    },

    compareAirportIcaoDown(a, b) {
      if (a.icao < b.icao) {
        return 1;
      }
      if (b.icao < a.icao) {
        return -1;
      }
      return 0;
    },

    //-------------------------Warnings

    compareWarningTypeUp(a, b) {
      if (a.type < b.type) {
        return -1;
      }
      if (b.type < a.type) {
        return 1;
      }
      return 0;
    },

    compareWarningTypeDown(a, b) {
      if (a.type < b.type) {
        return 1;
      }
      if (b.type < a.type) {
        return -1;
      }
      return 0;
    },

    compareWarningMessageUp(a, b) {
      if (a.message < b.message) {
        return -1;
      }
      if (b.message < a.message) {
        return 1;
      }
      return 0;
    },

    compareWarningMessageDown(a, b) {
      if (a.message < b.message) {
        return 1;
      }
      if (b.message < a.message) {
        return -1;
      }
      return 0;
    },

    //---------------------Flights

    compareFlightAircraftRegistrationUp(a, b) {
      if (a.aircraft.registration < b.aircraft.registration) {
        return -1;
      }
      if (b.aircraft.registration < a.aircraft.registration) {
        return 1;
      }
      return 0;
    },

    compareFlightAircraftRegistrationDown(a, b) {
      if (a.aircraft.registration < b.aircraft.registration) {
        return 1;
      }
      if (b.aircraft.registration < a.aircraft.registration) {
        return -1;
      }
      return 0;
    },

    compareAircrafts(a, b) {
      if (a.registration < b.registration) {
        return -1;
      }
      if (b.registration < a.registration) {
        return 1;
      }
      return 0;
    },

    compareFlights(a, b) {
      if (
        new Date(a.effectiveTimeOut).getTime() <
        new Date(b.effectiveTimeOut).getTime()
      ) {
        return -1;
      }
      if (
        new Date(b.effectiveTimeOut).getTime() <
        new Date(a.effectiveTimeOut).getTime()
      ) {
        return 1;
      }
      return 0;
    },

    compareVaccines(a, b) {
      if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
        return -1;
      }
      if (new Date(b.date).getTime() < new Date(a.date).getTime()) {
        return 1;
      }
      return 0;
    },

    comparePassports(a, b) {
      if (
        new Date(a.expirationDate).getTime() <
        new Date(b.expirationDate).getTime()
      ) {
        return -1;
      }
      if (
        new Date(b.expirationDate).getTime() <
        new Date(a.expirationDate).getTime()
      ) {
        return 1;
      }
      return 0;
    },

    compareExemptions(a, b) {
      if (a.key < b.key) {
        return -1;
      }
      if (b.key < a.key) {
        return 1;
      }
      return 0;
    },

    compareAssignments(a, b) {
      if (new Date(a.startTime).getTime() < new Date(b.startTime).getTime()) {
        return -1;
      }
      if (new Date(b.startTime).getTime() < new Date(a.startTime).getTime()) {
        return 1;
      }
      return 0;
    },

    //#endregion

    getLocationLeft(time, filterStart, scale) {
      // Notes
      // time and filterStart are passed as strings
      // scale is passed as number
      const minutes =
        (new Date(time).getTime() - new Date(filterStart).getTime()) /
        1000 /
        60;
      return minutes / scale;
    },
  },
};
