<template>
  <div>
    <div class="backdrop"></div>
    <dialog open>
      <div class="px-5 mb-2">
        <div class="text-3xl text-blue-500-d3 font-bold my-2">
          {{ `${employee.surname}, ${employee.givenName}` }}
        </div>
        <div>
          {{
            `Warnings: ${hardWarnings.length} hard, ${
              employee.warnings.length - hardWarnings.length
            } soft.`
          }}
        </div>
      </div>
      <section>
        <div
          class="warning-tile"
          :class="{ 'hard-warning': warning.level === 'Hard' }"
          v-for="(warning, index) in employee.warnings"
          :key="index"
        >
          <div class="flex justify-between font-2xl font-bold text-gray-500">
            <div>
              {{ warning.type }}
            </div>
            <i
              v-if="warning.level === 'Hard'"
              class="fa-solid fa-octagon-exclamation text-red-500"
            ></i>
            <i v-else class="fa-solid fa-warning text-yellow-300"></i>
          </div>

          <div class="text-sm text-gray-500">{{ warning.message }}</div>
        </div>
      </section>

      <p class="mx-5 text-lg text-red-500">
        By clicking acknowledge, you confirm that you have read and understood
        these warnings.
      </p>

      <div class="flex justify-end px-4 py-2">
        <button
          @click="handleClose('cancel')"
          class="btn bg-gray-500 text-white"
        >
          Cancel
        </button>

        <button
          @click="handleClose('acknowledge')"
          class="ml-1 btn bg-blue-500 text-white"
        >
          Acknowledge
        </button>
      </div>
    </dialog>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
export default {
  mixins: [mixin],
  methods: {
    handleClose(action) {
      this.$emit("close", { action });
    },
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hardWarnings() {
      return this.employee.warnings.filter((item) => {
        return item.level === "Hard";
      });
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 700;
}

dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  border-radius: 5px;
  border-width: 3px 3px 3px 10px;
  border-style: solid;
  border-color: red;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  z-index: 800;
}

section {
  padding: 0 1rem;
  min-height: 10rem;
  max-height: 26rem;
  overflow-y: auto;
}

.warning-tile {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px 10px;
  color: black;
  margin-bottom: 3px;
}

.hard-warning {
  background-color: pink;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>
