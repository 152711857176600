<template>
  <div>
    <div class="backdrop" @click="handleBackDropClick"></div>
    <dialog open>
      <slot></slot>
    </dialog>
  </div>
</template>

<script>
export default {
  methods: {
    handleBackDropClick() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 700;
}

dialog {
  position: fixed;
  top: 10vh;
  left: 15%;
  width: 70%;
  border-radius: 5px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  z-index: 800;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 350px);
    width: 700px;
  }
}

@media (min-width: 1024px) {
  dialog {
    left: calc(50% - 500px);
    width: 1000px;
  }
}
</style>
