<template>
  <div class="outer-frame" :class="{ 'has-conflict': flight.hasConflict }">
    <div class="cursor-pointer text-[10px]">
      {{ flight.flightNumber }}
    </div>

    <div class="inner-frame" :style="getFlightLabelStyle()">
      {{ `${flight.originIata} - ${flight.destinationIata}` }}
    </div>
    <div class="flex justify-between text-[10px]">
      <div>{{ flight.effectiveTimeOut.substring(11, 16) }}</div>
      <div>{{ flight.effectiveTimeIn.substring(11, 16) }}</div>
    </div>
  </div>
</template>
<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "flightLabel",
  mixins: [mixin],

  props: ["flight"],
  methods: {
    getFlightLabelStyle() {
      let style = {
        backgroundColor: "#FFFFFF",
        color: "#FF0000",
      };

      let crewFlag = "1px solid #bebebe";
      let groundCrewFlag = "1px solid #bebebe";
      let unassigned = [];
      let warnings = 0;

      if (this.flight.crewmembers?.length) {
        unassigned = this.flight.crewmembers.filter((crewmember) => {
          if (crewmember.warnings.length) {
            warnings += 1;
          }
          return !crewmember.employee;
        });

        if (unassigned.length === 0) {
          crewFlag = "5px solid #4CAF50"; //green
        } else {
          crewFlag = "5px solid #FFC107"; //amber
        }
      } else {
        crewFlag = "5px solid #673AB7"; //deep purple
      }

      if (this.flight.groundCrewmembers.length) {
        groundCrewFlag = "6px solid #641E16"; //#00FFCC
      }

      switch (this.flight.status) {
        case "In":
        case "On":
          style = {
            backgroundColor: "#273FCB",
            color: "#FFFFFF",
          };
          break;
        case "Off":
        case "Out":
          style = {
            backgroundColor: "#228833",
            color: "#FFFFFF",
          };
          break;
        case "Delayed":
          if (this.flight.isVip) {
            style = {
              backgroundColor: "#d795d4",
              color: "#FFFFFF",
              borderLeft: crewFlag,
              borderBottom: groundCrewFlag,
            };
          } else {
            style = {
              backgroundColor: "#D92120",
              color: "#FFFFFF",
              borderLeft: crewFlag,
              borderBottom: groundCrewFlag,
            };
          }

          break;
        case "Scheduled":
          if (this.flight.isVip) {
            style = {
              backgroundColor: "#96b2d7",
              color: "#000000",
              borderLeft: crewFlag,
              borderBottom: groundCrewFlag,
            };
          } else {
            style = {
              backgroundColor: "#F7EE55",
              color: "#000000",
              borderLeft: crewFlag,
              borderBottom: groundCrewFlag,
            };
          }
          break;
        default:
          break;
      }

      if (warnings > 0) {
        style.borderTop = "2px solid red";
      }

      return style;
    },
  },
};
</script>
<style scoped>
.outer-frame {
  position: absolute;
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
}

.has-conflict {
  border: 1px solid red;
  background-color: darksalmon;
}

.inner-frame {
  position: relative;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #bebebe;
  background-color: #939790;
  height: 27px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
</style>
