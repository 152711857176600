<template>
  <div>
    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-2"> </i></span
          ></RouterLink>
        </li>
        <li>
          <a href="#">
            <div class="ml-3 text-base lg:text-2xl text-white">
              {{ `Airports - ${filteredAirports.length}` }}
            </div>
          </a>
        </li>

        <li @click="handleOpenFilters" class="icon">
          <a href="#">
            <span class="tooltip">Airport Filters</span>
            <span
              ><i class="fa-solid fa-bars-filter text-yellow-500 ml-3"></i
            ></span>
          </a>
        </li>

        <li @click="getData" class="icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>

        <li>
          <RouterLink to="/"></RouterLink>
        </li>
      </ul>
    </nav>

    <!-- AUTO - LOGOUT -->
    <r-snackbar
      v-if="showLogoutWarning"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="120000"
      @close="resetTimers()"
    >
    </r-snackbar>

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <!--  FILTERS DIALOG -->
    <r-modal v-if="showFiltersDialog" @close="showFiltersDialog = false">
      <div
        v-if="showFiltersDialog"
        class="p-5 flex flex-col justify-between text-sm px-3"
      >
        <!-- Header -->
        <div class="flex justify-between mb-10">
          <div class="text-2xl font-bold ml-5">Airport Filters</div>
          <button @click="showFiltersDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <!-- Section -->
        <div class="px-5">
          <!-- Filter by Country-->
          <div class="border-b border-gray-400 mb-5">
            <div class="flex h-6 items-center mb-2">
              <input
                v-model="filterByCountry"
                id="filterByCountry"
                aria-describedby="filter-description"
                name="filterByCountry"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
              />

              <div class="ml-3 text-base leading-6">
                <label for="filterByCountry" class="font-medium text-gray-900"
                  >Filter by Country</label
                >
                {{ " " }}
              </div>
            </div>

            <div class="flex my-1 px-5 pb-2" v-if="filterByCountry">
              <Listbox as="div" v-model="selectedCountry" class="mb-10">
                <ListboxLabel
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Show Airports from</ListboxLabel
                >
                <div class="relative mt-2 min-w-[250px]">
                  <ListboxButton
                    class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  >
                    <span class="block truncate">{{ selectedCountry }}</span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                      <ChevronUpDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="(option, id) in companyCountries"
                        :key="id"
                        :value="option"
                        v-slot="{ active, selectedCountry }"
                      >
                        <li
                          :class="[
                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selectedCountry ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ option }}</span
                          >

                          <span
                            v-if="selectedCountry"
                            :class="[
                              active ? 'text-white' : 'text-blue-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>

          <!-- Filter by Special Period -->
          <div class="border-b border-gray-400 mb-5">
            <div class="flex h-6 items-center mb-2">
              <input
                v-model="filterBySpecialPeriods"
                id="filterBySpecialPeriods"
                aria-describedby="filter-description"
                name="filterBySpecialPeriods"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
              />

              <div class="ml-3 text-base leading-6">
                <label
                  for="filterBySpecialPeriods"
                  class="font-medium text-gray-900"
                  >Filter by Special Periods</label
                >
                {{ " " }}
              </div>
            </div>

            <div class="flex my-1 px-5 pb-2" v-if="filterBySpecialPeriods">
              <Listbox
                as="div"
                v-model="selectedSpecialPeriodsMatch"
                class="mb-10"
              >
                <ListboxLabel
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Show Airports with Special Periods</ListboxLabel
                >
                <div class="relative mt-2 min-w-[250px]">
                  <ListboxButton
                    class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  >
                    <span class="block truncate">{{
                      selectedSpecialPeriodsMatch
                    }}</span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                      <ChevronUpDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 max-h-80 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="(option, id) in specialPeriodsOptions"
                        :key="id"
                        :value="option"
                        v-slot="{ active, selectedSpecialPeriodsMatch }"
                      >
                        <li
                          :class="[
                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selectedSpecialPeriodsMatch
                                ? 'font-semibold'
                                : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ option }}</span
                          >

                          <span
                            v-if="selectedSpecialPeriodsMatch"
                            :class="[
                              active ? 'text-white' : 'text-blue-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>

              <div class="ml-3">
                <label
                  for="iata"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Check Date</label
                >
                <div class="mt-2">
                  <input
                    type="date"
                    v-model="selectedSpecialPeriodsDate"
                    name="period-date"
                    id="period-date"
                    class="min-h-9 width-block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Footer -->
        <div class="flex justify-end mt-1">
          <button @click="handleSaveFilters" class="btn bg-blue-500 text-white">
            Apply Filters
          </button>
        </div>
      </div>
    </r-modal>

    <!-- Data Table -->
    <div class="relative overflow-x-auto">
      <table
        v-if="filteredAirports.length"
        class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
      >
        <thead
          class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th
              scope="col"
              class="px-6 py-2 cursor-pointer"
              @click="sortAirports('name')"
            >
              Name
            </th>
            <th
              scope="col"
              class="px-6 py-2 cursor-pointer"
              @click="sortAirports('country')"
            >
              Country
            </th>
            <th
              scope="col"
              class="px-6 py-2 cursor-pointer"
              @click="sortAirports('iata')"
            >
              IATA Code
            </th>
            <th
              scope="col"
              class="px-6 py-2 cursor-pointer"
              @click="sortAirports('icao')"
            >
              ICAO Code
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="airport in filteredAirports"
            :key="airport._id"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600"
            @click="handleShowAirportInformation(airport)"
          >
            <td
              scope="row"
              class="px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              {{ airport.name }}
            </td>

            <td class="px-6 py-2">{{ airport.country }}</td>

            <td class="px-6 py-2">
              {{ airport.iata }}
            </td>
            <td class="px-6 py-2">{{ airport.icao }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else class="p-5 text-2xl text-green-600">
        No airports found with the selected filters
      </div>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import { Switch } from "@headlessui/vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "airports",
  mixins: [mixin],
  components: {
    RSnackbar,
    RSpinner,
    RModal,
    Switch,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
  },

  created() {
    this.getData();
  },

  mounted() {
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimers);
    });

    this.setTimers();
  },

  destroyed() {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimers);
    });

    this.resetTimers();
  },

  data() {
    return {
      //-----------------------------------------Auto-Logout
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      logoutTimer: null,
      showLogoutWarning: false,
      autologoutIn: 60,

      loading: false,
      actionOptions: [
        "Add COVID-19 / Hazard periods",
        "End COVID-19 periods on",
        "End HAZARD periods on",
        "Delete COVID-19 periods",
        "Delete HAZARD periods",
      ],
      selectedAction: "Add COVID-19 / Hazard periods",
      airports: [],

      //-------------------------------------------Sorting

      sortAirportsName: "",
      sortAirportsCountry: "",
      sortAirportsIata: "",
      sortAirportsIcao: "",

      //--------------------------------Filters
      showFiltersDialog: false,

      selectedCountry: null,
      filterByCountry: false,

      specialPeriodsOptions: ["COVID-19", "Hazard"],
      selectedSpecialPeriodsMatch: null,
      selectedSpecialPeriodsDate: null,
      filterBySpecialPeriods: false,

      airport: null,

      selectedPeriods: [],

      showOnlyIfActiveCovid: false,
      showOnlyIfActiveHazard: false,
      showCovid19StartCalendar: false,
      showCovid19EndCalendar: false,
      selectedCovid19StartDate: new Date().toISOString().substring(0, 10),
      selectedCovid19EndDate: new Date(
        new Date().getTime() + 30 * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .substring(0, 10),
      showHazardStartCalendar: false,
      showHazardEndCalendar: false,
      selectedHazardStartDate: new Date().toISOString().substring(0, 10),
      selectedHazardEndDate: new Date(
        new Date().getTime() + 30 * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .substring(0, 10),

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      companyCountries: [],
      filteredAirports: [],
      country: "",
      search: "",

      isCovid19: false,
      isCovid19OpenEnded: false,
      isHazard: false,
      isHazardOpenEnded: false,
      targetDate: new Date().toISOString().substring(0, 10),
      filterDate: new Date().toISOString().substring(0, 10),
    };
  },

  computed: {
    airportsSpecialPeriodsFilter() {
      return this.$store.getters.airportsSpecialPeriodsFilter;
    },

    airportsCountryFilter() {
      return this.$store.getters.airportsCountryFilter;
    },

    showActionCalendar() {
      return (
        this.selectedAction === "End COVID-19 periods on" ||
        this.selectedAction === "End HAZARD periods on"
      );
    },
  },

  methods: {
    setTimers() {
      this.showLogoutWarning = false;
      this.warningTimer = setTimeout(() => {
        this.snackbarColor = "red";
        this.snackbarText =
          "Are you still there? Monarch will auto-logout in 1 minute";
        this.showLogoutWarning = true;
      }, (this.autologoutIn - 1) * 60 * 1000); //59 Minutes
      this.logoutTimer = setTimeout(
        this.handleAutoLogout,
        this.autologoutIn * 60 * 1000
      ); //60 Minutes
    },

    resetTimers() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },

    sortAirports(field) {
      switch (field) {
        case "name":
          if (this.sortAirportsName === "ASC") {
            this.filteredAirports.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (b.name < a.name) {
                return 1;
              }
              return 0;
            });

            this.sortAirportsName = "DESC";
          } else {
            this.filteredAirports.sort((a, b) => {
              if (a.name > b.name) {
                return -1;
              }
              if (b.name > a.name) {
                return 1;
              }
              return 0;
            });

            this.sortAirportsName = "ASC";
          }

          break;

        case "country":
          if (this.sortAirportsCountry === "ASC") {
            this.filteredAirports.sort((a, b) => {
              if (a.country < b.country) {
                return -1;
              }
              if (b.country < a.country) {
                return 1;
              }
              return 0;
            });

            this.sortAirportsCountry = "DESC";
          } else {
            this.filteredAirports.sort((a, b) => {
              if (a.country > b.country) {
                return -1;
              }
              if (b.country > a.country) {
                return 1;
              }
              return 0;
            });

            this.sortAirportsCountry = "ASC";
          }
          break;

        case "iata":
          if (this.sortAirportsIata === "ASC") {
            this.filteredAirports.sort((a, b) => {
              if (a.iata < b.iata) {
                return -1;
              }
              if (b.iata < a.iata) {
                return 1;
              }
              return 0;
            });

            this.sortAirportsIata = "DESC";
          } else {
            this.filteredAirports.sort((a, b) => {
              if (a.iata > b.iata) {
                return -1;
              }
              if (b.iata > a.iata) {
                return 1;
              }
              return 0;
            });

            this.sortAirportsIata = "ASC";
          }
          break;

        case "icao":
          if (this.sortAirportsIcao === "ASC") {
            this.filteredAirports.sort((a, b) => {
              if (a.icao < b.icao) {
                return -1;
              }
              if (b.icao < a.icao) {
                return 1;
              }
              return 0;
            });

            this.sortAirportsIcao = "DESC";
          } else {
            this.filteredAirports.sort((a, b) => {
              if (a.icao > b.icao) {
                return -1;
              }
              if (b.icao > a.icao) {
                return 1;
              }
              return 0;
            });

            this.sortAirportsIcao = "ASC";
          }
          break;

        default:
          break;
      }
    },

    async handleAutoLogout() {
      aad.logout();
      this.$store.commit("updateUser", null);
      this.$router.push({ name: "home" });
    },

    handleGoBack() {
      this.$router.go(-1);
    },

    async getData() {
      try {
        this.loading = true;

        const token = await aad.getIdTokenSilent();

        const res = await api.get(`/airports`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
        });

        this.loading = false;

        this.airports = res.data.airports;
        this.$store.commit("updateAirports", res.data.airports);

        let countries = [];

        this.airports.forEach((airport) => {
          if (!countries.includes(airport.country)) {
            countries.push(airport.country);
          }
        });

        this.companyCountries = countries.sort(this.compareNames);
        this.filterAirports();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleOpenFilters() {
      if (!this.selectedCountry) {
        this.selectedCountry = "CHINA";
      }

      if (!this.selectedSpecialPeriodsMatch) {
        this.selectedSpecialPeriodsMatch = "COVID-19";
      }

      if (!this.selectedSpecialPeriodsDate) {
        this.selectedSpecialPeriodsDate = new Date()
          .toISOString()
          .substring(0, 10);
      }

      //----------------------------------------Country Filter
      if (this.airportsCountryFilter.active) {
        this.filterByCountry = true;
        this.selectedCountry = this.airportsCountryFilter.country.slice();
      }

      //---------------------------------------Special Period
      if (this.airportsSpecialPeriodsFilter.active) {
        this.filterBySpecialPeriods = true;
        this.selectedSpecialPeriodsMatch =
          this.airportsSpecialPeriodsFilter.match.slice();
        this.selectedSpecialPeriodsDate = new Date(
          this.airportsSpecialPeriodsFilter.date
        )
          .toISOString()
          .substring(0, 10);
      }

      this.showFiltersDialog = true;
    },

    handleSaveFilters() {
      this.showFiltersDialog = false;

      //-----------------------------Country Filter
      let countryFilter = {
        active: false,
        country: "",
      };

      if (this.filterByCountry && this.selectedCountry) {
        countryFilter = {
          active: true,
          country: this.selectedCountry.slice(),
        };
      }

      this.$store.commit("updateAirportsCountryFilter", countryFilter);

      //----------------------------Special Periods Filter
      let specialPeriodsFilter = {
        active: false,
        match: "",
        date: null,
      };

      if (this.filterBySpecialPeriods && this.selectedSpecialPeriodsMatch) {
        specialPeriodsFilter = {
          active: true,
          match: this.selectedSpecialPeriodsMatch.slice(),
          date: this.selectedSpecialPeriodsDate,
        };
      }

      this.$store.commit(
        "updateAirportsSpecialPeriodsFilter",
        specialPeriodsFilter
      );

      this.filterAirports();
    },

    handleShowAirportInformation(airport) {
      let periods = [];

      if (airport.covid19Periods.length) {
        airport.covid19Periods.forEach((period) => {
          periods.push({
            category: "COVID-19",
            start: this.formatDate(period.start),
            end: this.formatDate(period.end),
            _id: period._id,
          });
        });
      }

      if (airport.hazardPeriods.length) {
        airport.hazardPeriods.forEach((period) => {
          periods.push({
            category: "Hazard",
            start: this.formatDate(period.start),
            end: this.formatDate(period.end),
            _id: period._id,
          });
        });
      }

      this.$store.commit("updateAirport", { ...airport, periods });
      this.$router.push({ name: "airport" });
    },

    filterAirports() {
      let filterOne;
      let filterTwo;

      if (this.airportsCountryFilter.active) {
        filterOne = this.airports.filter((airport) => {
          return (
            airport.country === this.airportsCountryFilter.country.toUpperCase()
          );
        });
      } else {
        filterOne = [...this.airports];
      }

      if (this.airportsSpecialPeriodsFilter.active) {
        const startTime = new Date(
          this.airportsSpecialPeriodsFilter.date
        ).getTime();
        const endTime = new Date(
          this.airportsSpecialPeriodsFilter.date
        ).getTime();

        if (this.airportsSpecialPeriodsFilter.match === "COVID-19") {
          filterTwo = filterOne.filter((airport) => {
            return this.overlaps(startTime, endTime, airport.covid19Periods);
          });
        } else {
          filterTwo = filterOne.filter((airport) => {
            return this.overlaps(startTime, endTime, airport.hazardPeriods);
          });
        }
      } else {
        filterTwo = [...filterOne];
      }

      this.filteredAirports = [...filterTwo];
    },

    goHome() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style scoped>
.actions-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 3px;
}
</style>
