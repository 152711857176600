<template>
  <div>
    <!-- AUTO - LOGOUT -->
    <r-snackbar
      v-if="showLogoutWarning"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="120000"
      @close="resetTimers()"
    >
    </r-snackbar>

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="ml-3 text-2xl text-white">About</div>
          </a>
        </li>

        <li class="icon" @click="getData">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>

        <li>
          <a href="#">
            <span
              class="mx-2 text-white cursor-pointer"
              @click="showBackendEnvironmentData"
            >
              {{ `Version ${version} - ${build}` }}
            </span>
          </a>
        </li>
      </ul>
    </nav>

    <div v-if="!loading">
      <div v-if="selectedVersion" class="p-5">
        <div class="flex justify-between text-xl">
          <div class="flex">
            <div class="flex justify-end mt-2">
              <button
                @click="handleGetNextVersion"
                class="btn-icon"
                :class="{ hidden: selectedVersionIndex === versions.length }"
              >
                <i class="fa-solid fa-arrow-left"></i>
              </button>
              <h3 class="font-bold text-blue-500 mx-2">
                {{ `Version No. ${selectedVersion.name} ` }}
              </h3>
              <button
                @click="handleGetPreviousVersion"
                class="btn-icon"
                :class="{ hidden: selectedVersionIndex === 0 }"
              >
                <i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>

          <button @click="handleHideSelectedVersion" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div class="text-blue-600 ml-5">
          {{ `Released on ${formatDate(selectedVersion.releaseDate)}` }}
        </div>

        <div class="frame">
          <div v-if="selectedVersion.improvements.length">
            <div class="text-xl text-green-500">Improvements:</div>
            <ul class="text-base">
              <li
                v-for="(improvement, i) in selectedVersion.improvements"
                :key="i"
              >
                {{ improvement.description }}
              </li>
            </ul>
          </div>

          <div v-if="selectedVersion.fixes.length">
            <h3 class="text-xl text-orange-500">Fixes:</h3>
            <ul class="text-base">
              <li v-for="(fix, i) in selectedVersion.fixes" :key="i">
                {{ fix.description }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- Data Table -->
        <div class="relative overflow-x-auto">
          <table
            class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortVersions('name')"
                >
                  Version
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortVersions('releaseDate')"
                >
                  Release date
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortVersions('improvements')"
                >
                  Improvements
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortVersions('fixes')"
                >
                  Fixes
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="version in versions"
                :key="version._id"
                @click="handleSeeVersion(version)"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ version.name }}
                </td>
                <td class="px-6 py-2">{{ formatDate(version.releaseDate) }}</td>
                <td class="px-6 py-2">{{ version.improvements.length }}</td>
                <td class="px-6 py-2">{{ version.fixes.length }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";

export default {
  name: "about",
  mixins: [mixin],
  components: { RSnackbar, RSpinner },
  data() {
    return {
      //-----------------------------------------Auto-Logout
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      logoutTimer: null,
      showLogoutWarning: false,
      autologoutIn: 60,

      loading: false,

      //-----------------------Sorting
      sortVersionName: "",
      sortVersionReleaseDate: "",
      sortVersionImprovements: "",
      sortVersionFixes: "",

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      selectedVersion: null,
      selectedVersionIndex: 0,
      versions: [],
    };
  },

  mounted() {
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimers);
    });

    this.setTimers();
  },

  destroyed() {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimers);
    });

    this.resetTimers();
  },

  created() {
    this.getData();
  },

  computed: {},

  methods: {
    setTimers() {
      this.showLogoutWarning = false;
      this.warningTimer = setTimeout(() => {
        this.snackbarColor = "red";
        this.snackbarText =
          "Are you still there? Monarch will auto-logout in 1 minute";
        this.showLogoutWarning = true;
      }, (this.autologoutIn - 1) * 60 * 1000); //59 Minutes
      this.logoutTimer = setTimeout(
        this.handleAutoLogout,
        this.autologoutIn * 60 * 1000
      ); //60 Minutes
    },

    resetTimers() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },

    async handleAutoLogout() {
      aad.logout();
      this.$store.commit("updateUser", null);
      this.$router.push({ name: "home" });
    },

    sortVersions(field) {
      switch (field) {
        case "name":
          if (this.sortVersionName === "ASC") {
            this.versions.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (b.name < a.name) {
                return 1;
              }
              return 0;
            });

            this.sortVersionName = "DESC";
          } else {
            this.versions.sort((a, b) => {
              if (a.name > b.name) {
                return -1;
              }
              if (b.name > a.name) {
                return 1;
              }
              return 0;
            });

            this.sortVersionName = "ASC";
          }
          break;

        case "releaseDate":
          if (this.sortVersionReleaseDate === "ASC") {
            this.versions.sort((a, b) => {
              if (a.releaseDate < b.releaseDate) {
                return -1;
              }
              if (b.releaseDate < a.releaseDate) {
                return 1;
              }
              return 0;
            });

            this.sortVersionReleaseDate = "DESC";
          } else {
            this.versions.sort((a, b) => {
              if (a.releaseDate > b.releaseDate) {
                return -1;
              }
              if (b.releaseDate > a.releaseDate) {
                return 1;
              }
              return 0;
            });

            this.sortVersionReleaseDate = "ASC";
          }
          break;

        case "improvements":
          if (this.sortVersionImprovements === "ASC") {
            this.versions.sort((a, b) => {
              if (a.improvements < b.improvements) {
                return -1;
              }
              if (b.improvements < a.improvements) {
                return 1;
              }
              return 0;
            });

            this.sortVersionImprovements = "DESC";
          } else {
            this.versions.sort((a, b) => {
              if (a.improvements > b.improvements) {
                return -1;
              }
              if (b.improvements > a.improvements) {
                return 1;
              }
              return 0;
            });

            this.sortVersionImprovements = "ASC";
          }
          break;

        case "fixes":
          if (this.sortVersionFixes === "ASC") {
            this.versions.sort((a, b) => {
              if (a.fixes < b.fixes) {
                return -1;
              }
              if (b.fixes < a.fixes) {
                return 1;
              }
              return 0;
            });

            this.sortVersionFixes = "DESC";
          } else {
            this.versions.sort((a, b) => {
              if (a.fixes > b.fixes) {
                return -1;
              }
              if (b.fixes > a.fixes) {
                return 1;
              }
              return 0;
            });

            this.sortVersionFixes = "ASC";
          }
          break;

        default:
          break;
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },
    handleSeeVersion(v) {
      const i = this.versions.findIndex((item) => {
        return item.name === v.name;
      });

      this.selectedVersion = { ...v };
      this.selectedVersionIndex = i;
    },
    handleHideSelectedVersion() {
      this.selectedVersion = null;
    },
    handleGetPreviousVersion() {
      this.selectedVersionIndex -= 1;
      this.selectedVersion = this.versions[this.selectedVersionIndex];
    },
    handleGetNextVersion() {
      this.selectedVersionIndex += 1;
      this.selectedVersion = this.versions[this.selectedVersionIndex];
    },
    async showBackendEnvironmentData() {
      try {
        const url = api.defaults.baseURL;
        const res = await api.get(url.substring(0, url.length - 7));

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.snackbarColor = "#66BB6A";
        this.snackbarText = `${res.data.version} - ${res.data.status}`;
        this.snackbarVisible = true;
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },
    async getData() {
      try {
        this.loading = true;
        const token = await aad.getIdTokenSilent();

        const res = await api.get("/versions", {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
        });

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.versions = res.data.versions;

        this.loading = false;
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },
  },
};
</script>

<style scoped>
#scrollable-vertical-frame {
  max-height: calc(100vh - 250px);
  overflow-y: scroll;
}
</style>
