<template>
  <div>
    <!-- AUTO - LOGOUT -->
    <r-snackbar
      v-if="showLogoutWarning"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="120000"
      @close="resetTimers()"
    >
    </r-snackbar>

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="ml-3 text-base lg:text-2xl text-white">
              Security Groups
            </div>
          </a>
        </li>

        <li class="icon" @click="getData">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>

        <li
          v-if="canCreateSecurityGroups"
          class="icon"
          @click="handleNavidateToSecurityGroup(null)"
        >
          <a href="#">
            <span class="tooltip">Add Group</span>
            <span><i class="fa-solid fa-plus"></i></span>
          </a>
        </li>

        <li>
          <RouterLink to="/"></RouterLink>
        </li>
      </ul>
    </nav>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <div v-else class="px-5 py-2">
      <div
        class="flex justify-between border-b border-gray-500 px-3 py-1 cursor-pointer"
        v-for="group in securityGroups"
        :key="group._id"
        @click="handleNavidateToSecurityGroup(group)"
      >
        <div>{{ group.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";

export default {
  name: "securityGroups",
  mixins: [mixin],
  components: { RSnackbar, RSpinner, RModal },
  data() {
    return {
      //-----------------------------------------Auto-Logout
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      logoutTimer: null,
      showLogoutWarning: false,
      autologoutIn: 60,

      loading: false,
      securityGroups: [],

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
    };
  },

  mounted() {
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimers);
    });

    this.setTimers();
  },

  destroyed() {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimers);
    });

    this.resetTimers();
  },

  created() {
    this.getData();
  },

  methods: {
    setTimers() {
      this.showLogoutWarning = false;
      this.warningTimer = setTimeout(() => {
        this.snackbarColor = "red";
        this.snackbarText =
          "Are you still there? Monarch will auto-logout in 1 minute";
        this.showLogoutWarning = true;
      }, (this.autologoutIn - 1) * 60 * 1000); //59 Minutes
      this.logoutTimer = setTimeout(
        this.handleAutoLogout,
        this.autologoutIn * 60 * 1000
      ); //60 Minutes
    },

    resetTimers() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },

    async handleAutoLogout() {
      aad.logout();
      this.$store.commit("updateUser", null);
      this.$router.push({ name: "home" });
    },

    handleNavidateToSecurityGroup(group) {
      this.$store.commit("updateSecurityGroup", group);
      this.$router.push({ name: "securityGroup" });
    },

    async getData() {
      this.loading = true;

      this.securityGroups = [];

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.get("/security-groups", {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
        });

        this.loading = false;

        const securityGroups = res.data.securityGroups;

        this.$store.commit("updateSecurityGroups", securityGroups);

        this.securityGroups = securityGroups;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = `Error "${error}`;
        this.snackbarVisible = true;
      }
    },
  },
};
</script>
