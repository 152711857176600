<template>
  <div class="p-5 bg-white">
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>
    <div v-else>
      <div class="flex justify-between text-xl">
        <div class="flex items-center">
          <div class="header-title mr-3">
            {{ `${groundAssignment.originIata} - STATION ASSIGNMENT` }}
          </div>
          <button
            v-if="!isEditMode"
            @click="handleBeginEditing"
            class="btn-icon"
          >
            <i class="fa-solid fa-pen"></i>
          </button>
        </div>

        <button @click="handleCancel" class="btn-icon">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>

      <div class="flex my-1" v-if="groundAssignment.employee">
        <div class="font-bold">
          {{
            `${groundAssignment.employee.surname}, ${groundAssignment.employee.givenName}`
          }}
        </div>
        <div class="font-bold text-blue-600 ml-3">
          {{
            `(${groundAssignment.employee.companyName} - ${groundAssignment.employee.companyId.number})`
          }}
        </div>
        <button @click="handleSendNotification" class="btn-icon">
          <i class="fa-solid fa-envelope"></i>
        </button>
      </div>
      <div v-else>
        <div class="flex align-center">
          <div class="font-bold">
            <span>Assigned to:</span>
            <span class="ml-1 text-red-500">{{
              ` ${groundAssignment.assignedToCompany}`
            }}</span>
          </div>
        </div>
      </div>

      <div class="horizontal-line-gray my-1"></div>

      <div class="flex justify-between mb-2">
        <div class="text-3xl font-bold">
          {{ `${formatGroundAssignmentSnapshot(groundAssignment)}` }}
        </div>
      </div>
      <div class="horizontal-line-gray my-1"></div>

      <div
        v-for="flight in groundAssignment.flights"
        :key="flight.flightId"
        class="text-base mb-1 px-1"
        :class="[
          {
            'selected-for-delete': selectedForDelete.includes(flight._id),
          },
          {
            'cursor-pointer': isEditMode,
          },
        ]"
        @click="handleToggleSelectFlight(flight)"
      >
        {{ `${formatFlightSnapshot(flight)}` }}
      </div>

      <div class="horizontal-line-gray my-1"></div>

      <div v-if="!isEditMode">
        <span>Period:</span
        ><span class="text-base font-bold ml-2">
          {{
            `${formatDateTime(groundAssignment.startTime)} -  ${formatDateTime(
              groundAssignment.endTime
            )}`
          }}</span
        >
      </div>
      <div v-else class="flex my-1">
        <div>
          <p class="ml-2 text-sm text-gray-500">Start</p>

          <div class="flex">
            <input
              class="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="startDate"
              type="date"
            />
            <div>
              <input
                class="ml-2 block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                v-model="startTime"
                type="time"
              />
            </div>
          </div>
        </div>

        <div class="mx-5"></div>

        <div>
          <p class="ml-2 text-sm text-gray-500">End</p>
          <div class="flex">
            <input
              class="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="endDate"
              type="date"
            />

            <div>
              <input
                class="ml-2 block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                v-model="endTime"
                type="time"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="groundAssignment.employee">
        <span>Employee Phone:</span
        ><span class="text-base font-bold ml-2">{{
          groundAssignment.employee.mobilePhone
        }}</span>
      </div>

      <div>
        <span>Assigned by:</span
        ><span class="text-base font-bold ml-2">{{
          groundAssignment.assignedBy
        }}</span>
      </div>
      <div v-if="groundAssignment.warnings.length">
        <div
          class="border border-gray-500 rounded-md p-1 my-1"
          v-if="showAssignmentWarnings"
        >
          <div class="flex justify-between p-1 font-bold">
            <div>
              The following warnings were active when the assignment was made:
            </div>
            <i
              @click="showAssignmentWarnings = false"
              class="fa-solid fa-xmark cursor-pointer"
            ></i>
          </div>

          <div
            class="text-sm border-b p-1"
            :class="{ isHardWarning: warning.level === 'Hard' }"
            v-for="(warning, index) in groundAssignment.warnings"
            :key="index"
          >
            {{ warning.message }}
          </div>
        </div>
        <div v-else>
          <i
            @click="showAssignmentWarnings = true"
            class="fa-solid fa-triangle-exclamation text-orange-500 mr-2"
          ></i>
          <span class="text-red-500 font-bold">Assigned with Warnings</span>
        </div>
      </div>

      <div class="border-b border-gray-500 mb-1">
        <span>Last updated:</span
        ><span class="text-base font-bold ml-2">{{
          formatDateTime(groundAssignment.updatedAt)
        }}</span>
      </div>

      <div class="text-small text-gray-500">Remarks</div>

      <div v-if="!isEditMode">
        <p
          class="user-select-text text-base text-blue-600 border border-gray-500 rounded-md min-h-20 px-2"
        >
          {{ groundAssignment.remarks }}
        </p>
      </div>
      <div v-else>
        <div class="w-full">
          <div class="mb-2">
            <textarea
              v-model="remarks"
              rows="4"
              name="remarks"
              id="remarks"
              class="block w-full rounded-md border-0 py-1.5 text-base text-blue-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6"
            />
          </div>
        </div>
      </div>

      <!-- Remove employee OR delete assignment -->
      <div class="flex my-2" v-if="!isEditMode">
        <select
          class="block w-80 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
          v-model="selectedAction"
          :items="actionOptions"
        >
          <option v-for="option in actionOptions" :key="option">
            {{ option }}
          </option>
        </select>

        <button
          class="ml-1 btn bg-red-500 text-white"
          v-if="selectedAction !== 'Select'"
          @click="handleExecuteAction"
        >
          Confirm
        </button>
      </div>
      <div v-else class="flex justify-end my-1">
        <button @click="handleCancelEdit" class="btn bg-gray-500 text-white">
          Cancel
        </button>
        <button @click="handleSave" class="ml-1 btn bg-green-500 text-white">
          Save Assignment
        </button>
      </div>
      <div class="flex justify-between mt-1">
        <span class="text-xs color-gray selectable-text">{{
          groundAssignment._id
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";

export default {
  mixins: [mixin],
  props: ["groundAssignment"],
  components: { RSnackbar, RSpinner },
  data() {
    return {
      loading: false,
      warnings: null,
      isValid: true,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      dialog: true,
      remarks: "",
      stationRole: null,
      stationRoles: [],
      search: "",
      assignedToCompany: "Atlas Air",

      showAssignmentWarnings: false,

      //----------------Editing
      isEditMode: false,
      startDate: null,
      startTime: null,
      minimumDuration: 30,
      selectedForDelete: [],

      selectedAction: "Select",
    };
  },
  computed: {
    actionOptions() {
      let options = ["Select", "Delete Assignment"];

      if (this.groundAssignment.employee) {
        options.push("Delete Assignment and Notify Employee");
      }

      return options;
    },
    error() {
      return this.$store.getters.error;
    },

    employee() {
      return this.$store.getters.employee;
    },

    hardWarnings() {
      return this.warnings.filter((warning) => {
        return warning.level === "Hard";
      });
    },
  },

  methods: {
    handleBeginEditing() {
      this.startDate = new Date(this.groundAssignment.startTime)
        .toISOString()
        .substring(0, 10);
      this.startTime = new Date(this.groundAssignment.startTime)
        .toISOString()
        .substring(11, 16);

      this.endDate = new Date(this.groundAssignment.endTime)
        .toISOString()
        .substring(0, 10);
      this.endTime = new Date(this.groundAssignment.endTime)
        .toISOString()
        .substring(11, 16);

      this.remarks = this.groundAssignment.remarks
        ? this.groundAssignment.remarks.slice()
        : null;

      this.isEditMode = true;
    },

    handleCancelEdit() {
      this.isEditMode = false;
      this.selectedForDelete = [];
    },

    async handleSave() {
      this.isEditMode = false;

      const d1 = `${this.startDate}T${this.startTime}:00.000+00:00`;
      const d2 = `${this.endDate}T${this.endTime}:59.999+00:00`;

      const startDateTime = new Date(d1);
      const endDateTime = new Date(d2);

      const duration = Math.ceil(
        (endDateTime.getTime() - startDateTime.getTime()) / 60 / 1000
      );

      if (duration > 2 * 24 * 60) {
        this.snackbarColor = "red";
        this.snackbarText = `The maximum duration for a station assignment is 48 hours`;
        this.snackbarVisible = true;
        return;
      }

      // Remove any flights selected for deletion
      let flights;

      if (this.selectedForDelete.length) {
        flights = this.groundAssignment.flights.filter((flight) => {
          return !this.selectedForDelete.includes(flight._id);
        });
      } else {
        flights = [...this.groundAssignment.flights];
      }

      // All flights must overlap part of the selected assignment period
      let overlaps = 0;
      let timeOut;
      let timeIn;

      flights.forEach((flight) => {
        if (flight.originIata === this.groundAssignment.originIata) {
          //The departure time must overlap

          timeOut = new Date(flight.effectiveTimeOut).getTime();

          if (
            timeOut >= startDateTime.getTime() &&
            timeOut <= endDateTime.getTime()
          ) {
            overlaps += 1;
          }
        } else {
          //The arrival time must overlap

          timeIn = new Date(flight.effectiveTimeIn).getTime();

          if (
            timeIn >= startDateTime.getTime() &&
            timeIn <= endDateTime.getTime()
          ) {
            overlaps += 1;
          }
        }
      });

      if (overlaps !== flights.length) {
        this.snackbarColor = "red";
        this.snackbarText = `The selected period does not match the flight(s) schedule. Please check the start and/or end times.`;
        this.snackbarVisible = true;
        this.isEditMode = true;
        return;
      }

      let flightIds = [];
      flights.forEach((flight) => {
        flightIds.push(flight.flightId);
      });

      let stationAssignment = {
        _id: this.groundAssignment._id,
        confirmed: false,
        destinationIata: this.groundAssignment.destinationIata,
        originIata: this.groundAssignment.originIata,
        duration,
        flightIds,
        startTime: startDateTime,
        endTime: endDateTime,
        createdAt: this.groundAssignment.createdAt,
        role: this.groundAssignment.role,
        warnings: this.groundAssignment.warnings,
        remarks: this.remarks,
        type: "Station Assignment",
      };

      if (this.groundAssignment.employee) {
        stationAssignment.employee_id = this.groundAssignment.employee._id;
      } else {
        stationAssignment.assignedToCompany =
          this.groundAssignment.assignedToCompany;
      }

      this.loading = true;

      const res = await this.$store.dispatch(
        "updateStationAssignment",
        stationAssignment
      );

      this.loading = false;

      if (res.status !== 200) {
        this.snackbarColor = "red";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.$emit("close", true);
    },

    async handleExecuteAction() {
      this.loading = true;
      let res;

      res = await this.$store.dispatch(
        "deleteGroundAssignment",
        this.groundAssignment._id
      );

      if (res.status !== 200) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      if (this.selectedAction === "Delete Assignment and Notify Employee") {
        res = await this.$store.dispatch("sendAssignmentNotification", {
          assignment: this.groundAssignment,
          emailSubject: "Assignment cancelled",
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.snackbarColor = "#66BB6A";
        this.snackbarText = "Notification sent.";
        this.snackbarVisible = true;
        this.selectedAction = "Select";

        setTimeout(() => {
          this.$emit("close", true);
        }, 3000);
      } else {
        this.selectedAction = "Select";
        this.$emit("close", true);
      }
    },

    async handleSendNotification() {
      this.loading = true;

      const res = await this.$store.dispatch("sendAssignmentNotification", {
        assignment: this.groundAssignment,
        emailSubject: "Station Assignment",
      });

      this.loading = false;

      if (res.status !== 200) {
        this.snackbarColor = "red";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }
    },

    formatGroundAssignmentSnapshot(assignment) {
      const timeOut = this.formatDateTime(assignment.startTime);
      const timeIn = this.formatTime(assignment.endTime);

      return `${assignment.originIata} . ${
        assignment.role
      } . ${timeOut} Z - ${timeIn} Z . ${
        assignment.flightIds.length
      } Assignment(s) . ${this.formatMinutes(assignment.duration)}`;
    },

    handleCancel() {
      this.$emit("close", false);
    },

    handleToggleSelectFlight(flight) {
      if (!this.isEditMode) {
        return;
      }

      let i = this.selectedForDelete.findIndex((item) => {
        return item === flight._id;
      });

      if (i >= 0) {
        this.selectedForDelete.splice(i, 1);
      } else {
        this.selectedForDelete.push(flight._id);
      }
    },
  },
};
</script>

<style scoped>
.selected-for-delete {
  text-decoration: line-through;
  background-color: red;
  color: white;
}
.disclaimer-text {
  border: 1px solid red;
  border-radius: 3px;
  padding: 0 5px;
  margin: 5px 0;
  font-size: 1.5rem;
  text-align: center;
  color: red;
}

.edit-frame {
  border-style: solid;
  border-color: amber;
  border-width: 3px 3px 3px 10px;
  border-radius: 5px;
  background-color: #e0e0e0;
  padding: 10px;
  background-color: white;
}

.remarks {
  background-color: white;
  border: 1px solid #bebebe;
  border-radius: 5px;
  padding: 0 5px;
  cursor: pointer;
}

.header-title {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  color: blue;
}

i {
  cursor: pointer;
}

.isHardWarning {
  background-color: white;
  color: red;
}
</style>
