<template>
  <div>
    <!-- #region NAVBAR -->
    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>
        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">
              Flight Board
            </div>
          </a>
        </li>
        <li>
          <a href="#">
            <div
              v-if="boardStyle === 'Board' && pages.length"
              class="flex justify-between fnt-sm"
            >
              <button class="btn-icon" @click="handlePageUp">
                <i class="fa-solid fa-chevrons-up text-white"></i>
              </button>
              <div class="text-white">
                {{ `${pageNumber + 1} of ${pages.length}` }}
              </div>
              <button class="btn-icon" @click="handlePageDown">
                <i class="fa-solid fa-chevrons-down text-white"></i>
              </button>
            </div>
          </a>
        </li>

        <li @click="handleOpenFilters" class="icon">
          <a href="#">
            <span class="tooltip">Board Filters</span>
            <span><i class="fa-solid fa-bars-filter text-yellow-500"></i></span>
          </a>
        </li>

        <li
          class="icon"
          :class="{ hidden: !filteredWarnings.length }"
          @click="getWarnings(true)"
        >
          <a href="#">
            <span class="tooltip">{{
              `Warnings ${filteredWarnings.length}`
            }}</span>
            <span
              ><i class="fa-solid fa-triangle-exclamation text-orange-500"></i
            ></span>
          </a>
        </li>

        <li @click="handleInitTrip()" class="icon ml-5">
          <a href="#">
            <span class="tooltip">Trip Assignment</span>
            <span><i class="fa-solid fa-globe text-blue-300"></i></span>
          </a>
        </li>

        <li
          v-if="flightBoardAirportFilter.active"
          @click="handleInitStationAssignment()"
          class="icon"
        >
          <a href="#">
            <span class="tooltip">Station Assignment</span>
            <span><i class="fa-solid fa-warehouse text-orange-100"></i></span>
          </a>
        </li>

        <li @click="handleToggleBoardStyle" class="icon">
          <a href="#">
            <span class="tooltip">Switch Board</span>
            <span><i class="fa-solid fa-bolt text-yellow-500"></i></span>
          </a>
        </li>
        <li @click="showSpecialAirportsDialog = true" class="icon">
          <a href="#">
            <span class="tooltip">Special Airports</span>
            <span><i class="fa-solid fa-tower-control text-blue-600"></i></span>
          </a>
        </li>

        <li
          v-if="boardStyle !== 'List'"
          class="icon"
          @click="handleCaptureFlightBoard"
        >
          <a href="#">
            <span class="tooltip">Print Board</span>
            <span><i class="fa-solid fa-print text-white"></i></span>
          </a>
        </li>
        <li
          v-if="boardStyle !== 'List'"
          class="icon"
          @click="handleGetAircraftScheduleConflicts"
        >
          <a href="#">
            <span class="tooltip">Flight Conflicts</span>
            <span
              ><i
                class="fa-solid fa-calendar-circle-exclamation text-red-500"
              ></i
            ></span>
          </a>
        </li>
        <li class="icon" @click="openUnitConverter">
          <a href="#">
            <span class="tooltip">Weight Converter</span>
            <span
              ><i class="fa-solid fa-weight-hanging text-yellow-100"></i
            ></span>
          </a>
        </li>

        <li @click="zoomIn" class="icon" v-if="boardStyle !== 'List'">
          <a href="#">
            <span class="tooltip">Zoom In</span>
            <span><i class="fa-regular fa-magnifying-glass-plus"></i></span>
          </a>
        </li>
        <li @click="zoomOut" class="icon" v-if="boardStyle !== 'List'">
          <a href="#">
            <span class="tooltip">Zoom Out</span>
            <span><i class="fa-regular fa-magnifying-glass-minus"></i></span>
          </a>
        </li>
        <li @click="getData" class="nav-secondar icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>
      </ul>
    </nav>
    <!-- #endregion -->

    <!-- AUTO - LOGOUT -->
    <r-snackbar
      v-if="showLogoutWarning"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="120000"
      @close="resetTimers()"
    >
    </r-snackbar>

    <!-- #region WARNINGS -->

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- WARNING DIALOG -->
    <r-modal v-if="showWarningDialog" @close="showWarningDialog = false">
      <div class="border-l-8 border-2 border-red-500 p-2">
        <!-- TITLE -->
        <div class="flex justify-between mx-1">
          <div class="text-2xl font-bold mb-2">
            {{ `Warnings (${filteredWarnings.length})` }}
          </div>
          <button @click="showWarningDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="p-3 flex justify-between items-end border rounded-md mb-3">
          <div>
            <div class="text-sm text-gray-500">Filter Warnings</div>
            <select
              class="block w-80 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              @change="filterWarnings"
              v-model="selectedWarningFilter"
            >
              <option v-for="option in warningFilterOptions" :key="option">
                {{ option }}
              </option>
            </select>
          </div>

          <div>
            <i
              v-if="canUpdateCrewRequirements"
              class="txt-[48px] fa-solid fa-user-pilot text-blue-500 cursor-pointer"
              @click="handleNavigateToCrewRequirements"
            ></i>
          </div>
        </div>

        <!-- Data Table -->
        <div class="relative overflow-x-auto max-h-[300px]">
          <table
            v-if="filteredWarnings.length"
            class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortWarnings('type')"
                >
                  Type
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortWarnings('message')"
                >
                  Message
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(warning, id) in filteredWarnings"
                :key="id"
                @click="getFlightFromWarning(warning)"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
              >
                <td
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ warning.type }}
                </td>
                <td>{{ warning.message }}</td>
              </tr>
            </tbody>
          </table>
          <div v-else class="p-3 text-xl text-green-500">
            No warnings found with the selected filter
          </div>
        </div>
      </div>
    </r-modal>

    <!-- #region LOADING -->

    <r-spinner v-if="loading"> </r-spinner>
    <!-- #endregion -->

    <!-- #region DIALOGS -->
    <!-- FLIGHT ASSIGNMENT INFORMATION DIALOG -->
    <r-modal
      v-if="showFlightAssignmentInformationDialog"
      @close="handleHideFlightAssignmentInformationDialog($event)"
    >
      <FlightAssignmentInformation
        v-if="flightAssignment"
        :flightAssignment="flightAssignment"
        @close="handleHideFlightAssignmentInformationDialog($event)"
      ></FlightAssignmentInformation>
    </r-modal>

    <!-- NEW STATION ASSIGNMENT DIALOG -->
    <!-- Use this form for station assignments to companies -->
    <r-modal
      v-if="showNewStationAssignmentDialog"
      @close="showNewStationAssignmentDialog = false"
    >
      <div
        v-if="newStationAssignment && showNewStationAssignmentDialog"
        class="p-5"
      >
        <div class="flex justify-between mb-2">
          <div>
            <div class="text-2xl font-bold text-blue-500 mr-2">
              {{ `${newStationAssignment.originIata} - STATION ASSIGNMENT` }}
            </div>
          </div>
          <button
            @click="showNewStationAssignmentDialog = false"
            class="btn-icon"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="text-xl">
          {{
            `Assignments: ${
              newStationAssignment.flights.length
            },  Total Duty: ${formatElapsedTime(newStationAssignment.duration)}`
          }}
        </div>

        <div class="horizontal-line-blue my-3"></div>

        <div class="vertical-scroller-400">
          <div v-for="flight in newStationAssignment.flights" :key="flight._id">
            <div>{{ formatFlightSnapshot(flight) }}</div>
            <div class="horizontal-line-gray"></div>
          </div>
          <!-- ASSIGNMENT REMARKS -->
          <div class="text-small text-gray-500">Remarks</div>

          <div class="w-full">
            <div class="mb-2">
              <textarea
                v-model="stationAssignmentRemarks"
                rows="4"
                name="remarks"
                id="remarks"
                class="block w-full rounded-md border-0 py-1.5 text-base text-blue-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6"
              />
            </div>
          </div>
        </div>

        <div class="flex justify-end mt-2">
          <div class="flex">
            <select
              class="block w-48 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="selectedStationAssignmentCompany"
            >
              <option
                v-for="option in user.securityGroup.companies"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>

            <select
              class="ml-2 block w-48 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="selectedStationAssignmentRole"
            >
              <option
                v-for="option in authorizedFlightRoles"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>

          <button
            @click="handleSaveStationAssignment"
            class="btn ml-3 bg-blue-900 text-white"
          >
            Assign to Company
          </button>
        </div>
      </div>
    </r-modal>

    <!-- STATION ASSIGNMENT INFORMATION DIALOG -->
    <r-modal
      v-if="showStationAssignmentInformationDialog"
      @close="showStationAssignmentInformationDialog = false"
    >
      <station-assignment-information
        v-if="stationAssignment"
        :groundAssignment="stationAssignment"
        @close="hideStationAssignmentInformationDialog($event)"
      >
      </station-assignment-information>
    </r-modal>

    <!--  AIRCRAFT FILTER DIALOG -->
    <r-modal
      v-if="showAircraftFilterDialog"
      @close="showAircraftFilterDialog = false"
    >
      <div
        v-if="showAircraftFilterDialog"
        class="p-3 flex flex-col h-48 justify-between text-sm min-h-[500px]"
      >
        <!-- Header -->
        <div class="flex justify-between p-2">
          <div class="text-2xl font-bold">Aircraft Filter</div>
          <button @click="showAircraftFilterDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <!-- Section -->
        <div class="px-3">
          <div class="mb-2">
            <div class="flex h-6 items-center mb-10">
              <input
                v-model="filterByAircraft"
                id="filterByAircraft"
                aria-describedby="filter-description"
                name="filterByAircraft"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
              />

              <div class="ml-3 text-base leading-6">
                <label for="filterByAircraft" class="font-medium text-gray-900"
                  >Filter by Aircraft
                </label>
                {{ " " }}
              </div>
            </div>

            <div class="flex justify-start my-3" v-if="filterByAircraft">
              <div
                v-if="selectedAircraft.length !== aircraft.length"
                @click="selectedAircraft = [...aircraft]"
                class="my-1 ml-1 cursor-pointer text-base underline text-blue-600"
              >
                Select All
              </div>

              <div
                v-if="selectedAircraft.length"
                @click="selectedAircraft = []"
                class="my-1 ml-3 cursor-pointer text-base underline text-red-600"
              >
                Clear Selected
              </div>
            </div>
          </div>

          <div
            class="max-h-[250px] lg:max-h[350px] overflow-x-auto"
            v-if="filterByAircraft"
          >
            <!-- Data Table -->
            <div class="relative overflow-x-auto shadow-md">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
                <thead
                  class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
                >
                  <tr>
                    <th scope="col" class="p-4">Select</th>
                    <th
                      scope="col"
                      class="px-6 py-3 cursor-pointer"
                      @click="sortAircraft('registration')"
                    >
                      Registration
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 cursor-pointer"
                      @click="sortAircraft('type')"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 cursor-pointer"
                      @click="sortAircraft('cargo')"
                    >
                      Cargo / Passenger
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="_aircraft in aircraft"
                    :key="_aircraft._id"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td class="w-4 p-4">
                      <div class="flex items-center">
                        <input
                          v-model="selectedAircraft"
                          :id="_aircraft._id"
                          :value="_aircraft"
                          type="checkbox"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </div>
                    </td>
                    <td
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {{ _aircraft.registration }}
                    </td>

                    <td class="px-6 py-2">
                      {{ _aircraft.type }}
                    </td>

                    <td class="px-6 py-2">
                      {{ _aircraft.cargoOnly ? "Cargo" : "Passenger" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            v-else
            class="text-2xl text-green-500 text-center min-h-[300px] tracking-wider"
          >
            Filter by Aircraft is OFF. All Aircraft will be shown.
          </div>

          <div class="flex justify-end mt-2">
            <button
              v-if="filterByAircraft === false || selectedAircraft.length"
              @click="handleSaveAircraftFilter"
              class="btn bg-blue-500 text-white"
            >
              Apply Filter
            </button>
          </div>
        </div>
      </div>
    </r-modal>

    <!--  FILTERS DIALOG -->
    <r-modal v-if="showFiltersDialog" @close="showFiltersDialog = false">
      <div
        v-if="showFiltersDialog"
        class="p-5 flex flex-col h-48 justify-between text-sm min-h-[500px]"
      >
        <!-- Header -->
        <div class="flex justify-between p-2">
          <div class="text-2xl font-bold">Flight Board Filters</div>
          <button @click="showFiltersDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <!-- Section -->
        <div class="px-5">
          <!-- Filter by Aircraft Deck -->
          <div class="border-b border-gray-400 mb-5">
            <div class="flex h-6 items-center mb-2">
              <input
                v-model="filterByAircraftDeck"
                id="filterByAircraftDeck"
                aria-describedby="filter-description"
                name="filterByAircraftDeck"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
              />

              <div class="ml-3 text-base leading-6">
                <label
                  for="filterByAircraftDeck"
                  class="font-medium text-gray-900"
                  >Filter by Aircraft Deck</label
                >
                {{ " " }}
              </div>
            </div>

            <div class="flex my-1 px-5 pb-2" v-if="filterByAircraftDeck">
              <Listbox as="div" v-model="selectedAircraftDeck" class="mb-10">
                <ListboxLabel
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Show Employees when job title is associated
                  with</ListboxLabel
                >
                <div class="relative mt-2 min-w-[250px]">
                  <ListboxButton
                    class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  >
                    <span class="block truncate">{{
                      selectedAircraftDeck
                    }}</span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                      <ChevronUpDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="(option, id) in aircraftDeckOptions"
                        :key="id"
                        :value="option"
                        v-slot="{ active, selectedAircraftDeck }"
                      >
                        <li
                          :class="[
                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selectedAircraftDeck
                                ? 'font-semibold'
                                : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ option }}</span
                          >

                          <span
                            v-if="selectedAircraftDeck"
                            :class="[
                              active ? 'text-white' : 'text-blue-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>

          <!-- Filter by Station IATA -->
          <div class="border-b border-gray-400">
            <div class="flex h-6 items-center mb-2">
              <input
                v-model="filterByAirport"
                id="filterByIata"
                aria-describedby="comments-description"
                name="filterByIata"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
              />

              <div class="ml-3 text-base leading-6">
                <label for="filterByIata" class="font-medium text-gray-900"
                  >Filter by Station IATA</label
                >
                {{ " " }}
              </div>
            </div>

            <div class="flex my-1 px-5 pb-2" v-if="filterByAirport">
              <Listbox as="div" v-model="selectedIataMatch">
                <ListboxLabel
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Show Flights</ListboxLabel
                >
                <div class="relative mt-2 min-w-48">
                  <ListboxButton
                    class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  >
                    <span class="block truncate">{{ selectedIataMatch }}</span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                      <ChevronUpDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 max-h-80 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="(option, id) in iataMatchOptions"
                        :key="id"
                        :value="option"
                        v-slot="{ active, selectedIataMatch }"
                      >
                        <li
                          :class="[
                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selectedIataMatch
                                ? 'font-semibold'
                                : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ option }}</span
                          >

                          <span
                            v-if="selectedIataMatch"
                            :class="[
                              active ? 'text-white' : 'text-blue-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>

              <div class="ml-3">
                <label
                  for="iata"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >IATA</label
                >
                <div class="mt-2">
                  <input
                    type="text"
                    v-model="selectedIataCode"
                    placeholder="iata"
                    maxlength="3"
                    name="iata"
                    id="iata"
                    class="min-h-9 width-block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Footer -->
        <div class="flex justify-end mt-1">
          <button @click="handleSaveFilters" class="btn bg-blue-500 text-white">
            Apply Filters
          </button>
        </div>
      </div>
    </r-modal>

    <!-- DATE FILTER DIALOG -->
    <r-modal v-if="showDateFilterDialog" @close="showDateFilterDialog = false">
      <div v-if="showDateFilterDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Date Filter</div>
          <button @click="showDateFilterDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex">
          <div>
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Start Date</label
            >

            <input
              class="block w-32 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="startDate"
              type="date"
              id="date-filter"
            />
          </div>

          <div class="ml-3">
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Number of Days</label
            >

            <select
              class="block w-24 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="numberOfDays"
            >
              <option
                v-for="option in numberOfDaysOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex justify-end mt-1">
          <button
            @click="handleSaveDateFilter"
            class="btn bg-blue-500 text-white"
          >
            Apply Filter
          </button>
        </div>
      </div>
    </r-modal>

    <!-- SPECIAL AIRPORTS DIALOG -->
    <r-modal
      v-if="showSpecialAirportsDialog"
      @close="showSpecialAirportsDialog = false"
    >
      <SpecialAirports @hide="showSpecialAirportsDialog = false" />
    </r-modal>

    <!-- AIRPORT INFORMATION DIALOG -->
    <r-modal
      v-if="showAirportInformationDialog"
      @close="handleHideAirportInformationDialog"
    >
      <div
        v-if="airport"
        class="border-l-8 border-2 border-blue-500 text-base p-3"
      >
        <div class="flex justify-between mb-2 ml-2">
          <div class="text-3xl">
            {{ `${airport.iata} - ${airport.icao}` }}
          </div>
          <button @click="handleHideAirportInformationDialog" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div>Airport Name: {{ `  ${airport.name}` }}</div>
        <div>Country: {{ ` ${airport.country}` }}</div>

        <div>Min. Loadmaster Required Exemptions:</div>
        <div class="flex" v-if="airport.loadmasterExemptions.length">
          <div
            class="ml-2"
            v-for="(exemption, index) in airport.loadmasterExemptions"
            :key="index"
          >
            {{ `${exemption.aircraftType}, ` }}
          </div>
        </div>

        <div v-else>This airport has no exemptions</div>
      </div>
    </r-modal>

    <!-- PASSENGER PAYLOAD DIALOG-->
    <r-modal v-if="showPayloadDialog" @close="showPayloadDialog = false">
      <div class="p-5">
        <div class="flex justify-between mb-2 ml-2">
          <div class="text-2xl">Payload Calculator</div>
          <button @click="hidePayloadDialog" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div
          v-if="!showWeightConverter && !showReverseCalculator"
          class="mb-5 flex justify-between items-center pb-2 border-b border-gray-500"
        >
          <div class="text-xl">
            {{
              `${flight.flightNumber} . ${flight.aircraft.registration} . ${flight.originIata} - ${flight.destinationIata}`
            }}
          </div>

          <div class="flex">
            <button
              class="btn bg-blue-500 text-white"
              @click="handleShowWeightConverter"
            >
              Weight Converter
            </button>

            <button
              v-if="isValidNumberOfPassengers"
              @click="handleShowReverseCalculator"
              class="ml-1 btn bg-indigo-500 text-white"
            >
              Reverse Calculator
            </button>
          </div>
        </div>

        <div>
          <div
            class="flex justify-between items-center text-2xl text-blue-500 my-3"
            v-if="!showReverseCalculator && !showWeightConverter"
          >
            <div>
              {{ `Enter All Weights in ${flight.aircraft.weightUnit}` }}
            </div>
            <div class="flex text-xl text-right">
              <div>
                {{
                  `Max. Payload: ${formatWeight(
                    flight.aircraft.maximumPayload
                  )} `
                }}
              </div>
              <div class="ml-1">
                {{ flight.aircraft.weightUnit }}
              </div>
            </div>
          </div>

          <div v-if="!showReverseCalculator && !showWeightConverter">
            <div
              class="flex justify-between mb-5 items-center pb-2 border-b border-gray-300"
              v-if="swaCategory.name !== 'Ferry'"
            >
              <div class="flex">
                <div>
                  <div class="text-sm text-gray-500">Passengers</div>
                  <input
                    class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                    type="number"
                    step="10"
                    min="1"
                    max="400"
                    required
                    v-model="numberOfPassengers"
                  />
                </div>

                <div class="ml-2">
                  <div class="text-sm text-gray-500">Weight</div>
                  <div class="flex">
                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      step="100"
                      min="0"
                      max="500"
                      required
                      v-model="estimatedWeightPerPassenger"
                    />
                    <div class="ml-1 text-xl">
                      {{ flight.aircraft.weightUnit }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex justify-between py-2">
                <div class="text-xl">Preliminary Payload:</div>

                <div class="ml-2 flex justify-end text-xl">
                  <div>
                    {{ formatWeight(preliminaryWeight) }}
                  </div>
                  <div class="ml-1">
                    {{ flight.aircraft.weightUnit }}
                  </div>
                </div>
              </div>
            </div>

            <div
              class="flex justify-between items-center my-2 pb-2 border-b border-gray-300"
            >
              <div>
                <div class="text-sm text-gray-500">Buffer Weight</div>

                <select
                  class="block w-24 py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  v-model="selectedBufferWeight"
                  required
                >
                  <option
                    v-for="weight in availableBufferWeights"
                    :key="weight"
                    :value="weight"
                  >
                    {{ formatWeight(weight) }}
                  </option>
                </select>
              </div>

              <div class="flex justify-end text-xl text-right">
                <div>
                  {{ formatWeight(selectedBufferWeight) }}
                </div>
                <div class="ml-1">
                  {{ flight.aircraft.weightUnit }}
                </div>
              </div>
            </div>

            <div
              class="flex justify-between items-center my-2 pb-2 border-b border-gray-300"
            >
              <div>
                <div class="text-sm text-gray-500">SWA Category</div>
                <select
                  class="block w-24 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  v-model="swaCategory"
                  required
                >
                  <option
                    v-for="category in flight.aircraft.swaCategories"
                    :key="category.name"
                    :value="category"
                    @change="handleSWACategoryChange"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
              <div class="flex justify-end text-xl text-right">
                <div>
                  {{ formatWeight(swaCategory.value) }}
                </div>
                <div class="ml-1">
                  {{ flight.aircraft.weightUnit }}
                </div>
              </div>
            </div>

            <div class="flex text-2xl justify-between my-2">
              <div>Final Estimated Payload</div>
              <div class="flex justify-end text-2xl text-right">
                <div>
                  {{ formatWeight(finalEstimatedPayload) }}
                </div>
                <div class="ml-1">
                  {{ flight.aircraft.weightUnit }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="showReverseCalculator && !showWeightConverter">
            <!-- REVERSE CALCULATOR -->
            <div class="p-5 border border-gray-500 rounded-md bg-gray-100">
              <div class="flex">
                <div class="text-2xl">Reverse Calculator</div>
              </div>
              <div class="flex justify-center my-5">
                <div>
                  <div class="text-sm text-gray-500">
                    Final Estimated Payload
                  </div>
                  <div class="flex items-center">
                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-xl text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:leading-6"
                      type="number"
                      step="1000"
                      :min="selectedBufferWeight"
                      max="99999"
                      required
                      v-model="reverseCalculatorPayload"
                    />

                    <div class="ml-1 text-xl">
                      {{ flight.aircraft.weightUnit }}
                    </div>
                  </div>
                </div>
              </div>
              <p class="text-gray-500">
                {{
                  `The reverse calculator determines the average passenger weight, considering the final estimated payload, which includes the combined weight of all passengers and an additional buffer weight.`
                }}
              </p>

              <div class="flex justify-end">
                <button
                  @click="showReverseCalculator = false"
                  class="btn bg-gray-500 text-white"
                >
                  Close Reverse Calculator
                </button>

                <button
                  v-if="isValidReverseCalculatorPayload"
                  @click="calculateAveragePassengerWeight"
                  class="ml-1 btn bg-blue-500 text-white"
                >
                  Calculate
                </button>
              </div>
            </div>
          </div>

          <!-- WEIGHT CONVERTER -->
          <div
            class="my-5 bg-yellow-200 p-5 border-gray-500 rounded-md"
            v-if="showWeightConverter"
          >
            <div class="flex justify-center items-center text-xl my-2">
              <div>
                <div>Weight</div>
                <input
                  class="block w-24 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  type="number"
                  step="1000"
                  min="0"
                  max="99999"
                  required
                  v-model="weight"
                />
              </div>

              <div class="ml-1">
                <div>Units</div>
                <select
                  class="block w-24 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  v-model="units"
                  required
                >
                  <option
                    v-for="unit in convertFromOptions"
                    :key="unit"
                    :value="unit"
                  >
                    {{ unit }}
                  </option>
                </select>
              </div>
            </div>

            <div class="flex justify-center items-baseline text-3xl">
              {{ convertedWeight }}

              <div class="ml-10 flex h-6 items-center mb-2">
                <input
                  v-model="rounded"
                  id="roundup"
                  aria-describedby="filter-description"
                  name="roundup"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                />

                <div class="ml-3 text-base leading-6">
                  <label for="roundup" class="font-medium text-gray-900"
                    >Round Up</label
                  >
                  {{ " " }}
                </div>
              </div>
            </div>

            <div class="flex justify-end">
              <button
                @click="showWeightConverter = false"
                class="btn bg-gray-500 text-white"
              >
                Close Converter
              </button>
            </div>
          </div>
        </div>

        <div
          v-if="!showWeightConverter && !showReverseCalculator"
          class="flex justify-between mt-5"
        >
          <button
            @click="handleDeletePayload()"
            class="ml-1 btn bg-blue-500 text-white"
          >
            Reset Payload
          </button>

          <div class="flex justify-end">
            <button
              @click="handleSavePayload(true)"
              class="ml-1 btn bg-red-500 text-white"
              :class="{
                disabled:
                  !isValidNumberOfPassengers ||
                  !isValidEstimatedWeightPerPassenger,
              }"
            >
              Save Max. Payload
            </button>
            <button
              @click="handleSavePayload(false)"
              class="ml-1 btn bg-green-500 text-white"
              :class="{ disabled: !isValidFinalEstimatedPayload }"
            >
              Save Payload
            </button>
          </div>
        </div>
      </div>
    </r-modal>

    <!-- UNIT CONVERTER DIALOG-->
    <r-modal
      v-if="showUnitConverterDialog"
      @close="showUnitConverterDialog = false"
    >
      <div class="p-5 bg-yellow-100 text-sm">
        <div class="flex justify-between mb-5 ml-2">
          <div class="text-2xl">Weight Converter</div>
          <button class="btn-icon" @click="showUnitConverterDialog = false">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex justify-center items-center text-xl my-2">
          <div>
            <div>Weight</div>
            <input
              class="block w-24 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="number"
              step="1000"
              min="0"
              max="99999"
              required
              v-model="weight"
            />
          </div>

          <div class="ml-1">
            <div>Units</div>
            <select
              class="block w-24 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="units"
              required
            >
              <option
                v-for="unit in convertFromOptions"
                :key="unit"
                :value="unit"
              >
                {{ unit }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex justify-center items-baseline text-3xl">
          {{ convertedWeight }}

          <div class="ml-10 flex h-6 items-center mb-2">
            <input
              v-model="rounded"
              id="roundup"
              aria-describedby="filter-description"
              name="roundup"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="roundup" class="font-medium text-gray-900"
                >Round Up</label
              >
              {{ " " }}
            </div>
          </div>
        </div>
      </div>
    </r-modal>

    <!-- AIRCRAFT INFORMATION DIALOG -->
    <r-modal v-if="showAircraftDialog">
      <div
        v-if="myAircraft"
        class="border-l-8 border-2 border-blue-500 text-base p-3"
      >
        <div class="flex justify-between mb-5 ml-2">
          <div class="text-3xl">
            {{ myAircraft.registration }}
          </div>
          <button
            @click="[(showAircraftDialog = false), (myAircraft = null)]"
            class="btn-icon"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex flex-wrap gap-5 text-xl mb-3">
          <div>A/C Type: {{ myAircraft.typeDescription }}</div>

          <div>
            Max. Payload:
            {{
              `${formatWeight(myAircraft.maximumPayload)} ${
                myAircraft.weightUnit
              }`
            }}
          </div>

          <div>
            Configuration:
            {{ myAircraft.cargoOnly ? "Cargo" : "Passengers" }}
          </div>

          <div v-if="myAircraft.cargoOnly">
            CLS:
            {{ myAircraft.floorType }}
          </div>
        </div>

        <div v-if="!myAircraft.cargoOnly">
          <div class="border-y-2 py-2">
            <div class="text-lg font-bold">SWA Categories</div>
            <div class="flex flex-wrap gap-3">
              <div
                v-for="category in myAircraft.swaCategories"
                :key="category.name"
              >
                {{
                  `${category.name}: ${formatWeight(category.value)} ${
                    myAircraft.weightUnit
                  }`
                }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="!myAircraft.cargoOnly" class="py-2">
          <div class="text-lg font-bold">Cabin Configuration</div>
          <div v-for="(item, index) in myAircraft.cabinConfig" :key="index">
            {{ `Class "${item.class}": ${item.seats} Seat(s)` }}
          </div>
        </div>

        <div v-if="canUpdateAircraft" class="flex justify-end mt-3">
          <button
            @click="handleEditAircraft(myAircraft)"
            class="btn bg-blue-500 text-white ml-1"
          >
            Edit Aircraft
          </button>
        </div>
      </div>
    </r-modal>

    <!-- CARGO PAYLOAD DIALOG-->
    <r-modal
      v-if="showCargoPayloadDialog"
      @close="showCargoPayloadDialog = false"
    >
      <div class="p-5 -l2 text-sm">
        <div class="flex justify-between mb-2 ml-2">
          <div class="text-2xl">Cargo Payload</div>
          <button @click="showCargoPayloadDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div>
          <div class="flex justify-center">
            <div>
              <div class="text-sm">Estimated Payload</div>
              <div class="flex items-center">
                <input
                  class="block w-24 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  type="number"
                  step="1000"
                  min="0"
                  max="99999"
                  required
                  v-model="cargo"
                />

                <div class="ml-3">
                  {{ flight.aircraft.weightUnit }}
                </div>
              </div>
            </div>
          </div>
          <p class="text-sm text-red-500 text-center" v-if="cargoPayloadError">
            {{ cargoPayloadError }}
          </p>
        </div>

        <div class="flex justify-end items-center mt-2">
          <button
            @click="saveCargoPayload"
            class="ml-1 btn bg-green-500 text-white"
          >
            Save Payload
          </button>
        </div>
      </div>
    </r-modal>

    <!-- #endregion -->

    <!-- #region TRIP AND STATION ASSIGNMENT BUILDERS -->
    <!-- TRIP BUILDER-->
    <div
      v-if="trip"
      class="trip-frame flex flex-col justify-between min-h-[150px] w-full bg-gray-400 px-2 py-1"
    >
      <div class="flex justify-between text-white">
        <div class="flex-star mb-4">
          <span class="text-lg"> Trip Assignment </span>
          <span class="font-base" v-if="trip.flights.length">
            {{
              ` - ${
                trip.flights.length
              } segment(s),  Total Duty: ${formatElapsedTime(
                trip.duration
              )} hours`
            }}
          </span>
        </div>

        <button @click="trip = null" class="btn-icon">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>

      <div>
        <div v-if="trip.flights.length" class="flex mb-3">
          <button
            class="ml-1 btn-large bg-blue-400 text-white"
            @click="handleRemoveFlightFromTrip(flight)"
            v-for="flight in trip.flights"
            :key="flight.flightId"
          >
            {{ `${flight.originIata} - ${flight.destinationIata}` }}
          </button>
        </div>
        <div v-else class="mb-3">
          <span class="font-regular text-white"
            >Select flights from the board</span
          >
        </div>
      </div>

      <div class="flex justify-between" v-if="trip.flights.length">
        <button @click="handleResetTrip" class="btn-text text-blue underline">
          Reset
        </button>
        <button
          @click="handleGetEmployeeForTrip"
          class="btn bg-blue-500 text-white"
        >
          Assign to Employee
        </button>
      </div>
      <div v-else></div>
    </div>

    <!-- STATION ASSIGNMENT BUILDER-->
    <div
      v-if="newStationAssignment"
      class="trip-frame flex flex-col justify-between min-h-[150px] w-full bg-amber-600 px-2 py-1"
    >
      <div class="flex justify-between items-center">
        <div
          @click="newStationAssignment = null"
          class="cursor-pointer text-xl text-white mb-3"
        >
          {{
            `Flights ${flightBoardAirportFilter.match}  ${flightBoardAirportFilter.iataCode}`
          }}
        </div>

        <button @click="handleCancelNewStationAssignment" class="btn-icon">
          <i class="fa-solid fa-xmark text-white"></i>
        </button>
      </div>

      <div>
        <div v-if="newStationAssignment.flights.length">
          <div class="flex">
            <button
              @click="removeFlightFromNewStationAssignment(flight)"
              class="btn-large bg-yellow-700 text-white ml-1"
              v-for="flight in newStationAssignment.flights"
              :key="flight.flightId"
            >
              {{ `${flight.originIata} - ${flight.destinationIata}` }}
            </button>
          </div>
        </div>
        <div v-else class="mb-3">
          <span class="font-regular text-white"
            >Select flights from the board</span
          >
        </div>
      </div>

      <div
        class="flex justify-between"
        v-if="newStationAssignment.flights.length"
      >
        <button
          v-if="newStationAssignment.flights.length"
          @click="handleResetNewStationAssignment"
          class="mr-2 btn-text text-blue underline"
        >
          Reset
        </button>

        <div class="flex justify-end">
          <button
            @click="handleAssignStationToCompany()"
            class="ml-1 btn bg-blue-900 text-white"
          >
            Assign to Company
          </button>

          <button
            @click="handleGetEmployeeForStationAssignment"
            class="ml-1 btn bg-blue-500 text-white"
          >
            Assign to Employee
          </button>
        </div>
      </div>

      <div v-else></div>
    </div>
    <!-- #endregion -->

    <!-- #region FLIGHT DETAIL -->
    <r-modal v-if="showFlightDialog">
      <div v-if="flight" class="p-5 bg-green-100 text-sm">
        <!-- FLIGHT NUMBER -->
        <div class="flex justify-between text-xl">
          <div>
            {{ flight.flightNumber }} - {{ flight.aircraft.registration }} -
            {{ flight.aircraft.cargoOnly ? "CARGO" : "PAX"
            }}<i v-if="flight.isVip" class="fa-solid fa-star"></i>
          </div>
          <button @click="hideFlightDialog(false)" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <!-- CUSTOMER / STATUS -->
        <div class="flex justify-between text-2xl mb-3">
          <div>{{ flight.customerName }}</div>
          <div>Status: {{ flight.status }}</div>
        </div>

        <!-- ORIGIN / PAYLOAD / DESTINATION -->

        <div class="flex justify-between border-b border-gray-500 pb-2 mb-2">
          <div @click="handleGetPreviousFlight">
            <i class="fa-solid fa-left"></i>
          </div>

          <!-- Origin -->
          <div class="mx-auto">
            <div
              @click="handleShowAirportInformationDialog(flight.originIata)"
              class="text-center text-3xl font-bold cursor-pointer"
            >
              {{ flight.originIata }}
            </div>
            <div class="text-center">
              {{ formatDate(flight.effectiveTimeOut) }}
            </div>
            <div class="text-center text-3xl font-bold grey--text">
              {{ formatTime(flight.effectiveTimeOut) }} Z
            </div>
          </div>

          <!-- Payload -->
          <div
            class="flex flex-col justify-center min-w-48 mx-auto cursor-pointer"
          >
            <!-- Passenger -->
            <div v-if="!flight.aircraft.cargoOnly">
              <div v-if="flight.payload">
                <div @click="handleOpenPayloadCalculator">
                  <div class="flex justify-between items-center">
                    <div>Passengers:</div>
                    <div class="ml-3 text-2xl font-bold">
                      {{ flight.payload.numberOfPassengers }}
                    </div>
                  </div>
                  <div class="flex justify-between items-center">
                    <div>Payload:</div>
                    <div class="ml-3 text-2xl font-bold">
                      {{
                        `${formatWeight(
                          flight.payload.finalEstimatedPayload
                        )} ${flight.aircraft.weightUnit}`
                      }}
                    </div>
                  </div>
                  <div class="flex justify-between items-center">
                    <div>{{ `SWA (${flight.payload.swaCategory.name}):` }}</div>
                    <div class="ml-3 text-2xl font-bold">
                      {{
                        `${formatWeight(flight.payload.swaCategory.value)} ${
                          flight.aircraft.weightUnit
                        }`
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div @click="handleOpenPayloadCalculator">
                  <div class="flex justify-between items-center">
                    <div>Passengers:</div>
                    <div class="text-3xl font-bold">{{ 0 }}</div>
                  </div>
                  <div class="flex justify-between items-center">
                    <div>Payload:</div>
                    <div class="text-3xl font-bold">
                      {{ `${formatWeight(0)} ${flight.aircraft.weightUnit}` }}
                    </div>
                  </div>
                  <div class="flex justify-between items-center">
                    <div>SWA:</div>
                    <div class="text-3xl font-bold">
                      {{ `${formatWeight(0)} ${flight.aircraft.weightUnit}` }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Cargo -->
            <div v-else>
              <div v-if="flight.payload">
                <div @click="handleOpenCargoPayloadCalculator">
                  <div class="flex justify-between items-center">
                    <div>Payload:</div>
                    <div class="text-3xl font-bold">
                      {{
                        `${formatWeight(flight.payload.cargo)} ${
                          flight.aircraft.weightUnit
                        }`
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div @click="handleOpenCargoPayloadCalculator">
                  <div class="flex justify-between items-center">
                    <div>Payload:</div>
                    <div class="text-3xl font-bold">
                      {{ `${formatWeight(0)} ${flight.aircraft.weightUnit}` }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Destination -->
          <div class="mx-auto">
            <div
              @click="
                handleShowAirportInformationDialog(flight.destinationIata)
              "
              class="text-center text-3xl font-bold cursor-pointer"
            >
              {{ flight.destinationIata }}
            </div>
            <div class="text-center">
              {{ formatDate(flight.effectiveTimeIn) }}
            </div>
            <div class="text-center text-3xl font-bold grey--text">
              {{ formatTime(flight.effectiveTimeIn) }} Z
            </div>
          </div>

          <div @click="handleGetNextFlight">
            <i class="fa-solid fa-right"></i>
          </div>
        </div>

        <!-- #region CREWMEMBERS -->
        <div class="mb-1">
          <div class="flex">
            <div class="text-base font-bold">Flight Crew</div>

            <div v-if="!isCompanyAssignment">
              <button
                v-if="canCreateFlightAssignments"
                class="btn-icon ml-3"
                @click="handleGetEmployeeForFlight"
              >
                <i class="fa-solid fa-user-plus text-blue-500"></i>
              </button>

              <button
                v-if="
                  canCreateFlightAssignments &&
                  user.securityGroup.companies.length
                "
                class="btn-icon"
                @click="handleInitCompanyAssignment"
              >
                <i class="fa-solid fa-building text-yellow-900"></i>
              </button>
            </div>
          </div>

          <div
            v-if="isCompanyAssignment"
            class="p-3 bg-white border border-gray-500 rounded-md my-1"
          >
            <div class="flex">
              <select
                class="block w-48 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                v-model="selectedAssignedToCompany"
              >
                <option
                  v-for="option in user.securityGroup.companies"
                  :key="option"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>

              <select
                class="ml-2 block w-48 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                v-model="selectedFlightRole"
              >
                <option
                  v-for="option in authorizedFlightRoles"
                  :key="option"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>
            </div>

            <div class="mt-2">
              <div class="ml-1 text-sm text-gray-500">Remarks</div>
              <div>
                <textarea
                  v-model="flightAssignmentRemarks"
                  rows="4"
                  name="remarks"
                  id="remarks"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div class="flex justify-end mt-2">
              <button
                @click="isCompanyAssignment = false"
                class="btn bg-gray-500 text-white"
              >
                Cancel
              </button>

              <button
                @click="handleAssignFlightToCompany"
                class="ml-1 btn bg-blue-900 text-white"
              >
                Assign to Company
              </button>
            </div>
          </div>

          <!-- FLIGHT CREWMEMBERS -->
          <div class="border-b border-gray-500">
            <div v-if="flight.crewmembers">
              <div
                class="mb-1"
                v-for="crewmember in flight.crewmembers"
                :key="crewmember._id"
              >
                <div v-if="crewmember.employee" class="flex justify-between">
                  <div class="flex">
                    <div>{{ crewmember.role }}:</div>
                    <div>
                      <button
                        @click="
                          handleShowFlightAssignmentInformationDialog(
                            crewmember
                          )
                        "
                        class="ml-1 btn-text text-sm"
                        :class="{ hasWarnings: crewmember.warnings.length }"
                      >
                        {{ crewmember.employee.surname }},
                        {{ crewmember.employee.givenName }}
                      </button>
                    </div>
                  </div>
                  <div class="flex justify-end">
                    <button icon @click="handleSendNotification(crewmember)">
                      <i class="fa-solid fa-envelope"></i>
                    </button>
                  </div>
                </div>
                <div v-else class="flex justify-between">
                  <div class="flex justify-center">
                    <div>{{ crewmember.role }}:</div>
                    <div>
                      <button
                        @click="
                          handleShowFlightAssignmentInformationDialog(
                            crewmember
                          )
                        "
                        class="btn-text text-red-500 ml-1 font-bold"
                      >
                        {{ crewmember.assignedToCompany }}
                      </button>
                    </div>
                  </div>
                  <div class="flex justify-end">
                    <button
                      v-if="canCreateFlightAssignments"
                      icon
                      @click="handleCoverFlightAssignment(crewmember)"
                    >
                      <i class="fa-solid fa-user text-green-500"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <span class="text-red-500">No Crewmembers Assigned</span>
            </div>
          </div>
        </div>
        <!-- #endregion -->

        <!-- #region GROUND CREWMEMBERS -->

        <div class="text-base font-bold">Ground Crew</div>

        <div class="flex items-center my-1">
          <div
            class="text-[12px] rounded-md bg-gray-500 text-white px-3 cursor-pointer"
            @click="
              initSingleFlightStationAssignment({
                flight,
                station: flight.originIata,
              })
            "
          >
            {{ flight.originIata }}
          </div>

          <div class="mx-1">-</div>

          <div
            class="text-[12px] rounded-md bg-gray-500 text-white px-3 cursor-pointer"
            @click="
              initSingleFlightStationAssignment({
                flight,
                station: flight.destinationIata,
              })
            "
          >
            {{ flight.destinationIata }}
          </div>
        </div>

        <div class="border-b border-gray-500 mb-1">
          <div v-if="flight.groundCrewmembers.length > 0">
            <div
              v-for="groundCrewmember in flight.groundCrewmembers"
              :key="groundCrewmember._id"
            >
              <div
                v-if="groundCrewmember.employee"
                class="flex justify-between mb-1"
              >
                <div class="flex">
                  <div>
                    {{
                      `${groundCrewmember.role} (${groundCrewmember.originIata})`
                    }}:
                  </div>
                  <div>
                    <button
                      @click="
                        handleShowStationAssignmentInformationDialog(
                          groundCrewmember
                        )
                      "
                      class="btn-text ml-1 font-bold"
                    >
                      {{ groundCrewmember.employee.surname }},
                      {{ groundCrewmember.employee.givenName }}
                    </button>
                  </div>
                </div>
                <div class="flex justify-end">
                  <button
                    icon
                    @click="handleSendNotification(groundCrewmember)"
                  >
                    <i class="fa-solid fa-envelope"></i>
                  </button>
                </div>
              </div>
              <div v-else class="flex justify-between">
                <div class="flex">
                  <div>
                    {{
                      `${groundCrewmember.role} (${groundCrewmember.originIata})`
                    }}:
                  </div>
                  <div>
                    <button
                      @click="
                        handleShowStationAssignmentInformationDialog(
                          groundCrewmember
                        )
                      "
                      class="btn-text text-red-500 ml-1 font-bold"
                    >
                      {{ groundCrewmember.assignedToCompany }}
                    </button>
                  </div>
                </div>
                <div class="flex justify-end">
                  <button
                    v-if="canCreateGroundAssignments"
                    icon
                    @click="handleCoverStationAssignment(groundCrewmember)"
                  >
                    <i class="fa-solid fa-user text-green-700"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <span class="text-red-500">No Ground Crewmembers Assigned</span>
          </div>
        </div>

        <!-- #endregion -->

        <!-- AIMS Crew -->
        <div class="text-blue-500 mt-1">AIMS Crew</div>
        <div class="r-frame-vertical-scroll border-b border-gray-500">
          <div
            v-if="flight.aimscrewmembers && flight.aimscrewmembers.length > 0"
          >
            <div v-for="c in flight.aimscrewmembers" :key="c._id">
              <span
                >{{ c.positionCode.length > 0 ? c.positionCode : "JS" }}
              </span>
              <span class="ml-1">
                {{ c.name }}
              </span>
            </div>
          </div>

          <!-- AIMS Jumpseaters-->
          <div v-if="flight.jumpseaters && flight.jumpseaters.length > 0">
            <div v-for="j in flight.jumpseaters" :key="j._id">
              <span>{{ j.departmentCode ? j.departmentCode : "JS" }}: </span>
              <span class="ml-1">
                {{ j.name }}
              </span>
            </div>
          </div>
        </div>

        <div class="mt-1">Remarks</div>

        <div
          v-if="!editRemarks"
          class="border border-gray-500 rounded-md px-2 cursor-pointer"
          @click="handleEnableEditFlightRemarks"
        >
          <p
            v-if="flight.remarks"
            class="user-select-text text-sm text-blue-600 mt-2"
          >
            {{ flight.remarks }}
          </p>
          <p v-else></p>
        </div>
        <div v-else>
          <div>
            <div>
              <textarea
                v-model="flightRemarks"
                rows="4"
                name="remarks"
                id="remarks"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div class="flex justify-end mt-1">
            <button
              class="btn bg-gray-500 text-white"
              @click="handleCancelEditFlightRemarks"
            >
              Cancel
            </button>
            <button
              class="ml-1 btn bg-green-500 text-white"
              @click="handleSaveFlightRemarks"
            >
              Save Remarks
            </button>
          </div>
        </div>

        <div class="flex justify-between mt-1">
          <div class="text-[10px] text-gray-500 selectable-text">
            {{ flight._id }}
          </div>
          <div class="font-base text-blue-500 selectable-text">
            {{ flight.flightId }}
          </div>
        </div>
      </div>
    </r-modal>
    <!-- #endregion -->

    <!-- #region BOARD -->
    <div
      class="board"
      ref="board"
      :style="{
        top: `${boardY}px`,
      }"
    >
      <!-- LEFT PANEL -->
      <div v-if="boardStyle === 'Board'" class="left-panel">
        <div class="left-panel-header bg-gray-500-l4">
          <div
            class="flex justify-center px-2 text-xl font-bold cursor-pointer"
            @click="handleOpenAircraftFilter"
          >
            Aircraft
          </div>
        </div>
        <div v-if="page">
          <div
            @click="handleShowAircraftInformation(item.aircraft)"
            class="left-panel-row bg-gray-500-l4 cursor-pointer"
            v-for="item in page.items"
            :key="item.aircraft.registration"
            :style="{ height: `${rowHeight}px` }"
          >
            <div class="text-lg text-bold">
              {{ item.aircraft.registration }}
            </div>
            <div :style="getAircraftTypeStyle(item.aircraft)">
              {{ item.aircraft.typeDescription }}
            </div>
          </div>
        </div>
      </div>
      <!-- RIGHT PANEL -->
      <div v-if="boardStyle === 'Board'" class="right-panel-container">
        <div v-if="page">
          <div class="right-panel">
            <!-- Flight Tooltip -->
            <div
              class="flight-tooltip text-[12px]"
              :style="tooltipStyle"
              v-if="tooltip"
            >
              <div class="border-b border-gray-500 pb-1 mb-1 text-base">
                {{ tooltip.customerName }}
              </div>

              <div>
                {{
                  `${tooltip.flightNumber} . ${tooltip.aircraft.registration} . ${tooltip.originIata} - ${tooltip.destinationIata} `
                }}
              </div>

              <div class="border-b border-gray-500 pb-1 mb-1">
                {{
                  `${formatDateTime(tooltip.effectiveTimeOut)} . ${formatTime(
                    tooltip.effectiveTimeIn
                  )}`
                }}
              </div>

              <div v-if="tooltip.crewmembers && tooltip.crewmembers.length > 0">
                <div
                  class="flex"
                  v-for="crewmember in tooltip.crewmembers"
                  :key="crewmember._id"
                >
                  <div>{{ crewmember.role }}:</div>
                  <div v-if="crewmember.employee" class="ml-1">
                    {{ crewmember.employee.surname }},
                    {{ crewmember.employee.givenName }}
                  </div>
                  <div v-else>
                    {{ crewmember.assignedToCompany }}
                  </div>
                </div>
              </div>
              <div v-else>No flight crewmembers assigned.</div>

              <div
                class="border-t border-gray-500 pb-1 mt-1"
                v-if="
                  tooltip.groundCrewmembers &&
                  tooltip.groundCrewmembers.length > 0
                "
              >
                <div
                  v-for="groundCrewmember in tooltip.groundCrewmembers"
                  :key="groundCrewmember._id"
                  class="flex"
                >
                  <div>
                    {{
                      `Ground (${groundCrewmember.originIata}): ${groundCrewmember.role} - `
                    }}
                  </div>
                  <div v-if="groundCrewmember.employee">
                    {{ groundCrewmember.employee.surname }},
                    {{ groundCrewmember.employee.givenName }}
                  </div>
                  <div v-else>{{ groundCrewmember.assignedToCompany }}</div>
                </div>
              </div>
            </div>
            <!-- Header -->
            <div
              class="right-panel-header bg-gray-500-l4 cursor-pointer"
              @click="handleOpenDateFilter(item)"
              v-for="(item, index) in dateFilter.days"
              :key="index"
              :style="{
                top: 0,
                left: `${(1440 / scale) * index}px`,
                width: `${dayWidth}px`,
              }"
            >
              {{ formatHeaderDate(item) }}
            </div>

            <!-- Day Lines -->
            <div
              class="day-line"
              v-for="(day, index) in dateFilter.days"
              :key="day"
              :style="{
                left: `${(1440 / scale) * index}px`,
                height: `${availableBoardHeight + 50}px`,
              }"
            ></div>

            <!-- Hour Boxes -->
            <div
              class="hour-box text-[10px]"
              :class="{ hidden: marker.value === '00' || scale > 4 }"
              v-for="(marker, index) in dateFilter.hourBoxes"
              :key="marker.key"
              :style="{ left: `${(60 / scale) * index}px` }"
            >
              {{ marker.value }}
            </div>

            <!-- Hour Lines -->
            <div
              class="hour-line"
              :class="{ hidden: marker.value === '00' || scale > 6 }"
              v-for="(marker, index) in dateFilter.hourLines"
              :key="marker.key"
              :style="{
                left: `${(60 / scale) * index}px`,
                height: `${availableBoardHeight + 7}px`,
              }"
            ></div>

            <!-- Now Box -->
            <div
              class="now-box"
              v-if="showCurrentTime"
              :style="{
                left: `${nowLineLeft}px`,
              }"
            >
              {{ formatTime(new Date()) }}
            </div>

            <!-- Now Line -->
            <div
              class="now-line"
              v-if="showCurrentTime"
              :style="{
                left: `${nowLineLeft}px`,
                height: `${availableBoardHeight + 7}px`,
              }"
            ></div>

            <!-- Flights -->
            <div
              class="right-panel-row"
              v-for="(item, index) in page.items"
              :key="item.aircraft.registration"
              :style="{
                top: `${index * rowHeight + 50}px`,
                width: `${boardWidth}px`,
                height: `${rowHeight}px`,
              }"
            >
              <!-- Flights -->
              <div
                v-for="flight in item.flights"
                @click="handleFlightLabelClicked(flight)"
                @mouseover="handleShowFlightTooltip(flight, index)"
                @mouseleave="handleHideFlightTooltip"
                :key="flight._id"
                class="board-label-frame"
                :style="{
                  width: `${flight.effectiveBlockTime / scale}px`,
                  left: `${getLocationLeft(
                    flight.effectiveTimeOut,
                    dateFilter.days[0],
                    scale
                  )}px`,
                }"
              >
                <FlightLabel :flight="flight" :aircraft="item.aircraft" />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="right-panel">
            <div
              class="right-panel-header bg-gray-500-l4 cursor-pointer"
              @click="handleOpenDateFilter(item)"
              v-for="(item, index) in dateFilter.days"
              :key="index"
              :style="{
                top: 0,
                left: `${(1440 / scale) * index}px`,
                width: `${dayWidth}px`,
              }"
            >
              {{ formatHeaderDate(item) }}
            </div>

            <!-- Day Lines -->
            <div
              class="day-line"
              v-for="(day, index) in dateFilter.days"
              :key="day"
              :style="{
                left: `${(1440 / scale) * index}px`,
                height: `${availableBoardHeight + 50}px`,
              }"
            ></div>

            <!-- Hour Boxes -->
            <div
              class="hour-box text-[10px]"
              :class="{ hidden: marker.value === '00' || scale > 4 }"
              v-for="(marker, index) in dateFilter.hourBoxes"
              :key="marker.key"
              :style="{ left: `${(60 / scale) * index}px` }"
            >
              {{ marker.value }}
            </div>

            <!-- Hour Lines -->
            <div
              class="hour-line"
              :class="{ hidden: marker.value === '00' || scale > 6 }"
              v-for="(marker, index) in dateFilter.hourLines"
              :key="marker.key"
              :style="{
                left: `${(60 / scale) * index}px`,
                height: `${availableBoardHeight + 7}px`,
              }"
            ></div>

            <!-- Now Box -->
            <div
              class="now-box"
              v-if="showCurrentTime"
              :style="{
                left: `${nowLineLeft}px`,
              }"
            >
              {{ formatTime(new Date()) }}
            </div>

            <!-- Now Line -->
            <div
              class="now-line"
              v-if="showCurrentTime"
              :style="{
                left: `${nowLineLeft}px`,
                height: `${availableBoardHeight + 7}px`,
              }"
            ></div>
          </div>
        </div>
      </div>
      <!-- FLIGHT LIST -->
      <div v-if="boardStyle === 'List' && page">
        <div class="flex justify-end">
          <div class="flex justify-between items-center">
            <div class="flex h-6 items-center mb-2">
              <input
                v-model="printGroundCrewmembers"
                id="printGroundCrewmembers"
                aria-describedby="filter-description"
                name="printGroundCrewmembers"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
              />

              <div class="ml-3 text-base leading-6">
                <label
                  for="printGroundCrewmembers"
                  class="font-medium text-gray-900"
                  >Include Ground Crew</label
                >
                {{ " " }}
              </div>
            </div>
            <button @click="handlePrintFlightBoardList" class="btn-icon mx-2">
              <i class="fa-solid fa-print text-blue-500"></i>
            </button>
          </div>
        </div>

        <div class="max-h-full relative overflow-x-auto shadow-md">
          <table
            class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortAircraftFlights('registration')"
                >
                  Registration
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortAircraftFlights('from')"
                >
                  From
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortAircraftFlights('to')"
                >
                  To
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortAircraftFlights('status')"
                >
                  Status
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortAircraftFlights('start')"
                >
                  OUT
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortAircraftFlights('end')"
                >
                  IN
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="flight in aircraftFlights"
                :key="flight._id"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                @click="getFlightFromList(flight)"
              >
                <td
                  scope="row"
                  class="px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ flight.aircraft.registration }}
                </td>

                <td class="px-6 py-1">
                  {{ flight.originIata }}
                </td>

                <td class="px-6 py-1">
                  {{ flight.destinationIata }}
                </td>

                <td class="px-6 py-1">
                  {{ flight.status }}
                </td>

                <td class="px-6 py-1">
                  {{ formatDateTime(flight.effectiveTimeOut) }}
                </td>

                <td class="px-6 py-1">
                  {{ formatDateTime(flight.effectiveTimeIn) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- #endregion -->
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import FlightLabel from "../components/FlightLabel.vue";

import domtoimage from "dom-to-image";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import SpecialAirports from "../components/SpecialAirports.vue";
import FlightAssignmentInformation from "../components/FlightAssignmentInformation.vue";
import StationAssignmentInformation from "../components/StationAssignmentInformation.vue";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "flightBoard",
  components: {
    FlightAssignmentInformation,
    StationAssignmentInformation,
    FlightLabel,
    SpecialAirports,
    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
  },
  data() {
    return {
      //-----------------------------------------Auto-Logout
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      logoutTimer: null,
      showLogoutWarning: false,
      autologoutIn: 60,

      loading: false,

      tooltip: null,
      tooltipStyle: {
        display: "none",
      },

      aircraftFlights: [],
      myAircraft: null,

      //------------------------------------------Sorting
      sortWarningsType: "",
      sortWarningsMessage: "",

      sortAircraftFlightsRegistration: "",
      sortAircraftFlightsFrom: "",
      sortAircraftFlightsTo: "",
      sortAircraftFlightsStatus: "",
      sortAircraftFlightsStart: "",
      sortAircraftFlightsEnd: "",

      sortAircraftRegistration: "",
      sortAircraftType: "",
      sortAircraftCargo: "",

      //-----------------------------------Paginate
      availableBoardHeight: 0,
      aircraftSchedule: null,
      page: null,
      itemsPerPage: 0,
      printGroundCrewmembers: true,
      pages: [],
      rowHeight: 60,
      disableNextPageButton: true,
      disablePreviousPageButton: true,

      boardStyle: "Board",
      timer: null,

      scale: 3,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      //----------------------------------------Trip Assignment
      trip: null,
      maximumTripSegments: 10,
      maximumTripDuration: 10068, // 10,068 minutes = 168 hours = 7 days
      maximumGroundAssignments: 20,
      getDataTimer: null,

      //---------------------------------------Calculators
      showWeightConverter: false,
      showReverseCalculator: false,
      reverseCalculatorPayload: 0,
      showUnitConverterDialog: false,
      weight: 0,
      units: "Kgs",
      convertFromOptions: ["Kgs", "Lbs"],
      rounded: true,

      //--------------------------------------Airport Info
      showAirportInformationDialog: false,
      airport: null,

      //----------------------------------Filters and Settings

      filterByAircraft: false,
      showAircraftFilterDialog: false,
      selectedAircraft: [],

      showFiltersDialog: false,

      iataMatchOptions: ["Departing or Arriving", "Departing", "Arriving"],

      filterByAirport: false,
      selectedIataCode: "",
      selectedIataMatch: "Departing or Arriving",

      filterByAircraftDeck: false,
      selectedAircraftDeck: null,
      aircraftDeckOptions: ["Cargo Aircraft", "Passenger Aircraft"],

      showDateFilterDialog: false,
      startDate: null,
      numberOfDays: 7,
      numberOfDaysOptions: [3, 5, 7, 14, 21, 31],

      crewStatusOptions: [
        "Complete",
        "Empty",
        "Employee Assigned",
        "Needs Employee",
        "Unable to Cover",
      ],

      //---------------------------------------Warnings
      warnings: [],
      filteredWarnings: [],
      showWarningDialog: false,

      //--------------------------------------Payload
      numberOfPassengers: 0,
      cargo: 0,
      showCargoPayloadDialog: false,
      cargoPayloadError: null,
      passengerPayloadError: null,

      estimatedWeightPerPassenger: 0,
      swaCategory: { name: "", value: 0 },
      showPayloadDialog: false,

      //-------------------------------------Aircraft Info
      showAircraftDialog: false,

      //-------------------------------------Flight Assignments
      flightAssignment: null,
      showFlightDialog: false,
      showStartCalendar: false,
      showEndCalendar: false,

      selectedFlight: null,
      flightSearch: "",

      editRemarks: false,
      flightRemarks: "",

      showSpecialAirportsDialog: false,

      crewmember: null,
      groundCrewmember: null,

      showFlightAssignmentInformationDialog: false,
      showGroundAssignmentInformationDialog: false,

      availableBufferWeights: [5000, 8000],
      selectedBufferWeight: 5000,

      selectedWarningFilter: "All Warnings",
      warningFilterOptions: [
        "All Warnings",
        "AIMS Listing",
        "Loadmaster Missing (Departure or Arrival)",
        "Loadmaster Missing (Departure)",
        "Loadmaster Missing (Arrival)",
        "Open Assignment",
        "PSR Missing",
      ],

      selectedAssignedToCompany: null,
      isCompanyAssignment: false,
      flightRoles: [],
      selectedFlightRole: null,
      flightAssignmentRemarks: null,

      //---------------------------------Ground Assignment Information
      groundAssignment: null,

      //--------------------------------Station Assignment Information
      stationAssignment: null,
      showStationAssignmentInformationDialog: false,

      //----------------------------------New Station Assignment
      showNewStationAssignmentDialog: false,
      newStationAssignment: null,
      selectedStationAssignmentRole: null,
      selectedStationAssignmentCompany: null,
      stationAssignmentRemarks: null,

      stationIataMatchOptions: [
        "Departing or Arriving",
        "Departing",
        "Arriving",
      ],
      maximumStationAssignmentAssignments: 20,
      maximumStationAssignmentDuration: 2880, // 2,880 minutes = 48 hours = 2 days
    };
  },
  mixins: [mixin],
  created() {
    this.getData();
  },

  mounted() {
    this.$nextTick(() => {
      const board = this.$refs.board;

      this.availableBoardHeight = board.clientHeight - 120;

      this.itemsPerPage = Math.floor(
        this.availableBoardHeight / this.rowHeight
      );
    });

    window.addEventListener("resize", this.onResize, { passive: true });

    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimers);
    });

    this.setTimers();
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  destroyed() {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimers);
    });

    this.resetTimers();
  },

  computed: {
    showCurrentTime() {
      const now = new Date().getTime();

      return (
        now > new Date(this.dateFilter.start).getTime() &&
        now < new Date(this.dateFilter.end).getTime()
      );
    },

    //-------------------------------Passenger Aircraft payload
    isValidNumberOfPassengers() {
      const isValid =
        !isNaN(this.numberOfPassengers) &&
        !isNaN(parseFloat(this.numberOfPassengers));

      if (
        isValid &&
        parseInt(this.numberOfPassengers) > 0 &&
        parseInt(this.numberOfPassengers) <= 400
      ) {
        return true;
      } else {
        return false;
      }
    },

    isValidEstimatedWeightPerPassenger() {
      const isValid =
        !isNaN(this.estimatedWeightPerPassenger) &&
        !isNaN(parseFloat(this.estimatedWeightPerPassenger));

      if (
        isValid &&
        parseInt(this.estimatedWeightPerPassenger) > 0 &&
        parseInt(this.estimatedWeightPerPassenger) <= 500
      ) {
        return true;
      } else {
        return false;
      }
    },

    isValidBufferWeight() {
      const isValid =
        !isNaN(this.selectedBufferWeight) &&
        !isNaN(parseFloat(this.selectedBufferWeight));

      return isValid;
    },

    isValidReverseCalculatorPayload() {
      if (this.isValidBufferWeight) {
        const isValid = !isNaN(this.reverseCalculatorPayload);
        const bufferWeight = parseInt(this.selectedBufferWeight);

        return (
          isValid && parseInt(this.reverseCalculatorPayload) > bufferWeight
        );
      } else {
        return false;
      }
    },

    preliminaryWeight() {
      if (
        this.isValidNumberOfPassengers &&
        this.isValidEstimatedWeightPerPassenger
      ) {
        const weight =
          parseInt(this.numberOfPassengers) *
          parseInt(this.estimatedWeightPerPassenger);

        return weight;
      } else {
        return 0;
      }
    },

    finalEstimatedPayload() {
      if (this.isValidBufferWeight) {
        let weight;

        if (this.swaCategory.name === "Ferry") {
          weight = parseInt(this.selectedBufferWeight);
        } else {
          weight =
            parseInt(this.preliminaryWeight) +
            parseInt(this.selectedBufferWeight);
        }

        return weight;
      } else {
        return 0;
      }
    },

    isValidFinalEstimatedPayload() {
      const bufferWeight = parseInt(this.selectedBufferWeight);
      const maximumPayload = parseInt(this.flight.aircraft.maximumPayload);

      const weight = this.finalEstimatedPayload;

      let isValid = false;

      if (this.swaCategory.name === "Ferry") {
        isValid = bufferWeight > 0;
      } else {
        isValid =
          weight > 0 && weight > bufferWeight && weight < maximumPayload;
      }

      return isValid;
    },

    aircraftDeckFilter() {
      return this.$store.getters.aircraftDeckFilter;
    },

    companyFilter() {
      return this.$store.getters.companyFilter;
    },

    flightBoardAirportFilter() {
      return this.$store.getters.flightBoardAirportFilter;
    },

    flightBoardAircraftFilter() {
      return this.$store.getters.flightBoardAircraftFilter;
    },

    pageNumber() {
      return this.$store.getters.flightBoardPageNumber;
    },

    nowLineLeft() {
      const x = this.getLocationLeft(
        new Date().toISOString(),
        this.dateFilter.start,
        this.scale
      );

      if (x > 0 || x < this.boardWidth) {
        return x;
      } else {
        return 0;
      }
    },

    boardWidth() {
      return this.dateFilter.days.length * (1440 / this.scale);
    },

    boardY() {
      if (this.trip || this.newStationAssignment) {
        return 200;
      } else {
        return 50;
      }
    },

    dayWidth() {
      return 1440 / this.scale;
    },

    authorizedFlightRoles() {
      return this.$store.getters.authorizedFlightRoles;
    },

    flight() {
      return this.$store.getters.flight;
    },

    specialAirports() {
      let airports = [];

      return airports;
    },

    board() {
      return this.$store.getters.board;
    },

    crewRequirementExemptions() {
      return this.$store.getters.crewRequirementExemptions;
    },

    aircraft() {
      return this.$store.getters.aircraft;
    },

    airports() {
      return this.$store.getters.airports;
    },

    convertedWeight() {
      let w = 0;

      if (this.units === "Lbs") {
        if (this.rounded) {
          w = Math.ceil(this.weight / 2.20462);
        } else {
          w = (this.weight / 2.20462).toFixed(5);
        }
        return `${this.formatWeight(w)} Kgs`;
      } else {
        if (this.rounded) {
          w = Math.ceil(this.weight * 2.20462);
        } else {
          w = (this.weight * 2.20462).toFixed(5);
        }
        return `${this.formatWeight(w)} Lbs`;
      }
    },

    dateFilter() {
      return this.$store.getters.dateFilter;
    },

    errors() {
      return this.$store.getters.errors;
    },
  },

  methods: {
    handleShowAircraftInformation(aircraft) {
      this.myAircraft = { ...aircraft };
      this.showAircraftDialog = true;
    },

    handleEditAircraft(aircraft) {
      this.showAircraftDialog = false;
      let selectedAircraft = { ...aircraft };

      if (selectedAircraft.cargoOnly) {
        selectedAircraft.deck = "Cargo";
      } else {
        selectedAircraft.deck = "Passengers";
      }

      if (selectedAircraft.cabinConfig) {
        // Cabin Configuration
        let cabin;

        cabin = selectedAircraft.cabinConfig[0];

        if (cabin) {
          selectedAircraft.cabinOne = {
            active: true,
            class: selectedAircraft.cabinConfig[0].class,
            seats: selectedAircraft.cabinConfig[0].seats,
          };
        } else {
          selectedAircraft.cabinOne = {
            active: false,
            class: "A",
            seats: 100,
          };
        }

        cabin = selectedAircraft.cabinConfig[1];

        if (cabin) {
          selectedAircraft.cabinTwo = {
            active: true,
            class: selectedAircraft.cabinConfig[1].class,
            seats: selectedAircraft.cabinConfig[1].seats,
          };
        } else {
          selectedAircraft.cabinTwo = {
            active: false,
            class: "B",
            seats: 100,
          };
        }

        cabin = selectedAircraft.cabinConfig[2];

        if (cabin) {
          selectedAircraft.cabinThree = {
            active: true,
            class: selectedAircraft.cabinConfig[2].class,
            seats: selectedAircraft.cabinConfig[2].seats,
          };
        } else {
          selectedAircraft.cabinThree = {
            active: false,
            class: "C",
            seats: 100,
          };
        }
      }

      if (selectedAircraft.swaCategories) {
        // SWA Categories
        let category;

        category = selectedAircraft.swaCategories[0];

        if (category) {
          selectedAircraft.swaCategoryOne = {
            active: true,
            name: selectedAircraft.swaCategories[0].name,
            value: selectedAircraft.swaCategories[0].value,
          };
        } else {
          selectedAircraft.swaCategoryOne = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[1];

        if (category) {
          selectedAircraft.swaCategoryTwo = {
            active: true,
            name: selectedAircraft.swaCategories[1].name,
            value: selectedAircraft.swaCategories[1].value,
          };
        } else {
          selectedAircraft.swaCategoryTwo = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[2];

        if (category) {
          selectedAircraft.swaCategoryThree = {
            active: true,
            name: selectedAircraft.swaCategories[2].name,
            value: selectedAircraft.swaCategories[2].value,
          };
        } else {
          selectedAircraft.swaCategoryThree = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[3];

        if (category) {
          selectedAircraft.swaCategoryFour = {
            active: true,
            name: selectedAircraft.swaCategories[3].name,
            value: selectedAircraft.swaCategories[3].value,
          };
        } else {
          selectedAircraft.swaCategoryFour = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[4];

        if (category) {
          selectedAircraft.swaCategoryFive = {
            active: true,
            name: selectedAircraft.swaCategories[4].name,
            value: selectedAircraft.swaCategories[4].value,
          };
        } else {
          selectedAircraft.swaCategoryFive = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[5];

        if (category) {
          selectedAircraft.swaCategorySix = {
            active: true,
            name: selectedAircraft.swaCategories[5].name,
            value: selectedAircraft.swaCategories[5].value,
          };
        } else {
          selectedAircraft.swaCategorySix = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[6];

        if (category) {
          selectedAircraft.swaCategorySeven = {
            active: true,
            name: selectedAircraft.swaCategories[6].name,
            value: selectedAircraft.swaCategories[6].value,
          };
        } else {
          selectedAircraft.swaCategorySeven = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[7];

        if (category) {
          selectedAircraft.swaCategoryEight = {
            active: true,
            name: selectedAircraft.swaCategories[7].name,
            value: selectedAircraft.swaCategories[7].value,
          };
        } else {
          selectedAircraft.swaCategoryEight = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[8];

        if (category) {
          selectedAircraft.swaCategoryNine = {
            active: true,
            name: selectedAircraft.swaCategories[8].name,
            value: selectedAircraft.swaCategories[8].value,
          };
        } else {
          selectedAircraft.swaCategoryNine = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[9];

        if (category) {
          selectedAircraft.swaCategoryTen = {
            active: true,
            name: selectedAircraft.swaCategories[9].name,
            value: selectedAircraft.swaCategories[9].value,
          };
        } else {
          selectedAircraft.swaCategoryTen = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[10];

        if (category) {
          selectedAircraft.swaCategoryEleven = {
            active: true,
            name: selectedAircraft.swaCategories[10].name,
            value: selectedAircraft.swaCategories[10].value,
          };
        } else {
          selectedAircraft.swaCategoryEleven = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[11];

        if (category) {
          selectedAircraft.swaCategoryTwelve = {
            active: true,
            name: selectedAircraft.swaCategories[11].name,
            value: selectedAircraft.swaCategories[11].value,
          };
        } else {
          selectedAircraft.swaCategoryTwelve = {
            active: false,
            class: "",
            value: 0,
          };
        }
      }

      this.$store.commit("updateSelectedAircraft", selectedAircraft);
      this.$router.push({ name: "selectedAircraft" });
    },

    setTimers() {
      this.showLogoutWarning = false;
      this.warningTimer = setTimeout(() => {
        this.snackbarColor = "red";
        this.snackbarText =
          "Are you still there? Monarch will auto-logout in 1 minute";
        this.showLogoutWarning = true;
      }, (this.autologoutIn - 1) * 60 * 1000); //59 Minutes
      this.logoutTimer = setTimeout(
        this.handleAutoLogout,
        this.autologoutIn * 60 * 1000
      ); //60 Minutes
    },

    resetTimers() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },

    async handleAutoLogout() {
      aad.logout();
      this.$store.commit("updateUser", null);
      this.$router.push({ name: "home" });
    },

    onResize() {
      const board = this.$refs.board;

      if (!board) return;

      //Notes
      //Reduce 100px for menus, 20px for scrolling bar,
      //150px for trip or station assignment panel if needed

      this.availableBoardHeight = board.clientHeight - 120;

      this.itemsPerPage = Math.floor(
        this.availableBoardHeight / this.rowHeight
      );

      this.paginate();
    },

    sortWarnings(field) {
      switch (field) {
        case "type":
          if (this.sortWarningsType === "ASC") {
            this.filteredWarnings.sort((a, b) => {
              if (a.type < b.type) {
                return -1;
              }
              if (b.type < a.type) {
                return 1;
              }
              return 0;
            });

            this.sortWarningsType = "DESC";
          } else {
            this.filteredWarnings.sort((a, b) => {
              if (a.type > b.type) {
                return -1;
              }
              if (b.type > a.type) {
                return 1;
              }
              return 0;
            });

            this.sortWarningsType = "ASC";
          }

          break;

        case "message":
          if (this.sortWarningsMessage === "ASC") {
            this.filteredWarnings.sort((a, b) => {
              if (a.message < b.message) {
                return -1;
              }
              if (b.message < a.message) {
                return 1;
              }
              return 0;
            });

            this.sortWarningsMessage = "DESC";
          } else {
            this.filteredWarnings.sort((a, b) => {
              if (a.message > b.message) {
                return -1;
              }
              if (b.message > a.message) {
                return 1;
              }
              return 0;
            });

            this.sortWarningsMessage = "ASC";
          }

          break;

        default:
          break;
      }
    },

    sortAircraftFlights(field) {
      switch (field) {
        case "registration":
          if (this.sortAircraftFlightsRegistration === "ASC") {
            this.aircraftFlights.sort((a, b) => {
              if (a.aircraft.registration < b.aircraft.registration) {
                return -1;
              }
              if (b.aircraft.registration < a.aircraft.registration) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftFlightsRegistration = "DESC";
          } else {
            this.aircraftFlights.sort((a, b) => {
              if (a.aircraft.registration > b.aircraft.registration) {
                return -1;
              }
              if (b.aircraft.registration > a.aircraft.registration) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftFlightsRegistration = "ASC";
          }

          break;

        case "from":
          if (this.sortAircraftFlightsFrom === "ASC") {
            this.aircraftFlights.sort((a, b) => {
              if (a.originIata < b.originIata) {
                return -1;
              }
              if (b.originIata < a.originIata) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftFlightsFrom = "DESC";
          } else {
            this.aircraftFlights.sort((a, b) => {
              if (a.originIata > b.originIata) {
                return -1;
              }
              if (b.originIata > a.originIata) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftFlightsFrom = "ASC";
          }
          break;

        case "to":
          if (this.sortAircraftFlightsTo === "ASC") {
            this.aircraftFlights.sort((a, b) => {
              if (a.destinationIata < b.destinationIata) {
                return -1;
              }
              if (b.destinationIata < a.destinationIata) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftFlightsTo = "DESC";
          } else {
            this.aircraftFlights.sort((a, b) => {
              if (a.destinationIata > b.destinationIata) {
                return -1;
              }
              if (b.destinationIata > a.destinationIata) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftFlightsTo = "ASC";
          }
          break;

        case "status":
          if (this.sortAircraftFlightsStatus === "ASC") {
            this.aircraftFlights.sort((a, b) => {
              if (a.status < b.status) {
                return -1;
              }
              if (b.status < a.status) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftFlightsStatus = "DESC";
          } else {
            this.aircraftFlights.sort((a, b) => {
              if (a.status > b.status) {
                return -1;
              }
              if (b.status > a.status) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftFlightsStatus = "ASC";
          }
          break;

        case "start":
          if (this.sortAircraftFlightsStart === "ASC") {
            this.aircraftFlights.sort((a, b) => {
              if (a.effectiveTimeOut < b.effectiveTimeOut) {
                return -1;
              }
              if (b.effectiveTimeOut < a.effectiveTimeOut) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftFlightsStart = "DESC";
          } else {
            this.aircraftFlights.sort((a, b) => {
              if (a.effectiveTimeOut > b.effectiveTimeOut) {
                return -1;
              }
              if (b.effectiveTimeOut > a.effectiveTimeOut) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftFlightsStart = "ASC";
          }
          break;

        case "end":
          if (this.sortAircraftFlightsEnd === "ASC") {
            this.aircraftFlights.sort((a, b) => {
              if (a.effectiveTimeIn < b.effectiveTimeIn) {
                return -1;
              }
              if (b.effectiveTimeIn < a.effectiveTimeIn) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftFlightsEnd = "DESC";
          } else {
            this.aircraftFlights.sort((a, b) => {
              if (a.effectiveTimeIn > b.effectiveTimeIn) {
                return -1;
              }
              if (b.effectiveTimeIn > a.effectiveTimeIn) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftFlightsEnd = "ASC";
          }
          break;

        default:
          break;
      }
    },

    sortAircraft(field) {
      switch (field) {
        case "registration":
          if (this.sortAircraftRegistration === "ASC") {
            this.aircraft.sort((a, b) => {
              if (a.registration < b.registration) {
                return -1;
              }
              if (b.registration < a.registration) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftRegistration = "DESC";
          } else {
            this.aircraft.sort((a, b) => {
              if (a.registration > b.registration) {
                return -1;
              }
              if (b.registration > a.registration) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftRegistration = "ASC";
          }

          break;

        case "type":
          if (this.sortAircraftType === "ASC") {
            this.aircraft.sort((a, b) => {
              if (a.type < b.type) {
                return -1;
              }
              if (b.type < a.type) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftType = "DESC";
          } else {
            this.aircraft.sort((a, b) => {
              if (a.type > b.type) {
                return -1;
              }
              if (b.type > a.type) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftType = "ASC";
          }

          break;

        case "cargo":
          if (this.sortAircraftCargo === "ASC") {
            this.aircraft.sort((a, b) => {
              if (a.cargoOnly < b.cargoOnly) {
                return -1;
              }
              if (b.cargoOnly < a.cargoOnly) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftCargo = "DESC";
          } else {
            this.aircraft.sort((a, b) => {
              if (a.cargoOnly > b.cargoOnly) {
                return -1;
              }
              if (b.cargoOnly > a.cargoOnly) {
                return 1;
              }
              return 0;
            });

            this.sortAircraftCargo = "ASC";
          }
          break;

        default:
          break;
      }
    },

    handleShowFlightTooltip(flight, index) {
      this.tooltip = { ...flight };

      this.tooltipStyle = {
        display: "block",
        top: `${index * this.rowHeight + 50 + 45}px`,
        left: `${
          this.getLocationLeft(
            flight.effectiveTimeOut,
            this.dateFilter.days[0],
            this.scale
          ) + 30
        }px`,
      };
    },

    handleHideFlightTooltip() {
      setTimeout(() => {
        this.tooltipStyle = {
          display: "none",
        };
      }, 150);
    },

    handleNavigateToCrewRequirements() {
      this.$router.push({ name: "crewRequirements" });
    },

    handleShowReverseCalculator() {
      if (this.isValidBufferWeight) {
        const bufferWeight = parseInt(this.selectedBufferWeight);
        this.reverseCalculatorPayload = bufferWeight + 2000;
      } else {
        this.reverseCalculatorPayload = 10000;
      }

      this.showReverseCalculator = true;
    },

    paginate() {
      if (!this.aircraftSchedule) {
        return;
      }

      this.pages = [];
      let _pages = [];

      let data = [...this.aircraftSchedule];

      if (data.length) {
        let chunk = Math.min(
          Math.floor(this.availableBoardHeight / this.rowHeight),
          data.length
        );

        //Show warning if not enough space to display
        if (chunk < 1) {
          this.snackbarColor = "red";
          this.snackbarText = "Not enough screen space to display data";
          this.snackbarVisible = true;
          return;
        }

        //Split data into pages
        for (let i = 0; i < data.length; i += chunk) {
          let tempArray;
          tempArray = data.slice(i, i + chunk);
          _pages.push({
            index: _pages.length,
            items: tempArray,
          });
        }

        this.pages = [..._pages];

        const i = this.pages.findIndex((page) => {
          return page.index === this.pageNumber;
        });

        if (i < 0) {
          this.$store.commit("updateFlightBoardPageNumber", 0);
        }

        this.page = this.pages[this.pageNumber];
      }
    },

    handlePageUp() {
      if (this.pageNumber > 0) {
        const val = this.pageNumber - 1;
        this.$store.commit("updateFlightBoardPageNumber", val);
        this.page = this.pages[this.pageNumber];
      }
    },

    handlePageDown() {
      if (this.pageNumber < this.pages.length - 1) {
        const val = this.pageNumber + 1;
        this.$store.commit("updateFlightBoardPageNumber", val);
        this.page = this.pages[this.pageNumber];
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },

    handleNavigateToHome() {
      this.$router.go({ name: "home" });
    },

    handleOpenFilters() {
      if (!this.selectedAircraftDeck) {
        this.selectedAircraftDeck = "Cargo Aircraft";
      }

      //------------------------------------------Airports Filter
      if (this.flightBoardAirportFilter.active) {
        this.filterByAirport = true;
        this.selectedIataCode = this.flightBoardAirportFilter.iataCode.slice();
      }

      //-----------------------------------------Aircraft Deck Filter
      if (this.aircraftDeckFilter.active) {
        this.filterByAircraftDeck = true;
        this.selectedAircraftDeck = this.aircraftDeckFilter.deck.slice();
      }

      this.showFiltersDialog = true;
    },
    handleSaveFilters() {
      //------------------------------------Aircraft Deck Filter
      let aircraftDeckFilter = {
        active: false,
        deck: "",
      };

      if (this.filterByAircraftDeck) {
        aircraftDeckFilter = {
          active: true,
          deck: this.selectedAircraftDeck.slice(),
        };
      }

      this.$store.commit("updateAircraftDeckFilter", aircraftDeckFilter);

      //------------------------------------Airport Filter
      let flightBoardAirportFilter = {
        active: false,
        match: "",
        iataCode: "",
      };

      if (this.filterByAirport) {
        if (this.selectedIataCode?.length === 3) {
          const i = this.airports.findIndex((item) => {
            return item.iata === this.selectedIataCode.toUpperCase();
          });

          if (i >= 0) {
            flightBoardAirportFilter = {
              active: true,
              match: this.selectedIataMatch.slice(),
              iataCode: this.selectedIataCode.slice().toUpperCase(),
            };
          } else {
            this.filterByAirport = false;
            this.selectedIataCode = null;
          }
        } else {
          this.filterByAirport = false;
        }
      }

      this.$store.commit(
        "updateFlightBoardAirportFilter",
        flightBoardAirportFilter
      );

      this.showFiltersDialog = false;

      this.getData();
    },

    handleOpenAircraftFilter() {
      if (this.flightBoardAircraftFilter.active) {
        this.filterByAircraft = true;
        this.selectedAircraft = [...this.flightBoardAircraftFilter.aircraft];
      } else {
        this.filterByAircraft = false;
        this.selectedAircraft = [];
      }

      this.showAircraftFilterDialog = true;
    },

    handleSaveAircraftFilter() {
      //------------------------------------Aircraft Deck Filter
      let aircraftFilter = {
        active: false,
        aircraft: [],
      };

      if (this.filterByAircraft) {
        aircraftFilter = {
          active: true,
          aircraft: [...this.selectedAircraft],
        };
      }

      this.$store.commit("updateFlightBoardAircraftFilter", aircraftFilter);

      this.showAircraftFilterDialog = false;

      this.getData();
    },

    async handleCoverStationAssignment(groundCrewmember) {
      try {
        this.loading = true;
        const token = await aad.getIdTokenSilent();
        const res = await api.post(
          "/flights/station-assignment",
          {
            flightIds: groundCrewmember.flightIds,
          },
          {
            headers: {
              Authorization: "Bearer " + token.idToken.rawIdToken,
            },
          }
        );

        this.loading = false;

        const flights = res.data.flights.sort(this.compareFlights);

        const assignmentData = {
          type: "Station Assignment",
          stationAssignment: { ...groundCrewmember, flights },
        };

        this.$store.commit("updateAuthorizedFlightRoles", [
          groundCrewmember.role,
        ]);
        this.$store.commit("updateAssignmentData", assignmentData);
        this.$router.push({ name: "assignmentBoard" });
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
        return;
      }
    },

    async handleSaveStationAssignment() {
      this.showNewStationAssignmentDialog = false;

      let flightIds = [];
      this.newStationAssignment.flights.forEach((flight) => {
        flightIds.push(flight.flightId);
      });

      let assignment = {
        type: "Station Assignment",
        employee_id: null,
        assignedToCompany: this.selectedStationAssignmentCompany,
        startTime: this.newStationAssignment.startTime,
        endTime: this.newStationAssignment.endTime,
        originIata: this.newStationAssignment.originIata,
        destinationIata: this.newStationAssignment.originIata,
        duration: this.newStationAssignment.duration,
        role: this.selectedStationAssignmentRole,
        flightIds,
        warnings: [],
        remarks: this.stationAssignmentRemarks,
      };

      this.loading = true;

      const res = await this.$store.dispatch(
        "saveStationAssignment",
        assignment
      );

      this.loading = false;
      this.newStationAssignment = null;

      if (res.status !== 200) {
        this.snackbarColor = "red";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.getData();
    },

    compareAircraftTypes(a, b) {
      if (a.type < b.type) {
        return -1;
      }
      if (b.type < a.type) {
        return 1;
      }
      return 0;
    },

    handleOpenDateFilter(item) {
      this.startDate = item.substring(0, 10);
      this.numberOfDays = this.dateFilter.days.length;

      this.showDateFilterDialog = true;
    },

    handleSaveDateFilter() {
      const filterStart = new Date(this.startDate).getTime();

      this.showDateFilterDialog = false;

      //Pass filterStart in miliseconds, numberOfDays as integer
      this.$store.commit("updateDateFilter", {
        filterStart,
        numberOfDays: this.numberOfDays,
      });

      this.getData();
    },

    handleToggleBoardStyle() {
      if (this.boardStyle === "Board") {
        let flights = [];

        if (this.aircraftSchedule) {
          this.aircraftSchedule.forEach((item) => {
            item.flights.forEach((flight) => {
              flights.push(flight);
            });
          });
        }

        this.aircraftFlights = [...flights];
        this.boardStyle = "List";
      } else {
        this.boardStyle = "Board";
      }
    },

    async handleSendNotification(crewmember) {
      this.loading = true;

      const flightAssignment = {
        type: "Flight",
        aircraftRegistration: this.flight.aircraft.registration,
        assignedBy: crewmember.assignedBy,
        createdAt: crewmember.createdAt,
        destinationIata: this.flight.destinationIata,
        employee: crewmember.employee,
        endTime: this.flight.effectiveTimeIn,
        flightNumber: this.flight.flightNumber,
        flight_id: this.flight._id,
        originIata: this.flight.originIata,
        remarks: crewmember.remarks,
        role: crewmember.role,
        startTime: this.flight.effectiveTimeOut,
        updatedAt: crewmember.updatedAt,
        warnings: crewmember.warnings,
        _id: crewmember._id,
      };

      const res = await this.$store.dispatch("sendAssignmentNotification", {
        assignment: flightAssignment,
        emailSubject: `${flightAssignment.role} - Flight Assignment`,
      });

      this.loading = false;

      if (res.status !== 200) {
        this.snackbarColor = "red";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }
    },

    handleGetEmployeeForFlight() {
      this.showFlightDialog = false;

      //---------------Update the authorized flight roles
      let authorizedFlightRoles;

      if (this.flight.aircraft.cargoOnly) {
        authorizedFlightRoles = [
          ...this.user.securityGroup.flightAssignmentCargoRoles,
        ];
      } else {
        authorizedFlightRoles = [
          ...this.user.securityGroup.flightAssignmentPassengerRoles,
        ];
      }

      if (!authorizedFlightRoles.length) {
        this.snackbarColor = "red";
        this.snackbarText = "Not authorized to assign any flight role";
        this.snackbarVisible = true;
        return;
      }

      const assignmentData = {
        type: "Flight Assignment",
        flight: { ...this.flight },
      };

      this.$store.commit("updateAuthorizedFlightRoles", authorizedFlightRoles);
      this.$store.commit("updateAssignmentData", assignmentData);
      this.$router.push({ name: "assignmentBoard" });
    },

    handleCoverFlightAssignment(crewmember) {
      this.showFlightDialog = false;

      const assignmentData = {
        type: "Flight Assignment",
        flight: { ...this.flight },
        crewmember: { ...crewmember },
      };

      this.$store.commit("updateAuthorizedFlightRoles", [crewmember.role]);
      this.$store.commit("updateAssignmentData", assignmentData);
      this.$router.push({ name: "assignmentBoard" });
    },

    handleInitCompanyAssignment() {
      //---------------Update the authorized flight roles
      let authorizedFlightRoles;

      if (this.flight.aircraft.cargoOnly) {
        authorizedFlightRoles = [
          ...this.user.securityGroup.flightAssignmentCargoRoles,
        ];
      } else {
        authorizedFlightRoles = [
          ...this.user.securityGroup.flightAssignmentPassengerRoles,
        ];
      }

      if (!authorizedFlightRoles.length) {
        this.snackbarColor = "red";
        this.snackbarText = "Not authorized to assign any flight role";
        this.snackbarVisible = true;
        return;
      }

      //No Jumseat on a Station Assignment
      const filteredRoles = authorizedFlightRoles.filter((item) => {
        return item !== "Jumpseat";
      });

      this.$store.commit("updateAuthorizedFlightRoles", filteredRoles);

      this.selectedAssignedToCompany = this.user.securityGroup.companies[0];
      this.selectedFlightRole = filteredRoles[0];

      if (this.flight.aircraft.cargoOnly) {
        this.flightRoles = [
          ...this.user.securityGroup.flightAssignmentCargoRoles,
        ];
      } else {
        this.flightRoles = [
          ...this.user.securityGroup.flightAssignmentPassengerRoles,
        ];
      }

      this.selectedFlightRole = this.flightRoles[0];

      this.isCompanyAssignment = true;
    },

    async handleAssignStationToCompany() {
      let assignments = [];
      let assignment;

      this.newStationAssignment.flights.forEach((flight) => {
        if (flight.originIata === this.flightBoardAirportFilter.iataCode) {
          assignment = {
            flight,
            dateTime: flight.effectiveTimeOut,
          };
        } else {
          assignment = {
            flight,
            dateTime: flight.effectiveTimeIn,
          };
        }
        assignments.push(assignment);
      });

      assignments.sort(function (a, b) {
        let dateA = new Date(a.dateTime);
        let dateB = new Date(b.dateTime);

        return dateA - dateB;
      });

      //Generate a list of possible start times
      let startTimes = [];
      let endTimes = [];

      assignments.forEach((assignment) => {
        if (
          assignment.flight.originIata ===
          this.flightBoardAirportFilter.iataCode
        ) {
          //The assignment is a kick-out,
          //Start time is 3 hours before the flight's time out
          startTimes.push(
            new Date(assignment.flight.effectiveTimeOut).getTime() -
              3 * 60 * 60 * 1000
          );

          //End time is 1 hour after the flight's time out
          endTimes.push(
            new Date(assignment.flight.effectiveTimeOut).getTime() +
              1 * 60 * 60 * 1000
          );
        } else {
          //The assignment is an arrival,
          //Start time is 1 hour before the flight's time in
          startTimes.push(
            new Date(assignment.flight.effectiveTimeIn).getTime() -
              1 * 60 * 60 * 1000
          );

          //End time is 1 hour after the flight's time out
          endTimes.push(
            new Date(assignment.flight.effectiveTimeIn).getTime() +
              1 * 60 * 60 * 1000
          );
        }
      });

      startTimes.sort(function (a, b) {
        return a - b;
      });

      endTimes.sort(function (a, b) {
        return a - b;
      });

      const assignmentStart = new Date(startTimes[0]);
      const assignmentEnd = new Date(endTimes[endTimes.length - 1]);

      const duration = (assignmentEnd - assignmentStart) / (60 * 1000); // In Minutes

      if (duration < 30) {
        //Less thna 30 minutes
        this.snackbarColor = "red";
        this.snackbarText = `The minimum duration for a station assignment is 30 minutes`;
        this.snackbarVisible = true;
        return;
      }

      if (duration > 2 * 24 * 60) {
        // More than 48 hours
        this.snackbarColor = "red";
        this.snackbarText = `The maximum duration for a station assignment is 48 hours`;
        this.snackbarVisible = true;
        return;
      }

      this.newStationAssignment.originIata =
        this.flightBoardAirportFilter.iataCode;
      this.newStationAssignment.startTime = assignmentStart;
      this.newStationAssignment.endTime = assignmentEnd;
      this.newStationAssignment.duration = duration;

      //---------------Update the authorized flight roles
      let authorizedFlightRoles = [];

      let cargoCount = 0;
      let passengerCount = 0;

      this.newStationAssignment.flights.forEach((flight) => {
        if (flight.aircraft.cargoOnly) {
          cargoCount += 1;
        } else {
          passengerCount += 1;
        }
      });

      if (cargoCount > 0) {
        this.user.securityGroup.flightAssignmentCargoRoles.forEach((role) => {
          if (!authorizedFlightRoles.includes(role)) {
            authorizedFlightRoles.push(role);
          }
        });
      }

      if (passengerCount > 0) {
        this.user.securityGroup.flightAssignmentPassengerRoles.forEach(
          (role) => {
            if (!authorizedFlightRoles.includes(role)) {
              authorizedFlightRoles.push(role);
            }
          }
        );
      }

      if (!authorizedFlightRoles.length) {
        this.snackbarColor = "red";
        this.snackbarText = "Not authorized to assign any flight role";
        this.snackbarVisible = true;
        return;
      }

      //No Jumseat on a Station Assignment
      const filteredRoles = authorizedFlightRoles.filter((item) => {
        return item !== "Jumpseat";
      });

      this.selectedStationAssignmentRole = filteredRoles[0];
      this.selectedStationAssignmentCompany =
        this.user.securityGroup.companies[0];

      this.$store.commit("updateAuthorizedFlightRoles", filteredRoles);

      // this.$store.commit("updateTripToAssign", null);
      // this.$store.commit("updateFlightToAssign", null);
      // this.$store.commit("updateNewStationAssignment", null);

      this.showNewStationAssignmentDialog = true;
    },

    filterWarnings() {
      switch (this.selectedWarningFilter) {
        case "AIMS Listing":
          this.filteredWarnings = this.warnings.filter((item) => {
            return item.type === "AIMS Listing";
          });
          break;

        case "Loadmaster Missing (Departure or Arrival)":
          this.filteredWarnings = this.warnings.filter((item) => {
            return (
              item.type === "Loadmaster Required at Arrival Station" ||
              item.type === "Loadmaster Required at Departure Station"
            );
          });

          break;

        case "Loadmaster Missing (Departure)":
          this.filteredWarnings = this.warnings.filter((item) => {
            return item.type === "Loadmaster Required at Departure Station";
          });
          break;

        case "Loadmaster Missing (Arrival)":
          this.filteredWarnings = this.warnings.filter((item) => {
            return item.type === "Loadmaster Required at Arrival Station";
          });

          break;

        case "Open Assignment":
          this.filteredWarnings = this.warnings.filter((item) => {
            return item.type === "Open Assignment";
          });

          break;

        case "PSR Missing":
          this.filteredWarnings = this.warnings.filter((item) => {
            return item.type === "PSR - Crew Requirements";
          });
          break;

        default:
          this.filteredWarnings = [...this.warnings];
          break;
      }
    },

    async handleAssignFlightToCompany() {
      this.loading = true;

      const res = await this.$store.dispatch("saveFlightAssignment", {
        flight_id: this.flight._id,
        assignedToCompany: this.selectedAssignedToCompany,
        role: this.selectedFlightRole,
        warnings: [],
        remarks: this.flightAssignmentRemarks,
      });

      this.loading = false;

      if (res.status !== 200) {
        this.snackbarColor = "red";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.$store.commit("updateFlight", { ...res.data.flight });

      this.getData();

      this.isCompanyAssignment = false;
      this.flightAssignmentRemarks = null;
    },

    async handleGetAircraftScheduleConflicts() {
      let conflicts = [];

      let previousDestination;
      let thisOrigin;
      let previousTimeIn;
      let thisTimeOut;

      this.aircraftSchedule.forEach((item) => {
        //Check if aircraft schedule has more than one flights
        if (item.flights.length > 1) {
          previousDestination = item.flights[0].destinationIata.slice();
          previousTimeIn = new Date(item.flights[0].effectiveTimeIn).getTime();

          for (let i = 1; i < item.flights.length; i++) {
            thisOrigin = item.flights[i].originIata.slice();
            thisTimeOut = new Date(item.flights[i].effectiveTimeOut).getTime();

            if (thisOrigin !== previousDestination) {
              conflicts.push({
                aircraft: item.aircraft.registration,
                conflict: {
                  type: "Route discontinuity",
                  previousFlight: { ...item.flights[i - 1] },
                  flight: { ...item.flights[i] },
                },
              });
            }

            if (thisTimeOut < previousTimeIn) {
              conflicts.push({
                aircraft: item.aircraft.registration,
                conflict: {
                  type: "Time overlap",
                  previousFlight: { ...item.flights[i - 1] },
                  flight: { ...item.flights[i] },
                },
              });
            }

            previousDestination = item.flights[i].destinationIata.slice();
            previousTimeIn = new Date(
              item.flights[i].effectiveTimeIn
            ).getTime();
          }
        }
      });

      if (!conflicts.length) {
        this.snackbarColor = "#66BB6A";
        this.snackbarText = "No conflicts found";
        this.snackbarVisible = true;
        return;
      }

      const formatConflictSnapshot = (item) => {
        return `${item.aircraft} . ${item.conflict.flight.flightNumber} . ${
          item.conflict.flight.originIata
        }-${item.conflict.flight.destinationIata} . ${this.formatDateTime(
          item.conflict.flight.effectiveTimeOut
        )} . Flight Id ${item.conflict.flight.flightId}`;
      };

      try {
        const pdfDoc = await PDFDocument.create();

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const helveticaBoldFont = await pdfDoc.embedFont(
          StandardFonts.HelveticaBold
        );

        let text = `Aircraft Schedule Conflicts`;
        pdfDoc.setTitle(text);
        pdfDoc.addPage();
        let pages = pdfDoc.getPages();
        let page = pages[0];
        let item;
        let rowHeight = 0;
        let options = {
          x: 50,
          y: 775,
          size: 20,
          font: helveticaBoldFont,
          color: rgb(0, 0, 0),
        };
        const pageCenter = parseInt(page.getWidth() / 2);

        let textWidth = helveticaBoldFont.widthOfTextAtSize(text, options.size);
        options.x = pageCenter - parseInt(textWidth / 2);
        page.drawText(text, options);
        //Printed On
        options.y -= 18;
        options.size = 10;
        options.font = helveticaFont;
        text = `Printed: ${this.formatDateTime(new Date())}`;
        textWidth = helveticaFont.widthOfTextAtSize(text, options.size);
        options.x = pageCenter - parseInt(textWidth / 2);
        page.drawText(text, options);
        options.y -= 40;
        options.x = 50;

        for (let i = 0; i < conflicts.length; i++) {
          item = conflicts[i];

          //---------------------------------------------------ROW 1
          options.x = 50;
          options.font = helveticaFont;
          options.color = rgb(0, 0, 1); //Blue
          options.size = 12;
          page.drawText(`${item.conflict.type}`, options);

          options.size = 10;
          options.y -= 12;
          rowHeight = 13;

          //---------------------------------------------------ROW 2
          options.color = rgb(0, 0, 0); //Black
          page.drawText(`${formatConflictSnapshot(item)}`, options);

          //---------------------------------------------------Separator
          options.color = rgb(0.17, 0.24, 0.31); //Gray
          options.y -= 5;
          options.x = 50;
          page.drawText(
            `__________________________________________________________________________________`,
            options
          );
          options.y -= 15;
          //If last page
          // if (i === conflicts.length - 1) {
          //   options.x = 350;
          //   page.drawText(
          //     `Printed: ${this.formatDateTime(new Date())}`,
          //     options
          //   );
          // }
          if (options.y - 90 < rowHeight && i <= conflicts.length) {
            options.x = 50;
            options.y = 50;
            options.color = rgb(0, 0, 0);
            page.drawText(
              `Monarch - Powered by Raiden Solutions Inc.`,
              options
            );
            options.x = 500;
            options.color = rgb(0, 0, 0);
            page.drawText(`page ${pages.length}`, options);
            pdfDoc.addPage();
            pages = pdfDoc.getPages();
            page = pages[pages.length - 1];
            options.color = rgb(0.15, 0.09, 0.81);
            options.x = 50;
            options.y = 775;
          }
        }
        options.x = 50;
        options.y = 50;
        page.drawText(`Monarch - Powered by Raiden Solutions Inc.`, options);
        options.x = 500;
        options.color = rgb(0, 0, 0); //Black
        page.drawText(`page ${pdfDoc.pageCount}`, options);
        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleEnableEditFlightRemarks() {
      if (!this.canUpdateFlightRemarks) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText =
          "Not authorized. Please contact your administrator.";
        this.snackbarVisible = true;
        return;
      }

      if (this.flight.remarks) {
        this.flightRemarks = this.flight.remarks.slice();
      } else {
        this.flightRemarks = null;
      }

      this.editRemarks = true;
    },

    async handleSaveFlightRemarks() {
      this.loading = true;

      const res = await this.$store.dispatch("updateFlightRemarks", {
        _id: this.flight._id,
        remarks: this.flightRemarks,
      });

      this.loading = false;

      if (res.status !== 200) {
        this.showFlightDialog = false;
        this.snackbarColor = "red";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.$store.commit("updateFlight", res.data.flight);

      if (this.flight.aircraft.cargoOnly) {
        if (this.flight.payload) {
          this.cargo = this.flight.payload.cargo;
        } else {
          this.cargo = 0;
        }

        this.numberOfPassengers = 0;
        this.estimatedWeightPerPassenger = 0;
        this.swaCategory = this.flight.aircraft.swaCategories[0];
        this.selectedBufferWeight = 5000;
      } else {
        if (this.flight.payload) {
          this.numberOfPassengers = this.flight.payload.numberOfPassengers;
          this.estimatedWeightPerPassenger =
            this.flight.payload.estimatedWeightPerPassenger;
          this.swaCategory = this.flight.payload.swaCategory;
          this.selectedBufferWeight = this.flight.payload.bufferWeight;
        } else {
          this.numberOfPassengers = 0;
          this.estimatedWeightPerPassenger = 0;
          this.swaCategory = this.flight.aircraft.swaCategories[0];
          this.selectedBufferWeight = 5000;
        }

        this.cargo = 0;
      }

      this.editRemarks = false;
      this.flightRemarks = null;

      this.getData();
    },

    handleCancelEditFlightRemarks() {
      this.editRemarks = false;
    },

    async handleGetPreviousFlight() {
      if (!this.page) {
        return;
      }
      const scheduleItem = this.page.items.find((item) => {
        return item.aircraft.registration === this.flight.aircraft.registration;
      });

      if (!scheduleItem) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = "Unable to find data";
        this.snackbarVisible = true;
        return;
      }

      const flights = scheduleItem.flights;

      flights.sort(function (a, b) {
        let dateA = new Date(a.effectiveTimeOut);
        let dateB = new Date(b.effectiveTimeOut);

        return dateA - dateB;
      });

      const i = flights.findIndex((flight) => {
        return flight._id === this.flight._id;
      });

      if (i > 0) {
        const flight = { ...flights[i - 1] };
        this.$store.commit("updateFlight", flight);
      } else {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText =
          "No previous flight found within the selected range";
        this.showFlightDialog = false;
        this.snackbarVisible = true;
        return;
      }

      if (this.flight.aircraft.cargoOnly) {
        if (this.flight.payload) {
          this.cargo = this.flight.payload.cargo;
        } else {
          this.cargo = 0;
        }

        this.numberOfPassengers = 0;
        this.estimatedWeightPerPassenger = 0;
        this.swaCategory = this.flight.aircraft.swaCategories[0];
        this.selectedBufferWeight = 5000;
      } else {
        if (this.flight.payload) {
          this.numberOfPassengers = this.flight.payload.numberOfPassengers;
          this.estimatedWeightPerPassenger =
            this.flight.payload.estimatedWeightPerPassenger;
          this.swaCategory = this.flight.payload.swaCategory;
          this.selectedBufferWeight = this.flight.payload.bufferWeight;
        } else {
          this.numberOfPassengers = 0;
          this.estimatedWeightPerPassenger = 0;
          this.swaCategory = this.flight.aircraft.swaCategories[0];
          this.selectedBufferWeight = 5000;
        }

        this.cargo = 0;
      }
    },

    async handleGetNextFlight() {
      if (!this.page) {
        return;
      }

      const scheduleItem = this.page.items.find((item) => {
        return item.aircraft.registration === this.flight.aircraft.registration;
      });

      if (!scheduleItem) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = "Unable to find data";
        this.snackbarVisible = true;
        return;
      }

      const flights = scheduleItem.flights;

      flights.sort(function (a, b) {
        let dateA = new Date(a.effectiveTimeOut);
        let dateB = new Date(b.effectiveTimeOut);

        return dateA - dateB;
      });

      const i = flights.findIndex((flight) => {
        return flight._id === this.flight._id;
      });

      if (i < flights.length - 1) {
        const flight = { ...flights[i + 1] };
        this.$store.commit("updateFlight", flight);
      } else {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = "No next flight found within the selected range";
        this.showFlightDialog = false;
        this.snackbarVisible = true;
        return;
      }

      if (this.flight.aircraft.cargoOnly) {
        if (this.flight.payload) {
          this.cargo = this.flight.payload.cargo;
        } else {
          this.cargo = 0;
        }

        this.numberOfPassengers = 0;
        this.estimatedWeightPerPassenger = 0;
        this.swaCategory = this.flight.aircraft.swaCategories[0];
        this.selectedBufferWeight = 5000;
      } else {
        if (this.flight.payload) {
          this.numberOfPassengers = this.flight.payload.numberOfPassengers;
          this.estimatedWeightPerPassenger =
            this.flight.payload.estimatedWeightPerPassenger;
          this.swaCategory = this.flight.payload.swaCategory;
          this.selectedBufferWeight = this.flight.payload.bufferWeight;
        } else {
          this.numberOfPassengers = 0;
          this.estimatedWeightPerPassenger = 0;
          this.swaCategory = this.flight.aircraft.swaCategories[0];
          this.selectedBufferWeight = 5000;
        }

        this.cargo = 0;
      }
    },

    //-----------------------Flight Assignment
    handleShowFlightAssignmentInformationDialog(flightAssignment) {
      this.showFlightDialog = false;

      this.flightAssignment = {
        ...flightAssignment,
        flight_id: this.flight._id,
        aircraftRegistration: this.flight.aircraft.registration.slice(),
        flightNumber: this.flight.flightNumber.slice(),
        originIata: this.flight.originIata.slice(),
        destinationIata: this.flight.destinationIata.slice(),
        startTime: this.flight.effectiveTimeOut.slice(),
        endTime: this.flight.effectiveTimeIn.slice(),
      };

      this.showFlightAssignmentInformationDialog = true;
    },

    async handleHideFlightAssignmentInformationDialog(e) {
      this.showFlightAssignmentInformationDialog = false;

      if (e.action === "refresh") {
        this.getData();
      }

      this.showFlightDialog = true;
    },

    //-----------------------Ground Assignment

    async handleShowStationAssignmentInformationDialog(stationAssignment) {
      this.showFlightDialog = false;

      try {
        this.loading = true;
        const token = await aad.getIdTokenSilent();
        const res = await api.post(
          "/flights/station-assignment",
          {
            flightIds: stationAssignment.flightIds,
          },
          {
            headers: {
              Authorization: "Bearer " + token.idToken.rawIdToken,
            },
          }
        );

        this.loading = false;

        const flights = res.data.flights.sort(this.compareFlights);

        this.stationAssignment = {
          ...stationAssignment,
          flights,
        };

        this.showStationAssignmentInformationDialog = true;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
        return;
      }
    },

    async hideStationAssignmentInformationDialog(refreshData) {
      this.showStationAssignmentInformationDialog = false;

      if (refreshData) {
        const { _id } = this.flight;

        const res = await this.$store.dispatch("getFlight", _id);

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.$store.commit("updateFlight", res.data.flight);
        this.isCompanyAssignment = false;

        this.getData();
      }

      this.showFlightDialog = true;
    },

    zoomIn() {
      if (this.scale > 1) {
        this.scale -= 1;
      }
    },

    zoomOut() {
      if (this.scale < 8) {
        this.scale += 1;
      }
    },

    //---------------------------------Trip
    handleInitTrip() {
      this.newStationAssignment = null;
      this.trip = {
        flights: [],
        route: "Empty",
        duration: 0,
      };
    },
    handleResetTrip() {
      this.trip = {
        flights: [],
        route: "Empty",
        duration: 0,
      };
    },
    handleRemoveFlightFromTrip(flight) {
      const _flights = this.trip.flights.filter((item) => {
        return item.flightId !== flight.flightId;
      });

      this.trip.flights = [..._flights];
    },

    handleGetEmployeeForTrip() {
      //---------------Update the authorized flight roles
      let authorizedFlightRoles = [];

      let cargoCount = 0;
      let passengerCount = 0;

      this.trip.flights.forEach((flight) => {
        if (flight.aircraft.cargoOnly) {
          cargoCount += 1;
        } else {
          passengerCount += 1;
        }
      });

      if (cargoCount > 0) {
        this.user.securityGroup.flightAssignmentCargoRoles.forEach((role) => {
          if (!authorizedFlightRoles.includes(role)) {
            authorizedFlightRoles.push(role);
          }
        });
      }

      if (passengerCount > 0) {
        this.user.securityGroup.flightAssignmentPassengerRoles.forEach(
          (role) => {
            if (!authorizedFlightRoles.includes(role)) {
              authorizedFlightRoles.push(role);
            }
          }
        );
      }

      if (!authorizedFlightRoles.length) {
        this.snackbarColor = "red";
        this.snackbarText = "Not authorized to assign any flight role";
        this.snackbarVisible = true;
        return;
      }

      const assignmentData = {
        type: "Trip Assignment",
        trip: { ...this.trip },
      };

      this.$store.commit("updateAuthorizedFlightRoles", authorizedFlightRoles);
      this.$store.commit("updateAssignmentData", assignmentData);
      this.$router.push({ name: "assignmentBoard" });
    },

    //-----------------------------Station Assignment

    handleInitStationAssignment() {
      if (!this.flightBoardAirportFilter.active) {
        this.snackbarColor = "red";
        this.snackbarText = `Filter by IATA must be active to start a new Station Assignment.`;
        this.snackbarVisible = true;
        return;
      }

      this.trip = null;

      if (this.canCreateGroundAssignments) {
        if (
          this.user.securityGroup.authorizations.flight.read.filterByGateway
        ) {
          let codes;
          if (this.user.subGatewayCodes) {
            codes = [...this.user.subGatewayCodes, this.user.gatewayCode];
          } else {
            codes = [this.user.gatewayCode];
          }

          if (!codes.includes(this.selectedIataCode.toUpperCase())) {
            this.snackbarColor = "red";
            this.snackbarText = `Not authorized to assign in ${this.selectedIataCode.toUpperCase()}`;
            this.snackbarVisible = true;
            return;
          }
        }
      } else {
        this.snackbarColor = "red";
        this.snackbarText = `Not authorized to assign in ${station}`;
        this.snackbarVisible = true;
        return;
      }

      const authorizedFlightRoles = [
        ...this.user.securityGroup.flightAssignmentCargoRoles,
        ...this.user.securityGroup.flightAssignmentPassengerRoles,
      ];

      if (!authorizedFlightRoles.length) {
        this.snackbarColor = "red";
        this.snackbarText = "Not authorized to assign any flight role";
        this.snackbarVisible = true;
        return;
      }

      //No Jumseat on a Station Assignment
      const filteredRoles = authorizedFlightRoles.filter((item) => {
        return item !== "Jumpseat";
      });

      this.$store.commit("updateAuthorizedFlightRoles", filteredRoles);

      this.selectedAssignedToCompany = this.user.securityGroup.companies[0];

      this.newStationAssignment = {
        flights: [],
        duration: 0,
        remarks: null,
      };
    },

    handleResetNewStationAssignment() {
      this.newStationAssignment = {
        flights: [],
        duration: 0,
        remarks: null,
      };
    },

    async initSingleFlightStationAssignment({ flight, station }) {
      if (this.canCreateGroundAssignments) {
        if (
          this.user.securityGroup.authorizations.flight.read.filterByGateway
        ) {
          let codes;
          if (this.user.subGatewayCodes) {
            codes = [...this.user.subGatewayCodes, this.user.gatewayCode];
          } else {
            codes = [this.user.gatewayCode];
          }

          if (!codes.includes(station)) {
            this.snackbarColor = "red";
            this.snackbarText = `Not authorized to assign in ${station}`;
            this.snackbarVisible = true;
            return;
          }
        }
      } else {
        this.snackbarColor = "red";
        this.snackbarText = `Not authorized to assign in ${station}`;
        this.snackbarVisible = true;
        return;
      }

      this.showFlightDialog = false;

      //------------------------------------Airport Filter
      let flightBoardAirportFilter = {
        active: false,
        match: "",
        iataCode: "",
      };

      this.filterByAirport = true;
      this.selectedIataCode = station.slice();
      this.selectedIataMatch = "Departing or Arriving";

      const i = this.airports.findIndex((item) => {
        return item.iata === this.selectedIataCode.toUpperCase();
      });

      if (i >= 0) {
        flightBoardAirportFilter = {
          active: true,
          match: this.selectedIataMatch.slice(),
          iataCode: this.selectedIataCode.slice().toUpperCase(),
        };
      } else {
        this.snackbarColor = "red";
        this.snackbarText = `${station} airport not found.`;
        this.snackbarVisible = true;
        return;
      }

      this.$store.commit(
        "updateFlightBoardAirportFilter",
        flightBoardAirportFilter
      );

      this.getData();

      let authorizedFlightRoles;

      if (this.flight.aircraft.cargoOnly) {
        authorizedFlightRoles = [
          ...this.user.securityGroup.flightAssignmentCargoRoles,
        ];
      } else {
        authorizedFlightRoles = [
          ...this.user.securityGroup.flightAssignmentPassengerRoles,
        ];
      }

      if (!authorizedFlightRoles.length) {
        this.snackbarColor = "red";
        this.snackbarText = "Not authorized to assign any flight role";
        this.snackbarVisible = true;
        return;
      }

      //No Jumseat on a Station Assignment
      const filteredRoles = authorizedFlightRoles.filter((item) => {
        return item !== "Jumpseat";
      });

      this.$store.commit("updateAuthorizedFlightRoles", filteredRoles);

      this.trip = null;

      this.selectedAssignedToCompany = this.user.securityGroup.companies[0];

      this.newStationAssignment = {
        flights: [{ ...flight }],
        duration: 0,
        remarks: null,
      };
    },

    handleCancelNewStationAssignment() {
      this.newStationAssignment = null;
    },

    removeFlightFromNewStationAssignment(flight) {
      const _flights = this.newStationAssignment.flights.filter((item) => {
        return item.flightId !== flight.flightId;
      });

      this.newStationAssignment.flights = [..._flights];
    },

    handleShowWeightConverter() {
      this.showWeightConverter = true;
    },

    calculateAveragePassengerWeight() {
      const bufferWeight = parseInt(this.selectedBufferWeight);
      const numberOfPassengers = parseInt(this.numberOfPassengers);
      const reverseCalculatorPayload = parseInt(this.reverseCalculatorPayload);

      const p = reverseCalculatorPayload - bufferWeight;

      this.estimatedWeightPerPassenger = Math.ceil(p / numberOfPassengers);
      this.showReverseCalculator = false;
    },

    async handleCaptureFlightBoard() {
      this.loading = true;

      const board = this.$refs.board;

      try {
        // Convert the DOM element to a blob
        const blob = await domtoimage.toBlob(board);

        // Convert the blob to an array buffer
        const response = new Response(blob);
        const arrayBuffer = await response.arrayBuffer();

        const pdfDoc = await PDFDocument.create();
        const pngImage = await pdfDoc.embedPng(arrayBuffer);

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

        const title = `Monarch- Flight Board`;
        pdfDoc.setTitle(title);

        const pngDims = pngImage.scale(0.85);
        pdfDoc.addPage([pngDims.width + 100, pngDims.height + 100]);
        let pages = pdfDoc.getPages();
        let page = pages[0];
        let pdfOptions = {
          x: 50,
          y: 50,
          size: 16,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        };
        page.drawImage(pngImage, {
          x: pdfOptions.x,
          y: pdfOptions.y,
          width: pngDims.width,
          height: pngDims.height,
        });
        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
      }
    },

    async handlePrintFlightBoardList() {
      if (!this.pages.length) {
        this.snackbarColor = "red";
        this.snackbarText = "Nothing to print";
        this.snackbarVisible = true;
      }

      const formatFlight = (f) => {
        return `${f.aircraft.registration} . ${f.flightNumber} . ${
          f.originIata
        }-${f.destinationIata} . ${this.formatDate(
          f.effectiveTimeOut
        )} . ETD ${f.effectiveTimeOut.substring(
          11,
          16
        )}z / ETA ${f.effectiveTimeIn.substring(11, 16)}z`;
      };

      const formatCrewmember = (crewmember) => {
        if (crewmember.employee) {
          return `${crewmember.role}: ${crewmember.employee.surname}, ${
            crewmember.employee.givenName
          } - Phone: ${
            crewmember.employee.mobilePhone
              ? crewmember.employee.mobilePhone
              : "Not provided."
          }`;
        } else {
          return `${crewmember.role}: ${crewmember.assignedToCompany} `;
        }
      };
      try {
        const pdfDoc = await PDFDocument.create();

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const helveticaBoldFont = await pdfDoc.embedFont(
          StandardFonts.HelveticaBold
        );

        let text;
        let textWidth;
        text = `Flight Schedule ${this.formatDate(
          this.dateFilter.start
        )} - ${this.formatDate(this.dateFilter.end)}`;
        pdfDoc.setTitle(text);
        pdfDoc.addPage();
        let pages = pdfDoc.getPages();
        let page = pages[0];

        //let crewmember;
        //let flightRole;
        let rowHeight = 0;
        let options = {
          x: 50,
          y: 775,
          size: 20,
          font: helveticaBoldFont,
          color: rgb(0, 0, 0),
        };
        const pageCenter = parseInt(page.getWidth() / 2);

        textWidth = helveticaBoldFont.widthOfTextAtSize(text, options.size);
        options.x = pageCenter - parseInt(textWidth / 2);
        page.drawText(text, options);
        //Printed On
        options.y -= 18;
        options.size = 10;
        options.font = helveticaFont;
        text = `Printed: ${this.formatDateTime(new Date())}`;
        textWidth = helveticaFont.widthOfTextAtSize(text, options.size);
        options.x = pageCenter - parseInt(textWidth / 2);
        page.drawText(text, options);
        options.y -= 40;
        options.x = 50;
        let i = 0;

        let flights = [];

        this.pages.forEach((page) => {
          page.items.forEach((item) => {
            item.flights.forEach((flight) => {
              flights.push(flight);
            });
          });
        });

        flights.forEach((flight) => {
          //---------------------------------------------------ROW 1
          options.x = 50;
          options.font = helveticaFont;
          options.color = rgb(0, 0, 1); //Blue
          options.size = 12;
          page.drawText(`${formatFlight(flight)}`, options);

          //-------------------------------------------Flight Crewmembers
          options.size = 10;
          options.y -= 3;
          rowHeight = 15;
          flight.crewmembers?.forEach((crewmember) => {
            options.color = rgb(0.17, 0.24, 0.31); //Gray
            rowHeight += 15;
            options.y -= 12;
            page.drawText(`${formatCrewmember(crewmember)}`, options);
          });

          if (this.printGroundCrewmembers) {
            //-------------------------------------------Ground Crewmembers
            flight.groundCrewmembers?.forEach((crewmember) => {
              options.color = rgb(0.5976, 0.2968, 0); //Brown
              rowHeight += 15;
              options.y -= 12;
              page.drawText(
                `Station(${crewmember.originIata}) - ${formatCrewmember(
                  crewmember
                )}`,
                options
              );
            });
          }

          //---------------------------------------------------Separator
          options.color = rgb(0.17, 0.24, 0.31); //Gray
          options.y -= 10;
          options.x = 50;
          page.drawText(
            `__________________________________________________________________________________`,
            options
          );
          options.y -= 20;

          //If last page
          if (i === flights.length - 1) {
            options.x = 350;
            page.drawText(
              `Printed: ${flights.length} flight(s) - ${this.formatDateTime(
                new Date()
              )}`,
              options
            );
            return;
          }

          if (options.y - 70 < rowHeight && i <= flights.length - 1) {
            options.x = 50;
            options.y = 50;
            options.color = rgb(0, 0, 0);
            page.drawText(
              `Monarch - Powered by Raiden Solutions Inc.`,
              options
            );
            options.x = 500;
            options.color = rgb(0, 0, 0);
            page.drawText(`page ${pages.length}`, options);
            pdfDoc.addPage();
            pages = pdfDoc.getPages();
            page = pages[pages.length - 1];
            options.color = rgb(0.15, 0.09, 0.81);
            options.x = 50;
            options.y = 775;
          }

          i++;
        });

        options.x = 50;
        options.y = 50;
        page.drawText(`Monarch - Powered by Raiden Solutions Inc.`, options);
        options.x = 500;
        options.color = rgb(0, 0, 0); //Black
        page.drawText(`page ${pdfDoc.pageCount}`, options);
        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.employee = null;
      }
    },

    getFlightFromWarning({ flight }) {
      //this.showWarningDialog = false;

      if (flight.aircraft.cargoOnly) {
        if (flight.payload) {
          this.cargo = flight.payload.cargo;
        } else {
          this.cargo = 0;
        }

        this.numberOfPassengers = 0;
        this.estimatedWeightPerPassenger = 0;
        this.swaCategory = flight.aircraft.swaCategories[0];
        this.selectedBufferWeight = 5000;
      } else {
        if (flight.payload) {
          this.numberOfPassengers = flight.payload.numberOfPassengers;
          this.estimatedWeightPerPassenger =
            flight.payload.estimatedWeightPerPassenger;
          this.swaCategory = flight.payload.swaCategory;
          this.selectedBufferWeight = flight.payload.bufferWeight;
        } else {
          this.numberOfPassengers = 0;
          this.estimatedWeightPerPassenger = 0;
          this.swaCategory = flight.aircraft.swaCategories[0];
          this.selectedBufferWeight = 5000;
        }

        this.cargo = 0;
      }

      this.$store.commit("updateFlight", { ...flight });

      this.showFlightDialog = true;
    },

    getFlightFromList(flight) {
      if (flight.aircraft.cargoOnly) {
        if (flight.payload) {
          this.cargo = flight.payload.cargo;
        } else {
          this.cargo = "0";
        }

        this.numberOfPassengers = 0;
        this.estimatedWeightPerPassenger = 0;
        this.swaCategory = flight.aircraft.swaCategories[0];
        this.selectedBufferWeight = 5000;
      } else {
        if (flight.payload) {
          this.numberOfPassengers = flight.payload.numberOfPassengers;
          this.estimatedWeightPerPassenger =
            flight.payload.estimatedWeightPerPassenger;
          this.swaCategory = flight.payload.swaCategory;
          this.selectedBufferWeight = flight.payload.bufferWeight;
        } else {
          this.numberOfPassengers = 0;
          this.estimatedWeightPerPassenger = 0;
          this.swaCategory = flight.aircraft.swaCategories[0];
          this.selectedBufferWeight = 5000;
        }

        this.cargo = 0;
      }

      this.$store.commit("updateFlight", { ...flight });

      this.showFlightDialog = true;
    },

    handleFlightLabelClicked(flight) {
      if (this.newStationAssignment) {
        this.computeNewStationAssignment(flight);
      } else if (this.trip) {
        this.computeTrip(flight);
      } else {
        this.$store.commit("updateFlight", { ...flight });

        if (this.flight.aircraft.cargoOnly) {
          if (this.flight.payload) {
            this.cargo = this.flight.payload.cargo;
          } else {
            this.cargo = 0;
          }

          this.numberOfPassengers = 0;
          this.estimatedWeightPerPassenger = 0;
          this.swaCategory = this.flight.aircraft.swaCategories[0];
          this.selectedBufferWeight = 5000;
        } else {
          if (this.flight.payload) {
            this.numberOfPassengers = this.flight.payload.numberOfPassengers;
            this.estimatedWeightPerPassenger =
              this.flight.payload.estimatedWeightPerPassenger;
            this.swaCategory = this.flight.payload.swaCategory;
            this.selectedBufferWeight = this.flight.payload.bufferWeight;
          } else {
            this.numberOfPassengers = 0;
            this.estimatedWeightPerPassenger = 0;
            this.swaCategory = this.flight.aircraft.swaCategories[0];
            this.selectedBufferWeight = 5000;
          }

          this.cargo = 0;
        }

        this.showFlightDialog = true;
      }
    },

    computeNewStationAssignment(flight) {
      let i;

      if (
        flight.originIata !== this.flightBoardAirportFilter.iataCode &&
        flight.destinationIata !== this.flightBoardAirportFilter.iataCode
      ) {
        this.snackbarColor = "red";
        this.snackbarText = `Assignments must depart or arrive from ${this.flightBoardAirportFilter.iataCode}.`;
        this.snackbarVisible = true;
        return;
      }

      i = this.newStationAssignment.flights.findIndex((item) => {
        return item.flightId === flight.flightId;
      });

      if (i >= 0) {
        this.snackbarColor = "red";
        this.snackbarText = `Flight ${flight.flightNumber} is already in this assignment.`;
        this.snackbarVisible = true;
        return;
      }

      if (
        this.newStationAssignment.flights.length ===
        this.maximumStationAssignmentAssignments
      ) {
        this.snackbarColor = "red";
        this.snackbarText =
          "Maximum number of segments reached, unable to add flight";
        this.snackbarVisible = true;
        return;
      }

      this.newStationAssignment.flights.push(flight);
    },

    computeTrip(flight) {
      let i;
      if (this.trip.flights.length) {
        i = this.trip.flights.findIndex((item) => {
          return item.flightId === flight.flightId;
        });

        if (i >= 0) {
          this.snackbarColor = "red";
          this.snackbarText = `Flight ${flight.flightNumber} is already in this trip`;
          this.snackbarVisible = true;
          return;
        }

        if (this.trip.flights.length === this.maximumTripSegments) {
          this.snackbarColor = "red";
          this.snackbarText =
            "Maximum number of segments reached, unable to add flight";
          this.snackbarVisible = true;
          return;
        }

        const previousFlight = this.trip.flights[this.trip.flights.length - 1];

        if (flight.originIata !== previousFlight.destinationIata) {
          this.snackbarColor = "red";
          this.snackbarText = "Route discontinuity, unable to add flight";
          this.snackbarVisible = true;
          return;
        }

        if (
          new Date(flight.effectiveTimeOut).getTime() <=
          new Date(previousFlight.effectiveTimeIn).getTime()
        ) {
          this.snackbarColor = "red";
          this.snackbarText = "ETA / ETD times conflict, unable to add flight";
          this.snackbarVisible = true;
          return;
        }

        const t1 = new Date(this.trip.flights[0].effectiveTimeOut).getTime();
        const t2 = new Date(flight.effectiveTimeIn).getTime();

        const duration = (t2 - t1) / 60 / 1000; // Duration in minutes

        //Max trip duration 168 hours / 7 days
        if (duration > this.maximumTripDuration) {
          this.snackbarColor = "red";
          this.snackbarText = `Maximum trip duration is ${
            this.maximumTripDuration / 60
          } hours`;
          this.snackbarVisible = true;
          return;
        }

        this.trip.flights.push(flight);
        this.trip.route += `-${flight.destinationIata}`;
        this.trip.duration = duration;
        this.trip.startTime = new Date(this.trip.flights[0].effectiveTimeOut);
      } else {
        const t1 = new Date(flight.effectiveTimeOut).getTime();
        const t2 = new Date(flight.effectiveTimeIn).getTime();

        this.trip.flights.push(flight);
        this.trip.route = `${flight.originIata}-${flight.destinationIata}`;
        this.trip.duration = (t2 - t1) / 1000 / 60;
      }
    },

    handleGetEmployeeForStationAssignment() {
      let assignments = [];
      let assignment;

      this.newStationAssignment.flights.forEach((flight) => {
        if (flight.originIata === this.flightBoardAirportFilter.iataCode) {
          assignment = {
            flight,
            dateTime: flight.effectiveTimeOut,
          };
        } else {
          assignment = {
            flight,
            dateTime: flight.effectiveTimeIn,
          };
        }
        assignments.push(assignment);
      });

      assignments.sort(function (a, b) {
        let dateA = new Date(a.dateTime);
        let dateB = new Date(b.dateTime);

        return dateA - dateB;
      });

      //Generate a list of possible start times
      let startTimes = [];
      let endTimes = [];

      assignments.forEach((assignment) => {
        if (
          assignment.flight.originIata ===
          this.flightBoardAirportFilter.iataCode
        ) {
          //The assignment is a kick-out,
          //Start time is 3 hours before the flight's time out
          startTimes.push(
            new Date(assignment.flight.effectiveTimeOut).getTime() -
              3 * 60 * 60 * 1000
          );

          //End time is 1 hour after the flight's time out
          endTimes.push(
            new Date(assignment.flight.effectiveTimeOut).getTime() +
              1 * 60 * 60 * 1000
          );
        } else {
          //The assignment is an arrival,
          //Start time is 1 hour before the flight's time in
          startTimes.push(
            new Date(assignment.flight.effectiveTimeIn).getTime() -
              1 * 60 * 60 * 1000
          );

          //End time is 1 hour after the flight's time in
          endTimes.push(
            new Date(assignment.flight.effectiveTimeIn).getTime() +
              1 * 60 * 60 * 1000
          );
        }
      });

      startTimes.sort(function (a, b) {
        return a - b;
      });

      endTimes.sort(function (a, b) {
        return a - b;
      });

      const assignmentStart = startTimes[0];
      const assignmentEnd = endTimes[endTimes.length - 1];

      const duration = (assignmentEnd - assignmentStart) / (60 * 1000); // In Minutes

      if (duration < 30) {
        //Less thna 30 minutes
        this.snackbarColor = "red";
        this.snackbarText = `The minimum duration for a station assignment is 30 minutes`;
        this.snackbarVisible = true;
        return;
      }

      if (duration > 2 * 24 * 60) {
        // More than 48 hours
        this.snackbarColor = "red";
        this.snackbarText = `The maximum duration for a station assignment is 48 hours`;
        this.snackbarVisible = true;
        return;
      }

      this.newStationAssignment.originIata =
        this.flightBoardAirportFilter.iataCode;
      this.newStationAssignment.startTime = assignmentStart;
      this.newStationAssignment.endTime = assignmentEnd;
      this.newStationAssignment.duration = duration;

      //---------------Update the authorized flight roles
      let authorizedFlightRoles = [];

      let cargoCount = 0;
      let passengerCount = 0;

      this.newStationAssignment.flights.forEach((flight) => {
        if (flight.aircraft.cargoOnly) {
          cargoCount += 1;
        } else {
          passengerCount += 1;
        }
      });

      if (cargoCount > 0) {
        this.user.securityGroup.flightAssignmentCargoRoles.forEach((role) => {
          if (!authorizedFlightRoles.includes(role)) {
            authorizedFlightRoles.push(role);
          }
        });
      }

      if (passengerCount > 0) {
        this.user.securityGroup.flightAssignmentPassengerRoles.forEach(
          (role) => {
            if (!authorizedFlightRoles.includes(role)) {
              authorizedFlightRoles.push(role);
            }
          }
        );
      }

      if (!authorizedFlightRoles.length) {
        this.snackbarColor = "red";
        this.snackbarText = "Not authorized to assign any flight role";
        this.snackbarVisible = true;
        return;
      }

      //No Jumseat on a Station Assignment
      const filteredRoles = authorizedFlightRoles.filter((item) => {
        return item !== "Jumpseat";
      });

      const assignmentData = {
        type: "Station Assignment",
        stationAssignment: { ...this.newStationAssignment },
      };

      this.$store.commit("updateAuthorizedFlightRoles", filteredRoles);
      this.$store.commit("updateAssignmentData", assignmentData);
      this.$router.push({ name: "assignmentBoard" });
    },

    async getData() {
      this.loading = true;

      this.aircraftSchedule = null;
      this.page = null;

      //--------------------------Aircraft Deck Filter
      let aircraftDeck = "Cargo and Pax.";

      if (this.aircraftDeckFilter.active) {
        aircraftDeck = this.aircraftDeckFilter.deck.slice();
      }

      //--------------------------Airport Filter
      let airportFilter = {
        active: false,
        match: "",
        iataCode: "",
      };

      if (this.flightBoardAirportFilter.active) {
        airportFilter = {
          active: true,
          match: this.flightBoardAirportFilter.match.slice(),
          iataCode: this.flightBoardAirportFilter.iataCode.slice(),
        };
      }

      //--------------------------Aircraft Filter
      let aircraftFilter = {
        active: false,
        registrations: [],
      };

      if (this.flightBoardAircraftFilter.active) {
        let registrations = [];
        this.flightBoardAircraftFilter.aircraft.forEach((a) => {
          registrations.push(a.registration);
        });
        aircraftFilter = {
          active: true,
          registrations,
        };
      }

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.post(
          "/flights",
          {
            dateFilterStart: this.dateFilter.start,
            dateFilterEnd: this.dateFilter.end,
            aircraftDeck,
            aircraftFilter,
            airportFilter,
          },
          {
            headers: {
              Authorization: "Bearer " + token.idToken.rawIdToken,
            },
          }
        );

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.aircraftSchedule = res.data.aircraftSchedule;

        this.paginate();
        this.getWarnings(false);
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
      }
    },

    formatCargoOnly(aircraft) {
      if (aircraft.cargoOnly) {
        return "Cargo";
      } else {
        return "Passenger";
      }
    },

    async handleShowAirportInformationDialog(code) {
      this.airport = this.airports.find((airport) => {
        return airport.iata === code;
      });

      this.showFlightDialog = false;

      if (this.airport) {
        const exemptions = this.crewRequirementExemptions.filter((item) => {
          return (
            item.airportCode === this.airport.iata ||
            item.airportCode === "All Stations"
          );
        });

        this.airport.loadmasterExemptions = exemptions;
        this.showAirportInformationDialog = true;
      } else {
        this.snackbarColor = "red";
        this.snackbarText = `Sorry we couln't find an airport with IATA code ${code}`;
        this.snackbarVisible = true;

        setTimeout(() => {
          this.showFlightDialog = true;
        }, 3000);
      }
    },

    handleHideAirportInformationDialog() {
      this.airport = null;
      this.showAirportInformationDialog = false;
      this.showFlightDialog = true;
    },

    hideFlightDialog(refreshData) {
      this.$store.commit("updateFlight", null);
      this.isCompanyAssignment = false;

      if (refreshData) {
        this.getData();
      }

      this.showFlightDialog = false;
    },

    extractLastName(fullname) {
      if (fullname.length) {
        const res = fullname.split(" ");
        return res[0];
      } else {
        return "";
      }
    },

    getWarnings(showDialog) {
      this.warnings = [];

      if (!this.pages.length) {
        return;
      }

      let generalWarnings = [];
      let cargoWarnings = [];
      let paxWarnings = [];

      let psrCount; // PSR Count is used to check that at least one PSR is assigned if the flight needs one (pax aircraft only)
      let flightLoadmasterCount; // Loadmaster Count is used to check that at least one Loadmaster is assigned if the flight needs one (cargo aircraft only)
      let originLoadmasterCount;
      let destinationLoadmasterCount;
      let exemptions;

      this.pages.forEach((page) => {
        page.items.forEach((item) => {
          item.flights.forEach((flight) => {
            // Catch to avoid reading flights without an aircraft (due to aircraft assigned was generic)
            if (flight.aircraft) {
              psrCount = 0;
              flightLoadmasterCount = 0;
              originLoadmasterCount = 0;
              destinationLoadmasterCount = 0;

              //  Check Flight Crewmembers

              flight.crewmembers?.forEach((crewmember) => {
                //Employee assigned
                if (crewmember.employee) {
                  // Get Crewmembers Not Listed in Aims
                  if (flight.jumpseaters && flight.jumpseaters.length) {
                    const i = flight.jumpseaters.findIndex((js) => {
                      return (
                        this.extractLastName(js.name) ===
                        crewmember.employee.surname.toUpperCase()
                      );
                    });

                    if (i < 0) {
                      generalWarnings.push({
                        flight,
                        type: "AIMS Listing",
                        message: `${this.formatFlightSnapshot(flight)} - ${
                          crewmember.employee.surname
                        }, ${
                          crewmember.employee.givenName
                        } is not listed in AIMS`,
                      });
                    }
                  } else {
                    generalWarnings.push({
                      flight,
                      type: "AIMS Listing",
                      message: `${this.formatFlightSnapshot(flight)} - ${
                        crewmember.employee.surname
                      }, ${
                        crewmember.employee.givenName
                      }  is not listed in AIMS`,
                    });
                  }
                }
                //Open assignment
                else {
                  generalWarnings.push({
                    flight,
                    type: "Open Assignment",
                    message: `${crewmember.assignedToCompany} - ${
                      crewmember.role
                    } - ${this.formatFlightSnapshot(flight)}`,
                  });
                }
              });

              //Trigger Cargo Warnings
              if (flight.aircraft.cargoOnly) {
                //Check Flight Crewmembers for Loadmasters
                if (flight.crewmembers && flight.crewmembers.length) {
                  flight.crewmembers.forEach((crewmember) => {
                    //Employee assigned
                    if (crewmember.employee) {
                      if (this.loadmasterRoles.includes(crewmember.role)) {
                        flightLoadmasterCount++;
                      }
                    }
                  });
                }

                //Check ground crewmembers only if no flight loadmaster found
                if (flightLoadmasterCount === 0) {
                  // No loadmaster on-board,
                  // check Ground Crewmembers for Loadmasters in both departure and arrival stations

                  flight.groundCrewmembers?.forEach((groundCrewmember) => {
                    if (groundCrewmember.employee) {
                      if (
                        this.loadmasterRoles.includes(groundCrewmember.role)
                      ) {
                        //Loadmaster at Origin
                        if (groundCrewmember.originIata === flight.originIata) {
                          originLoadmasterCount++;
                        }

                        //Loadmaster at Destination
                        if (
                          groundCrewmember.originIata === flight.destinationIata
                        ) {
                          destinationLoadmasterCount++;
                        }
                      }
                    }
                  });

                  if (originLoadmasterCount === 0) {
                    //At this point no loadmaster was found on-board and
                    //there is no loadmaster at the departure station

                    // Origin / Departure
                    exemptions = this.crewRequirementExemptions.filter(
                      (item) => {
                        return (
                          (item.airportCode === flight.originIata ||
                            item.airportCode === "All Stations") &&
                          (item.aircraftType === flight.aircraft.type ||
                            item.aircraftType === "All Aircraft Types")
                        );
                      }
                    );

                    if (!exemptions.length) {
                      //Trigger warning if origin and aircraft type are not exempted
                      cargoWarnings.push({
                        flight,
                        type: "Loadmaster Required at Departure Station",
                        message: `${this.formatFlightSnapshot(
                          flight
                        )} - Loadmaster Required at ${flight.originIata}`,
                      });
                    }
                  }

                  if (destinationLoadmasterCount === 0) {
                    //At this point no loadmaster was found on-board and
                    //there is no loadmaster at the arrival station

                    //Arrival / Destination
                    exemptions = this.crewRequirementExemptions.filter(
                      (item) => {
                        return (
                          (item.airportCode === flight.destinationIata ||
                            item.airportCode === "All Stations") &&
                          (item.aircraftType === flight.aircraft.type ||
                            item.aircraftType === "All Aircraft Types")
                        );
                      }
                    );

                    if (!exemptions.length) {
                      //Trigger warning if origin and aircraft type are not exempted
                      cargoWarnings.push({
                        flight,
                        type: "Loadmaster Required at Arrival Station",
                        message: `${this.formatFlightSnapshot(
                          flight
                        )} - Loadmaster Required at ${flight.destinationIata}`,
                      });
                    }
                  }
                }
              } else {
                //  Check Flight Crewmembers for PSRs

                flight.crewmembers?.forEach((crewmember) => {
                  //Employee assigned
                  if (crewmember.employee) {
                    if (this.psrRoles.includes(crewmember.role)) {
                      psrCount++;
                    }
                  }
                });

                //  Check Ground Crewmembers for PSRs

                flight.groundCrewmembers?.forEach((groundCrewmember) => {
                  if (groundCrewmember.employee) {
                    if (this.psrRoles.includes(groundCrewmember.role)) {
                      psrCount++;
                    }
                  }
                });

                //Trigger Pax Warnings
                if (psrCount < 1) {
                  paxWarnings.push({
                    flight,
                    type: "PSR - Crew Requirements",
                    message: `${this.formatFlightSnapshot(
                      flight
                    )} - PSR - Minimum crew requirements not met.`,
                  });
                }
              }
            }
          });
        });
      });

      if (this.selectedAircraftDeck === "Cargo") {
        this.warnings = [...generalWarnings, ...cargoWarnings];
      } else if (this.selectedAircraftDeck === "Passenger") {
        this.warnings = [...generalWarnings, ...paxWarnings];
      } else {
        this.warnings = [...generalWarnings, ...cargoWarnings, ...paxWarnings];
      }

      this.filterWarnings();

      if (this.filteredWarnings.length > 0) {
        this.showWarningDialog = showDialog;
      }
    },

    handleOpenPayloadCalculator() {
      if (!this.canUpdateFlight) {
        this.snackbarColor = "red";
        this.snackbarText =
          "Not authorized. Please contact your administrator.";
        this.snackbarVisible = true;
        return;
      }

      if (this.flight.payload) {
        this.numberOfPassengers = this.flight.payload.numberOfPassengers;
        this.estimatedWeightPerPassenger =
          this.flight.payload.estimatedWeightPerPassenger;
        this.swaCategory = this.flight.payload.swaCategory;
        this.selectedBufferWeight = this.flight.payload.bufferWeight;
      } else {
        this.numberOfPassengers = 0;
        this.estimatedWeightPerPassenger = 0;
        this.swaCategory = this.flight.aircraft.swaCategories[0];
        this.selectedBufferWeight = 5000;
      }

      this.cargo = 0;
      this.showFlightDialog = false;
      this.showPayloadDialog = true;
    },

    handleOpenCargoPayloadCalculator() {
      if (!this.canUpdateFlight) {
        this.snackbarColor = "red";
        this.snackbarText =
          "Not authorized. Please contact your administrator.";
        this.snackbarVisible = true;
        return;
      }

      if (this.flight.payload) {
        this.cargo = this.flight.payload.cargo;
      } else {
        this.cargo = 0;
      }

      this.numberOfPassengers = 0;
      this.estimatedWeightPerPassenger = 0;
      this.swaCategory = this.flight.aircraft.swaCategories[0];
      this.selectedBufferWeight = 5000;
      this.showFlightDialog = false;
      this.showCargoPayloadDialog = true;
    },

    async handleSavePayload(useMax) {
      const maximumPayload = parseInt(this.flight.aircraft.maximumPayload);
      let finalEstimatedPayload;

      if (useMax) {
        if (
          !this.isValidNumberOfPassengers ||
          !this.isValidEstimatedWeightPerPassenger
        ) {
          return;
        }
        finalEstimatedPayload = maximumPayload;
      } else {
        if (!this.isValidFinalEstimatedPayload) {
          return;
        }
        finalEstimatedPayload = this.finalEstimatedPayload;
      }

      let payload = {
        flightId: this.flight.flightId,
        aircraftRegistration: this.flight.aircraft.registration,
        cargo: 0,
        bufferWeight: this.selectedBufferWeight,
        swaCategory: this.swaCategory,
        finalEstimatedPayload,
      };

      if (this.swaCategory.name === "Ferry") {
        payload.numberOfPassengers = 0;
        payload.estimatedWeightPerPassenger = 0;
        payload.preliminaryWeight = 0;
      } else {
        payload.numberOfPassengers = this.numberOfPassengers;
        payload.estimatedWeightPerPassenger = this.estimatedWeightPerPassenger;
        payload.preliminaryWeight = this.preliminaryWeight;
      }

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/flights/${this.flight._id}/payload`,
          payload,
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.$store.commit("updateFlight", res.data.flight);

        this.getData();
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
        this.showPayloadDialog = false;
        this.showReverseCalculator = false;
        this.showFlightDialog = true;
      }
    },

    async handleDeletePayload() {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.delete(`/flights/${this.flight._id}/payload`, {
          headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.$store.commit("updateFlight", res.data.flight);

        this.getData();
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
        this.showPayloadDialog = false;
        this.showReverseCalculator = false;
        this.showFlightDialog = true;
      }
    },

    async deletePayload() {
      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.delete(`/flights/${this.flight._id}/payload`, {
          headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.$store.commit("updateFlight", res.data.flight);

        this.getData();
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
        this.showPayloadDialog = false;
        this.showReverseCalculator = false;
      }
    },

    hidePayloadDialog() {
      this.showPayloadDialog = false;
      this.showReverseCalculator = false;
    },

    async saveCargoPayload() {
      this.cargoPayloadError = null;

      const cargo = parseInt(this.cargo);

      if (isNaN(cargo)) {
        this.cargoPayloadError = "Please enter a valid number.";
        return;
      }

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const payload = {
          flightId: this.flight.flightId,
          aircraftRegistration: this.flight.aircraft.registration,
          cargo,
          estimatedWeightPerPassenger: 0,
          preliminaryWeight: 0,
          bufferWeight: 0,
          finalEstimatedPayload: 0,
          swaCategory: this.swaCategory,
        };

        const res = await api.put(
          `/flights/${this.flight._id}/payload`,
          payload,
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        this.loading = false;
        this.showCargoPayloadDialog = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.$store.commit("updateFlight", res.data.flight);
        this.getData();
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
        this.showCargoPayloadDialog = false;
        this.showReverseCalculator = false;
        this.showFlightDialog = true;
      }
    },

    formatDateShort(miliseconds) {
      const date = new Date(miliseconds);

      let options = {
        timeZone: "UTC",
        month: "short",
        day: "numeric",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    formatHeaderDate(string) {
      const date = new Date(string);

      let options = {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "long",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    openUnitConverter() {
      this.showUnitConverterDialog = true;
    },

    getAircraftTypeStyle({ type, cargoOnly }) {
      let style = {
        backgroundColor: "white",
        paddingLeft: "5px",
        border: "1px solid #bebebe",
        borderRadius: "5px",
        fontFamily: "Abel, Arial, Helvetica, sans-serif",
        fontSize: "12px",
        textAlign: "center",
        cursor: "pointer",
      };

      if (cargoOnly) {
        style.borderRight = "3px solid #696969";
        style.borderBottom = "2px solid #696969";
      }

      // 738	#31abb2
      // 744	#03abdb
      // 748	#a1a1e6
      // 763	#ff9900
      // 74Y	#cc9900
      // 76F	#fcb003
      // 77F	#0d8512

      switch (type) {
        case "738":
          style.backgroundColor = "#31abb2";
          break;
        case "744":
          style.backgroundColor = "#03abdb";
          break;
        case "748":
          style.backgroundColor = "#a1a1e6";
          break;
        case "763":
          style.backgroundColor = "#ff9900";
          break;
        case "74Y":
          style.backgroundColor = "#cc9900";
          break;
        case "76F":
          style.backgroundColor = "#fcb003";
          break;
        case "77F":
          style.backgroundColor = "#0d8512";
          break;

        default:
          break;
      }

      return style;
    },
  },
};
</script>

<style scoped>
.flight-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  min-width: 250px;
  padding: 5px;
  border-radius: 3px;
  background-color: rgba(128, 128, 128, 0.9);
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  z-index: 700;
}

.right-panel-row {
  position: absolute;
  top: 50px;
  left: 0;
  height: 0;
  width: 0;
  border-bottom: 1px solid gray;
  background-color: #bfdbfe;
  overflow: hidden;
}

.right-panel-row:nth-child(odd) {
  background-color: #bae6fd;
}

.exemption-row {
  display: block;
  font-family: "Abel", Times, serif;
  font-size: 0.75rem;
  border-bottom: 1px solid gray;
  padding: 0;
}

p {
  width: 100%;
  min-height: 100px;
}

.payload-exceeded {
  color: red;
}

.remove-icon:hover {
  color: red;
}

.trip-frame {
  position: fixed;
  top: 50px;
  z-index: 600;
}

/* .p-5 {
  position: relative;
  height: 100%;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
} */

.fa-left {
  font-size: 2.3rem;
  color: #8e8e8e;
  cursor: pointer;
}

.fa-left:hover {
  color: #1d8de9;
}

.fa-right {
  font-size: 2.3rem;
  color: #8e8e8e;
  cursor: pointer;
}

.fa-right:hover {
  color: #1d8de9;
}

.fa-star {
  font-size: 1.1rem;
  color: #d4af37;
  padding: 0 1rem;
}

.fa-add:hover {
  color: blue;
}

.fa-close {
  font-size: 1.1rem;
  color: #8e8e8e;
  cursor: pointer;
  padding: 0 15px;
}

.fa-close:hover {
  color: red;
}

.fa-calculator {
  font-size: 2rem;
  color: #8e8e8e;
  cursor: pointer;
  padding: 0 15px;
}

.weight-converter {
  align-items: center;
  padding: 5px 10px;
}
</style>
