<template>
  <div class="outer-frame">
    <div class="text-xs text-truncate">{{ `${assignment.originIata}` }}</div>
    <div class="inner-frame" :style="getFlightLabelStyle()">
      {{ ` ${assignment.role}` }}
    </div>

    <div class="flex justify-between text-truncate text-[10px]">
      <div>{{ assignment.startTime.substring(11, 16) }}</div>
      <div>{{ assignment.endTime.substring(11, 16) }}</div>
    </div>
  </div>
</template>
<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "stationAssignmentLabel",
  mixins: [mixin],
  data() {
    return {};
  },
  props: ["assignment"],
  methods: {
    getFlightLabelStyle() {
      let style = {
        backgroundColor: "brown",
        color: "#FFF",
      };

      if (this.assignment.warnings.length) {
        style.borderTop = "2px solid red";
      }

      return style;
    },
  },
};
</script>
<style scoped>
.outer-frame {
  position: absolute;
  height: 59px;
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
}

.has-conflict {
  border: 1px solid red;
  background-color: darksalmon;
}

.inner-frame {
  position: relative;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #bebebe;
  background-color: #939790;
  height: 27px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.hidden {
  visibility: hidden;
}
</style>
